import { API_CONFIG } from "../../shared/api/config";
import composeRequest from "../../shared/api/core";

const BASE_URL = API_CONFIG.API_BASE_PATH;

export const API_URLS = {
    userListUrl: BASE_URL + 'core/users/',
    userCountUrl: BASE_URL + 'core/users/count/',
    userSummaryDataUrl: BASE_URL + 'core/users/summary/'

};
export const permissionModel = 'user';

export const getUsersByPage = (
    businessId: string | number, 
    page: number, 
    pageSize: number, 
    searchTerm: string,
    orderBy: string | number,    
) => {
    let URL = `${API_URLS.userListUrl}?business_id=${businessId}&page=${page}&page_size=${pageSize}`;
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const updateUser = (data: any) => {
    const URL = API_URLS.userListUrl + `${data.id}/`;
    return composeRequest(URL, 'PUT', JSON.stringify(data));
};