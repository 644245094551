import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import SvgIcon from '@material-ui/core/SvgIcon';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Tooltip from '@material-ui/core/Tooltip';
import { Theme, createStyles, makeStyles, withStyles } from '@material-ui/core/styles';

import GlobalStyles from '../../../../styles/GlobalStyles.web';
import {
  MasterProduct, AttributeValue,
  MasterProductIdentifier, AttributeTypes
} from '../../models';

import { getMasterProductAttributes, getMasterProductDetail, updateMasterProduct } from '../../api';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { ConfirmModal } from '../../../shared/components';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { setPageLoading } from '../../../../store/actions';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { NO_PERMISSIONS_ERROR_MSG } from '../../../shared/lib/Localization';
import { AttributeModal, ProductCodeModal } from '../../components';
import _isEqual from 'lodash/isEqual';
import moment from 'moment';
import { BusinessRouterPaths } from '../../../page/Routes/RouterPaths';

//@ts-nocheck
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: GlobalStyles.primaryColor,
      alignSelf: 'center'
    },
    card: {
      padding: theme.spacing(1),
    },
    row: {
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    textFieldEdit: {
      marginRight: theme.spacing(1),
      marginBottom: 8,
      width: 'calc(100% - 64px)'
    },
    textFieldView: {
      marginRight: theme.spacing(1),
      marginBottom: 6,
      width: '100%'
    },
    textFieldCardRow: {
      // marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: 8,
      width: '100%'
    },
    button: {
      margin: theme.spacing(1),
    },
    progress: {
      margin: theme.spacing(2),
      justifySelf: 'center'
    },
    panelRow: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '8px 16px'
    },
    panelCol: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightRegular,
      alignSelf: 'center'
    },
    panelRowHeader: {
      backgroundColor: '#f5f5f5',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '12px'
    },
    panelHeaderText: {
      fontSize: '0.9375rem',
      alignSelf: 'center',
      // padding: '0 8px'
    },
    panelRowText: {
      fontSize: '0.9375rem',
      alignSelf: 'center',
      // padding: '0 8px'
    },
    noDataRow: {
      width: '100%',
      padding: 12
    },
    rowActionBtn: {
      padding: 8
    },
    greenCircle: {
      color: GlobalStyles.green
    },
    redCircle: {
      color: GlobalStyles.red
    }

  }),
);
const barcodesPanel = 'barcodesPanel';
const suppliersPanel = 'suppliersPanel';
const linkedProductsPanel = 'linkedProductsPanel';
const requiredAttributes = [AttributeTypes.QUANTITY, AttributeTypes.UNIT_OF_MEASURE];
const barcodeDateSortFn = (a: MasterProductIdentifier, b: MasterProductIdentifier) => {
  const dateA = a.effective_date ? new Date(a.effective_date).getTime() : new Date().getTime();
  const dateB = b.effective_date ? new Date(b.effective_date).getTime() : new Date().getTime();
  if ((!a.is_deleted && !b.is_deleted) || (a.is_deleted && b.is_deleted)) {
    return dateB - dateA;
  } else if (a.is_deleted && !b.is_deleted) {
    return 1;
  } else {
    return -1;
  }
};
const ExpansionPanel = withStyles({
  root: {
    marginTop: '16px',
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
      marginTop: '16px !important'
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .08)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 48,
    '&$expanded': {
      minHeight: 48,
    },
  },
  content: {
    '&$expanded': {
      // margin: '12px 0',
      margin: '0',
    },
    margin: 0
  },
  expandIcon: {
    padding: 8
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    //   padding: theme.spacing(2),
    padding: 0,
  },
}))(MuiExpansionPanelDetails);

const AddNewBarcodeModalData = {
  barcodeData: {
    identifier: '',
    description: '',
    effective_date: '1970-01-01',
    expiry_date: null,
    quantity: 1,
    is_deleted: false
  } as MasterProductIdentifier,
  isOpen: true,
  mode: 'add',
  allProductCodes: [] as MasterProductIdentifier[]
};
const ConfirmModalData = {
  isOpen: false,
  message1: 'You will lose all unsaved changes.',
  message2: ' Are you sure you want to leave this page?',
  title: 'Confirm Navigation',
  confirmBtnText: 'Yes',
  onCancel: () => { },
  onConfirm: () => { }
};

const MasterProductDetailScreen = (props: any) => {
  const classes = useStyles({});
  const [isAttributeModalOpen, setAttributeModalOpen] = useState(false);
  const [productCodeModalData, setProductCodeModalData] = useState(null);
  const [allMasterAttributes, setMasterAttributes] = useState<any[]>([]);
  const [confirmModalData, setConfirmModalData] = useState(ConfirmModalData);
  const [isLoading, setLoading] = useState(false);
  const [masterProductData, setMasterProductData] = useState<MasterProduct | null>(null);
  const [originalMasterProduct, setOriginalMasterProduct] = useState<MasterProduct | null>(null);
  const [pageMode, setPageMode] = useState({ type: 'view_mode' });
  const [fieldErrors, setFieldErrors] = useState({});
  const [nameError, setNameError] = useState({ isError: false, message: '' });
  const [expanded, setExpanded] = React.useState(
    { barcodesPanel: true, suppliersPanel: true, linkedProductsPanel: true });

  const handleChange = (panel: 'barcodesPanel' | 'suppliersPanel' | 'linkedProductsPanel') => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    const xpanel = { ...expanded };
    xpanel[panel] = isExpanded;
    setExpanded(xpanel);
  };

  useEffect(() => {
    getMasterProductAttributes()
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(jsonData => {
        setMasterAttributes(jsonData);
      })
      .catch((error: any) => { });
  }, []);

  useEffect(() => {
    if (props.match && props.match.params) {
      if (props.match.params.id) {
        props.setPageLoader(true);
        getMasterProductDetail(props.match.params.id)
          .catch(handleError) // handle network issues
          .then(checkStatus)
          .then(parseJSON)
          .then(jsonData => {
            initializeData(jsonData);
            props.setPageLoader(false);
          })
          .catch((error: any) => {
            props.setPageLoader(false);
            props.showToast({
              message: 'Failed to fetch Master Product.',
              options: ToastError
            });
          });
      }
    }
  }, [allMasterAttributes]);

  const initializeData = (data: MasterProduct) => {
    const mandatoryAttributes = [];
    const otherAttributes: any = [];
    let hasUnitAttribute = false;
    let hasQuantityAttribute = false;
    data.attributes.forEach(a => {
      if (a.attribute.name === AttributeTypes.UNIT_OF_MEASURE) {
        mandatoryAttributes.push({ ...a });
        hasUnitAttribute = true;
      } else if (a.attribute.name === AttributeTypes.QUANTITY) {
        mandatoryAttributes.push({ ...a });
        hasQuantityAttribute = true;
      } else {
        otherAttributes.push({ ...a });
      }
    });
    if (mandatoryAttributes.length === 0) {
      const quantityAttribute = allMasterAttributes.find(a => a.name === AttributeTypes.QUANTITY);
      const unitAttribute = allMasterAttributes.find(a => a.name === AttributeTypes.UNIT_OF_MEASURE);
      mandatoryAttributes.push({ value: '', attribute: { ...quantityAttribute } });
      mandatoryAttributes.push({ value: '', attribute: { ...unitAttribute } });
    } else if (!hasUnitAttribute) {
      const unitAttribute = allMasterAttributes.find(a => a.name === AttributeTypes.UNIT_OF_MEASURE);
      mandatoryAttributes.push({ value: '', attribute: unitAttribute });
    } else if (!hasQuantityAttribute) {
      const quantityAttribute = allMasterAttributes.find(a => a.name === AttributeTypes.QUANTITY);
      mandatoryAttributes.push({ value: '', attribute: quantityAttribute });
    }
    mandatoryAttributes.sort((a1, a2) => a1.attribute.name === AttributeTypes.QUANTITY ? -1 : 1);
    const allAttributes = mandatoryAttributes.concat(otherAttributes);
    data.attributes = allAttributes;
    data.identifiers.sort(barcodeDateSortFn);
    setMasterProductData(data);
    setOriginalMasterProduct(JSON.parse(JSON.stringify(data)));
    if (data.identifiers.length > 0) {
      setExpanded({ ...expanded, barcodesPanel: true });
    }
  };

  const navigateToProductDetail = (product) => {
    props.history.push(`${BusinessRouterPaths.productList}/${product.uuid}`, product);
  };

  const isDataEdited = () => {
    return !_isEqual(originalMasterProduct, masterProductData);
  };

  const isEditMode = () => {
    return pageMode && pageMode.type === 'edit_mode';
  };

  const handleDiscardChanges = () => {
    setFieldErrors({});
    const data = JSON.parse(JSON.stringify(originalMasterProduct));
    setMasterProductData(data);
  };

  const handleNameChange = (name: string) => {
    setMasterProductData({ ...masterProductData, name: name });
  };

  const togglePageMode = () => {
    if (pageMode.type === 'view_mode') {
      setPageMode({ type: 'edit_mode' });
    } else {
      setPageMode({ type: 'view_mode' });
    }
  };

  const openAttributeDialog = () => {
    setAttributeModalOpen(true);
  };
  const removeAttribute = (attribute, index) => {
    const masterProduct = { ...masterProductData };
    const attributes = masterProduct.attributes.filter(a => a.id !== attribute.id);
    masterProduct.attributes = attributes;
    setMasterProductData(masterProduct);
    const newError = { ...fieldErrors };
    newError[String(index)] = { isBlankError: false };
    setFieldErrors(newError);
  };

  const handleAttributeChange = (attributeValue, newValue) => {
    const masterProduct = { ...masterProductData };
    if (!attributeValue.hasOwnProperty('id')) {
      for (let a of masterProduct.attributes) {
        if (a.attribute.name === attributeValue.attribute.name) {
          a.value = newValue;
        }
      }
      setMasterProductData(masterProduct);
      return;
    } else {
      for (let a of masterProduct.attributes) {
        if (a.id === attributeValue.id) {
          a.value = newValue;
        }
      }
    }
    setMasterProductData(masterProduct);
  };

  const handleSave = () => {
    const payload = { ...masterProductData } as any;
    let hasFieldError = false;
    let errorCount = 0;
    payload.attributes.forEach((a, index) => {
      const isValid = validateField(a, index);
      if (!isValid && errorCount === 0) {
        errorCount += 1;
        hasFieldError = true;
      }

    });
    if (hasFieldError) {
      const msg = 'Invalid input! Please see errors below.';
      props.showToast({ message: msg, options: ToastError });
      return;
    }
    for (let key of Object.keys(fieldErrors)) {
      if (fieldErrors[key].isBlankError) {
        return;
      }
    }
    const attributes = payload.attributes.map((a: AttributeValue) => {
      return {
        ...a,
        attribute: a.attribute.id
      };
    });
    payload.attributes = attributes;
    updateMasterProduct(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(jsonData => {
        props.showToast({
          message: 'Master Product updated successfully!',
          options: ToastSuccess
        });
        setTimeout(() => navigateBack(), 1200);
      })
      .catch((error: any) => {
        let msg = 'Failed to update master product.';
        if ((error.status === 403 || error.status === 401)
          && error.body.detail.length > 0) {
          msg = NO_PERMISSIONS_ERROR_MSG;
        }
        props.showToast({ message: msg, options: ToastError });
      });
  };

  const onAttributeModalClose = (masterProduct) => {
    if (masterProduct) {
      setMasterProductData(masterProduct);
    }
    setAttributeModalOpen(false);
  };

  const openProductCodeModal = (event, mode: string, barcodeData = null) => {
    if (event) {
      event.stopPropagation();
    }
    if (mode === 'add') {
      setProductCodeModalData({
        ...AddNewBarcodeModalData,
        allProductCodes: masterProductData.identifiers
      }
      );
    } else {
      setProductCodeModalData({
        mode: 'edit',
        barcodeData: barcodeData,
        isOpen: true,
        allProductCodes: masterProductData.identifiers
      });
    }
  };

  const onProductCodeModalClose = () => {
    setProductCodeModalData(null);
  };

  const toggleMarkProductCodeAsDeleted = (barcodeData) => {
    if (!barcodeData.expiry_date) {
      barcodeData.expiry_date = new Date();
    } else {
      barcodeData.expiry_date = null;
    }
    barcodeData.is_deleted = !barcodeData.is_deleted;
    const masterProduct = { ...masterProductData };
    const oldBarcodes = [...masterProductData.identifiers];
    const newBarcodes = oldBarcodes.filter(b => b.id !== barcodeData.id);
    newBarcodes.push(barcodeData);
    newBarcodes.sort(barcodeDateSortFn);
    masterProduct.identifiers = newBarcodes;
    setMasterProductData(masterProduct);
  };

  const onProductCodeModalUpdate = (barcodeData) => {
    const masterProduct = { ...masterProductData };
    const oldBarcodes = [...masterProductData.identifiers];
    const newBarcodes = oldBarcodes.filter(b => b.id !== barcodeData.id);
    newBarcodes.push(barcodeData);
    newBarcodes.sort(barcodeDateSortFn);
    masterProduct.identifiers = newBarcodes;
    setMasterProductData(masterProduct);
    setProductCodeModalData(null);
  };
  const onProductCodeModalSave = (barcodeData) => {
    const masterProduct = { ...masterProductData };
    masterProduct.identifiers.push(barcodeData);
    masterProduct.identifiers.sort(barcodeDateSortFn);
    setMasterProductData(masterProduct);
    setProductCodeModalData(null);
  };

  const hasError = (key: string) => {
    if (fieldErrors && fieldErrors.hasOwnProperty(key)) {
      const error = fieldErrors[key].isBlankError;
      return error ? true : false;
    }
    return false;
  };

  const validateField = (a: AttributeValue, index: number) => { // return true if valid
    if (a.value.trim() === '') {
      const newError = { ...fieldErrors };
      newError[String(index)] = { isBlankError: true };
      setFieldErrors(newError);
      return false;
    } else {
      const newError = { ...fieldErrors };
      newError[String(index)] = { isBlankError: false };
      setFieldErrors(newError);
      return true;
    }
  };

  const confirmBackNavigation = () => {
    if (isDataEdited()) {
      setConfirmModalData({
        ...confirmModalData,
        isOpen: true,
        onCancel: () => setConfirmModalData({ ...confirmModalData, isOpen: false }),
        onConfirm: () => {
          setConfirmModalData({ ...confirmModalData, isOpen: false });
          navigateBack();
        }
      });
    } else {
      navigateBack();
    }
  };

  const navigateBack = () => {
    props.history.goBack();
  };

  const isDateExpired = (expiry_date: string) => {
    if (expiry_date) {
      const today = new Date();
      const expiryDate = new Date(expiry_date);
      const currentDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      if ((expiryDate > currentDate) || (expiryDate === currentDate)) {
        return false;
      }
      return true;
    }
    return false;
  };

  const getDateFormat = (date: string) => {
    if (date) {
      return moment(date).format('DD-MMM-YYYY');
    }
    return '';
  };

  const getDeletedCodeCount = () => {
    if (masterProductData && masterProductData.identifiers) {
      return masterProductData.identifiers.filter(b => b.is_deleted === true).length;
    }
    return '';
  };
  const getAttributeClass = (a: AttributeValue) => {
    if (a.attribute.name === AttributeTypes.QUANTITY ||
      a.attribute.name === AttributeTypes.UNIT_OF_MEASURE) {
      return classes.textFieldView;
    }
    else {
      if (isEditMode()) {
        return classes.textFieldEdit;
      }
      return classes.textFieldView;
    }
  };

  return (
    <div>
      <div style={{ maxWidth: '100%' }}>

        <AttributeModal
          isOpen={isAttributeModalOpen}
          onClose={(masterProduct) => onAttributeModalClose(masterProduct)}
          masterProduct={masterProductData}
          allMasterAttributes={allMasterAttributes}
        />
        <Card className={classes.card}>
          <CardContent>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingBottom: '8px',
              borderBottom: '2px solid #adb5bd'
            }}>
              <div style={{ display: 'flex' }}>
                <IconButton aria-label="back"
                  onClick={confirmBackNavigation}>
                  <ArrowBackIcon />
                </IconButton>
                <Typography variant='h6'
                  className={classes.title}>Master Product Detail</Typography>
              </div>
              <div style={{ alignSelf: 'center' }}>
                <Tooltip title="Toggle Page Mode">
                  <IconButton
                    style={{
                      color: isEditMode() ? '#ffb300' : GlobalStyles.primaryColor,
                      border: '2px solid currentColor',
                      borderRadius: '4px',
                      padding: '4px'
                    }}
                    onClick={() => togglePageMode()}>
                    {isEditMode() ? <EditIcon /> : <VisibilityIcon />}
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingTop: '8px'
            }}>
              {
                isEditMode() &&
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  className={classes.button}
                  startIcon={<EditIcon />}
                  onClick={() => openAttributeDialog()}
                >Attributes</Button>
              }
              {
                isEditMode() &&
                isDataEdited() &&
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  className={classes.button}
                  // startIcon={<SaveIcon />}
                  onClick={() => handleDiscardChanges()}
                >Discard Changes</Button>
              }
              {
                isEditMode() &&
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.button}
                  style={{ marginRight: 0 }}
                  startIcon={<SaveIcon />}
                  onClick={() => handleSave()}
                >Save</Button>
              }
            </div>
            <Grid container spacing={2} style={{ paddingTop: 4 }}>

              <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                {isLoading && <CircularProgress className={classes.progress} />}
                {
                  !isLoading &&
                  <React.Fragment>
                    <Grid container className={classes.row}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <TextField
                          id="id"
                          className={classes.textFieldCardRow}
                          style={{ marginTop: 16, flex: 1 }}
                          label="ID"
                          margin="dense"
                          variant="outlined"
                          value={masterProductData ? masterProductData.id : ''}
                          InputProps={{
                            disabled: true,
                          }}
                        />
                        <FormControlLabel
                          style={{ flex: 1, marginTop: 8 }}
                          control={
                            <Checkbox
                              disabled={!isEditMode()}
                              checked={masterProductData ? masterProductData.requires_review : false}
                              onChange={() => setMasterProductData({
                                ...masterProductData, requires_review: !masterProductData.requires_review
                              })}
                              color="primary"
                            />
                          }
                          label="Requires Review"
                        />
                      </div>
                    </Grid>
                    <Grid container className={classes.row}>
                      <TextField
                        id="name"
                        className={classes.textFieldCardRow}
                        label="Name"
                        margin="dense"
                        variant="outlined"
                        value={masterProductData ? masterProductData.name : ''}
                        onChange={(event) => handleNameChange(event.target.value)}
                        onBlur={() => {
                          if (isEditMode()) {
                            if (masterProductData && masterProductData.name.length === 0) {
                              setNameError({
                                isError: true,
                                message: 'Name cannot be blank.'
                              });
                            } else {
                              setNameError({
                                isError: false,
                                message: ''
                              });
                            }
                          }
                        }}
                        error={nameError.isError}
                        helperText={nameError.message}
                        InputProps={{
                          readOnly: !isEditMode(),
                        }}
                      />
                    </Grid>
                    {
                      masterProductData && masterProductData.attributes &&
                      masterProductData.attributes.map((a, index) => {
                        return (
                          <Grid container key={String(index)}
                            className={classes.row} style={{ display: 'flex' }}>
                            <TextField
                              id={String(a.id)}
                              className={getAttributeClass(a)}
                              label={a.attribute && a.attribute.name ? String(a.attribute.name) : ''}
                              margin="dense"
                              variant="outlined"
                              onChange={(event) => handleAttributeChange(a, event.target.value)}
                              onBlur={() => {
                                if (isEditMode()) {
                                  validateField(a, index);
                                }
                              }}
                              error={hasError(String(index))}
                              helperText={hasError(String(index)) ? 'This field cannot be blank' : null}
                              value={a.value}
                              InputProps={{
                                readOnly: !isEditMode(),
                              }}
                            />
                            {
                              isEditMode() &&
                              (requiredAttributes.indexOf(a.attribute.name) === -1) &&
                              <IconButton onClick={() => removeAttribute(a, index)}
                                style={{
                                  marginBottom: hasError(String(index)) ? 20 : 0
                                }}>
                                <RemoveCircleOutlineIcon />
                              </IconButton>
                            }
                          </Grid>
                        );
                      })
                    }
                  </React.Fragment>
                }
              </Grid>

              <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                <ExpansionPanel
                  expanded={expanded.barcodesPanel}
                  onChange={handleChange(barcodesPanel)}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <Typography className={classes.heading}>
                        Product Codes&nbsp;&nbsp;-&nbsp;&nbsp;
                                            {
                          masterProductData && masterProductData.identifiers ?
                            '( ' + masterProductData.identifiers.length + ', ' + getDeletedCodeCount() + ')'
                            : ''
                        }
                      </Typography>
                      {
                        isEditMode() &&
                        <Tooltip title="Add Code">
                          <IconButton style={{ padding: 8 }}
                            onClick={(event) => openProductCodeModal(event, 'add', null)}>
                            <AddBoxIcon />
                          </IconButton>
                        </Tooltip>
                      }
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className={classes.panelCol}>
                      <div className={classes.panelRowHeader}>
                        {
                          isEditMode() &&
                          <Typography className={classes.panelHeaderText}
                            style={{ flex: 1, textAlign: 'center', minWidth: '80px' }}>Actions</Typography>
                        }
                        <Typography className={classes.panelHeaderText}
                          style={{ flex: 2 }}>Code</Typography>
                        <Typography className={classes.panelHeaderText}
                          style={{ flex: 1 }}>Quantity</Typography>
                        <Typography className={classes.panelHeaderText}
                          style={{ flex: 2 }}>Description</Typography>
                        <Typography className={classes.panelHeaderText}
                          style={{ flex: 1, textAlign: 'center' }}>Status</Typography>
                      </div>
                      <div className={classes.panelCol}>
                        {
                          masterProductData &&
                            masterProductData.identifiers &&
                            masterProductData.identifiers.length > 0 ?
                            masterProductData.identifiers.map(barcodeData =>
                              <div key={String(barcodeData.id)}>
                                <Divider />
                                <div className={classes.panelRow}>
                                  {
                                    isEditMode() &&
                                    <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                                      <Tooltip title="Edit">
                                        <IconButton
                                          className={classes.rowActionBtn}
                                          onClick={(event) => openProductCodeModal(event, 'edit', barcodeData)}>
                                          <EditIcon />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title={barcodeData.is_deleted ? 'Renew' : 'Delete'}>
                                        <IconButton style={{
                                          color: barcodeData.is_deleted ? GlobalStyles.primaryColor : 'rgba(0, 0, 0, 0.54)'
                                        }}
                                          className={classes.rowActionBtn}
                                          onClick={() => toggleMarkProductCodeAsDeleted(barcodeData)}>
                                          {
                                            !barcodeData.is_deleted &&
                                            <DeleteIcon />
                                          }
                                          {
                                            barcodeData.is_deleted &&
                                            <AutorenewIcon />
                                          }
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  }
                                  <Typography className={classes.panelRowText}
                                    style={{ flex: 2 }}>
                                    {barcodeData.identifier}
                                  </Typography>
                                  <Typography className={classes.panelRowText}
                                    style={{ flex: 1 }}>
                                    {barcodeData.quantity}
                                  </Typography>
                                  <Typography className={classes.panelRowText}
                                    style={{ flex: 2 }}>
                                    {barcodeData.description}
                                  </Typography>
                                  <div style={{ flex: 1, textAlign: 'center', alignSelf: 'center' }}>
                                    {
                                      (barcodeData.is_deleted ||
                                        isDateExpired(barcodeData.expiry_date)) &&
                                      <SvgIcon className={classes.redCircle}>
                                        <path d={SVG_ICONS.blankCircle} />
                                      </SvgIcon>
                                    }
                                    {
                                      (!barcodeData.is_deleted &&
                                        !isDateExpired(barcodeData.expiry_date)) &&
                                      <SvgIcon className={classes.greenCircle}>
                                        <path d={SVG_ICONS.blankCircle} />
                                      </SvgIcon>
                                    }
                                  </div>
                                </div>
                              </div>
                            ) : null
                        }
                        {
                          masterProductData &&
                          masterProductData.identifiers &&
                          masterProductData.identifiers.length === 0 &&
                          <div className={classes.noDataRow}>
                            <Typography align='center'>No Codes</Typography>
                          </div>
                        }
                      </div>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
            </Grid>
            {/* <ExpansionPanel
              expanded={expanded.suppliersPanel}
              onChange={handleChange(suppliersPanel)}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="suppliersPanel"
              >
                <div>
                  <Typography className={classes.heading}>Linked Supplier Products</Typography>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className={classes.panelCol}>
                  <div className={classes.panelRowHeader}>
                    <Typography className={classes.panelHeaderText}
                      style={{ flex: 2 }}>Supplier</Typography>
                    <Typography className={classes.panelHeaderText}
                      style={{ flex: 2 }}>Supplier Product Name</Typography>
                    <Typography className={classes.panelHeaderText}
                      style={{ flex: 1 }}>Reorder Code</Typography>
                    <Typography className={classes.panelHeaderText}
                      style={{ flex: 1, textAlign: 'center' }}>Container Qty</Typography>
                  </div>
                  <div className={classes.panelCol}>
                    {
                      masterProductData &&
                        masterProductData.suppliers &&
                        masterProductData.suppliers.length > 0 ?
                        masterProductData.suppliers.map(s =>
                          <div key={String(s.id)}>
                            <Divider />
                            <div className={classes.panelRow}>
                              <Typography className={classes.panelRowText}
                                style={{ flex: 2 }}>
                                {s.master_supplier.name}
                              </Typography>
                              <Typography className={classes.panelRowText}
                                style={{ flex: 2 }}>
                                {s.supplier_product_name}
                              </Typography>
                              <Typography className={classes.panelRowText}
                                style={{ flex: 1 }}>
                                {s.supplier_reorder_code}
                              </Typography>
                              <Typography className={classes.panelRowText}
                                style={{ flex: 1 }} align='center'>
                                {s.supplier_container_qty}
                              </Typography>
                            </div>
                          </div>
                        ) : null
                    }
                    {
                      masterProductData &&
                      masterProductData.suppliers &&
                      masterProductData.suppliers.length === 0 &&
                      <div className={classes.noDataRow}>
                        <Typography align='center'>No Linked Supplier Products</Typography>
                      </div>
                    }
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel> */}
            <ExpansionPanel
              expanded={expanded.linkedProductsPanel}
              onChange={handleChange(linkedProductsPanel)}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="linkedProductsPanel"
              >
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <Typography className={classes.heading}>Linked Business Products</Typography>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className={classes.panelCol}>
                  <div className={classes.panelRowHeader}>
                    <Typography className={classes.panelHeaderText}
                      style={{ flex: 2 }}>Business</Typography>
                    <Typography className={classes.panelHeaderText}
                      style={{ flex: 2 }}>Business Product</Typography>
                  </div>
                  <div className={classes.panelCol}>
                    {
                      masterProductData &&
                        masterProductData.business_products &&
                        masterProductData.business_products.length > 0 ?
                        masterProductData.business_products.map(bp =>
                          <div key={String(bp.id)}>
                            <Divider />
                            <div className={classes.panelRow}>
                              <Typography className={classes.panelRowText}
                                style={{ flex: 2 }}>
                                {bp.business.name}
                              </Typography>
                              <Link variant="body1"
                                align="left"
                                className={classes.panelRowText}
                                style={{ color: "rgba(0, 0, 0, 0.87)", flex: 2 }}
                                component="button"
                                onClick={() => navigateToProductDetail(bp.business_product)} >
                                {bp.business_product.name}
                              </Link>
                            </div>
                          </div>
                        ) : null
                    }
                    {
                      masterProductData &&
                      masterProductData.business_products &&
                      masterProductData.business_products.length === 0 &&
                      <div className={classes.noDataRow}>
                        <Typography align='center'>No Linked Business Products</Typography>
                      </div>
                    }
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </CardContent>
        </Card>
      </div>
      <ConfirmModal
        isOpen={confirmModalData.isOpen}
        onCancel={confirmModalData.onCancel}
        onConfirm={confirmModalData.onConfirm}
        confirmBtnText={confirmModalData.confirmBtnText}
        dialogTitle={confirmModalData.title}
        dialogMessage1={confirmModalData.message1}
        dialogMessage1Color={GlobalStyles.red}
        dialogMessage2={confirmModalData.message2}
      />
      <ProductCodeModal
        isOpen={productCodeModalData ? productCodeModalData.isOpen : false}
        onClose={() => onProductCodeModalClose()}
        barcodeData={productCodeModalData ? productCodeModalData.barcodeData : null}
        mode={productCodeModalData ? productCodeModalData.mode : null}
        onUpdate={(barcodeData) => onProductCodeModalUpdate(barcodeData)}
        onSave={(barcodeData) => onProductCodeModalSave(barcodeData)}
        allProductCodes={productCodeModalData ? productCodeModalData.allProductCodes : null}
        masterProduct={masterProductData}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
    setPageLoader: (isLoading: boolean) => dispatch(setPageLoading(isLoading))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterProductDetailScreen);