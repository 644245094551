export const BusinessRouterPaths = {
    account: '/web/account',
    dashboard: '/web/dashboard',
    deviceManager: '/web/devices',
    inventories: '/web/inventories',
    invoices: '/web/invoices',
    invoiceDetail: '/web/invoices/:id',
    productDetail: '/web/products/:id',
    productList: '/web/products',
    labelerTemplates: '/web/labeler/templates',
    stockOrderImport: '/web/stock-order/import',
    stockOrderList: '/web/stock-order/list',
    stockReceiptList: '/web/stock-receipts',
    productsWithoutBarcodeReport: '/web/reports/products-without-barcode',
    productsWithDuplicateNameReport: '/web/reports/products-with-duplicate-name',
    productsNotMappedReport: '/web/reports/products-not-mapped',
    productsGlobalLookup: '/web/reports/product-lookup',
    expectedStockByWeekReport: '/web/reports/expected-stock-by-week',
    unknownIdentifierHistoryReport: '/web/reports/unknown-identifiers',
    rmsItemIssuesReport: '/web/rms/items-mismatch',
    rmsCostPriceReport: '/web/rms/cost-price',
    settings: '/web/settings',
    userManager: '/web/users',
    asyncTasks: '/web/async-tasks',
    storePriceReport: '/web/reports/store-price-report',
    // productPriceDetailbySoldScreen: '/web/reports/product-price-detail-by-sold-screen',
    // productsReport: '/web/reports/products'
};

export const SupplierRouterPaths = {
    account: '/web/account',
    dashboard: '/web/dashboard',
    businesses: '/web/supplier-businesses',
    invoices: '/web/invoices',
    invoiceDetail: '/web/invoices/:id',
    productDetail: '/web/products/:id',
    productList: '/web/products',
    settings: '/web/settings',
};

export const MasterDataRouterPaths = {
    masterSuppliers: '/web/master-data/suppliers',
    masterProductDetail: '/web/master-data/products/:id',
    masterProducts: '/web/master-data/products',
    masterProductsLink: '/web/master-data/link-products',
    masterSuppliersLink: '/web/master-data/link-suppliers',
    masterBusinessSuppliersLink: '/web/master-data/link-business-suppliers',
    // masterSupplierProductsLink: '/web/master-data/link-supplier-products',
    // masterBusinessSupplierProducts: '/web/master-data/supplier-products',
    masterProductsWithoutBarcodeReport: '/web/master-reports/products-without-barcode',
    masterProductsWithoutQtyValueReport: '/web/master-reports/products-without-qty-value',
    masterProductsWithoutUnitMeasureReport: '/web/master-reports/products-without-unit-measure',
    masterProductDuplicates: '/web/master-reports/product-duplicates',
    masterProductNameUnitMismatchReport: '/web/master-reports/product-name-unit-mismatch',
    // masterProductsWithoutBarcodeReport: '/web/reports/master-data/products-without-barcode',
    masterSupplierProductsWithoutBarcodeReport:
        '/web/reports/master-data/supplier-products-without-barcode',
    masterSupplierProductsWithoutCaseCodeReport:
        '/web/reports/master-data/supplier-products-without-case-code',
    masterSupplierProductsWithoutCaseQtyReport:
        '/web/reports/master-data/supplier-products-without-case-quantity',
    masterSupplierProductsWithoutUnitMeasureReport:
        '/web/reports/master-data/supplier-products-without-unit-measure',
    produceCodesReport: '/web/master-reports/produce-codes',
    supplierProductListReport: '/web/master-reports/supplier-product-list',
    retailerCodeNotLinkedMasterProductIdentifierReport : '/web/master-reports/retailer-code-not-in-linked-master-product-identifier',
    masterIdentifierDuplicates: '/web/master-reports/products-duplicates-identifier',
};
