import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Theme, createStyles, makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import { Product } from '../../models';
import { PaginatedResponse, Location } from '../../../shared/models';

import { getProductsByPage } from '../../api/Api';
import { handleError, checkStatus, parseJSON } from '../../../shared/api/core';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { CustomTableTitle, ErrorStatusBar, ExportStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG } from '../../../shared/lib/Localization';
import { isSuperAdmin } from '../../../shared/lib/PermissionManager';
import {
    initQueryParams,
    replaceHistory,
    tableOptions,
    useQuery,
} from '../../../shared/lib/TableQueryParams';
import {
    Toast,
    enqueueSnackbar,
    ToastSuccess,
    ToastError,
} from '../../../shared/components/Notifier';
import { AvailableFilters, SelectedFilters, FilterRow } from '../../components/Filters';
import { Filter, FilterType } from '../../../shared/models/Filter';
import download from 'downloadjs';

const FILTERS: Filter[] = [
    {
        id: 'f0',
        label: 'Commodity Group',
        types: [
            { id: '1', value: 'commodityGroup_id__in', label: 'is equal' },
            { id: '2', value: 'commodityGroup_id__not_in', label: 'is not equal' },
        ],
        selectedType: '',
        value: [],
        url: 'korona/commodity-groups',
        labelField: 'name',
        bindValue: 'uuid',
        isResponsePaginated: false,
    },
    {
        id: 'f1',
        label: 'Code / PLU',
        types: [
            { id: '1', value: 'product_article_codes__code__istartswith', label: 'begins with' },
            { id: '2', value: 'product_article_codes__code__icontains', label: 'contains' },
            { id: '3', value: 'product_article_codes__code', label: 'is equal' },
        ],
        selectedType: '',
        value: '',
        url: null,
    },
    {
        id: 'f2',
        label: 'Number',
        types: [
            { id: '1', value: 'number__istartswith', label: 'begins with' },
            { id: '2', value: 'number__icontains', label: 'contains' },
            { id: '3', value: 'number', label: 'is equal' },
        ],
        selectedType: '',
        value: '',
        url: null,
    },
    {
        id: 'f3',
        label: 'Supplier',
        types: [
            { id: '1', value: 'suppliers__uuid__in', label: 'is equal' },
            { id: '2', value: 'suppliers__uuid__not_in', label: 'is not equal' },
        ],
        selectedType: '',
        value: [],
        url: 'korona/supplier-list',
        labelField: 'name',
        bindValue: 'uuid',
        isResponsePaginated: true,
    },
    {
        id: 'f4',
        label: 'Tag',
        types: [
            { id: '1', value: 'tags__uuid__in', label: 'is equal' },
            { id: '2', value: 'tags__uuid__not_in', label: 'is not equal' },
        ],
        selectedType: '',
        value: [],
        url: 'korona/tags',
        labelField: 'name',
        bindValue: 'uuid',
        isResponsePaginated: true,
    },
];
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },
    })
);

const ProductListScreen = (props: any) => {
    const classes = useStyles({});
    const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
    const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
    let tableRef = useRef<{ onQueryChange: any; onChangePage: any; }>();
    let history = useHistory();
    let locationQuery = useQuery(useLocation);
    const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
    const [isLoading, setLoading] = useState(false);
    const [filterVisible, showFilters] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [filterString, setFilterString] = useState('');
    const [isExporting, setExporting] = useState(false);
    const stockSummaryError = 'Please select a location to view stock summary.';
    const sb = props.userData.selectedBusiness;

    useEffect(() => {
        if (!isLoading) {
            tableRef.current && tableRef.current.onChangePage(null, 0);
        }
    }, [props.userData.selectedBusiness, filterString]);

    useEffect(() => {
        const { selectedLocations } = props.userData;
        if (selectedLocations && Object.keys(selectedLocations).length === 0) {
            setErrorStatusVisible(true);
        } else {
            setErrorStatusVisible(false);
        }
    }, [props.userData.selectedLocations]);

    useEffect(() => {
        const activeFilters = selectedFilters.filter(
            (f: Filter) => f.selectedType && f.value && f.value.length > 0
        );
        // console.log('activeFilters', activeFilters);
        let filterString = '';
        activeFilters.forEach((f: Filter) => {
            let filterValue = '';
            if (f.url && Array.isArray(f.value)) {
                const mappedValues = f.value.map((v) => v[f.bindValue]);
                filterValue = mappedValues.toString();
            } else {
                // value is string
                filterValue = f.value.toString();
            }
            filterString += `&${f.selectedType}=${filterValue}`;
        });
        setFilterString(filterString);
    }, [selectedFilters]);

    const handleExportClick = () => {
        exportCSV();
    };

    const exportCSV = () => {
        setExporting(true);
        if (props.userData && props.userData.selectedBusiness) {
            const sb = props.userData.selectedBusiness;
            const shouldExport = true;
            getProductsByPage(
                null,
                sb.orderBy,
                null,
                tableQuery.search,
                sb.business_id,
                null,
                filterString,
                shouldExport
            )
                .catch(handleError) // handle network issues
                .then(checkStatus)
                .then((res) => res.blob())
                .then((data) => {
                    download(data, 'Products.csv', 'text/csv');
                    setExporting(false);
                })
                .catch((error: any) => {
                    setExporting(false);
                    console.log(error);
                    if (error) {
                        if (error.status && error.status === 500) {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(false);
                        } else if (error.status && error.status === 400) {
                            const { data } = error;
                            const defaulMsg = 'Error Could not export data!';
                            const msg = data ? (data.detail ? data.detail : defaulMsg) : defaulMsg;
                            props.showToast({ message: msg, options: ToastError });
                        }
                    }
                });
        }
    };

    const getErrorMsg = () => {
        const { selectedLocations } = props.userData;
        if (selectedLocations && Object.keys(selectedLocations).length === 0) {
            return stockSummaryError;
        }
        return null;
    };

    const navigateToProductDetailScreen = (rowData: any) => {
        const productDetailUrl = '/web/products/' + rowData.uuid;
        props.history.push(productDetailUrl, rowData);
    };

    const toggleFilterPanel = () => {
        showFilters(!filterVisible);
    };

    const renderStocksForSelectedLocations = (stocks, props) => {
        if (stocks.length === 0) {
            return '---';
        }
        const { selectedLocations } = props.userData;
        if (selectedLocations) {
            if (Object.keys(selectedLocations).length > 0) {
                const locations = Object.values(selectedLocations);
                const locationIds = locations.map((i: Location) => i.korona_org_unit);
                let sum = 0;
                stocks.forEach((s) => {
                    if (locationIds.includes(s.warehouse)) {
                        sum += s.goods;
                    }
                });
                if (locations.length === 1) {
                    return sum;
                }
                return `${sum} (${locations.length})`;
            }
            // return 'Select locations to view summary';
        }
        return '';
    };

    const addFilter = (filter) => {
        const filters = [...selectedFilters];
        filters.push(filter);
        setSelectedFilters(filters);
    };

    const removeFilter = (index) => {
        const filters = [...selectedFilters];
        filters.splice(index, 1);
        setSelectedFilters(filters);
    };

    const updateFilter = (filter, index) => {
        const filters = [...selectedFilters];
        filters.splice(index, 1, filter);
        setSelectedFilters(filters);
    };

    return (
        <Container maxWidth="lg">
            <ErrorStatusBar
                isVisible={isErrorStatusVisible}
                message={getErrorMsg()}
                displayErrorCode={false}
            />
            <ExportStatusBar isVisible={isExporting} />
            <div className="table-wrapper">
                <MaterialTable
                    options={{ 
                        ...tableOptions(tableQuery),
                        thirdSortClick: false,
                        draggable: false,
                        sorting: true
                    }}
                    components={{
                        Toolbar: (props) => (
                            <>
                                <MTableToolbar {...props} />
                                {filterVisible && (
                                    <FilterRow
                                        {...props}
                                        renderAvailableFilters={
                                            <AvailableFilters
                                                filters={FILTERS}
                                                addFilter={addFilter}
                                            />
                                        }
                                        renderSelectedFilters={
                                            <SelectedFilters
                                                selectedFilters={selectedFilters}
                                                removeFilter={removeFilter}
                                                updateFilter={updateFilter}
                                                businessId={sb ? sb.business_id : null}
                                            />
                                        }
                                    />
                                )}
                            </>
                        ),
                    }}
                    tableRef={tableRef}
                    localization={{
                        body: {
                            emptyDataSourceMessage: emptyDataSourceMessage,
                        },
                    }}
                    onRowClick={(event, rowData) => navigateToProductDetailScreen(rowData)}
                    columns={[
                        { title: 'Number', field: 'number' },
                        { title: 'Product', field: 'name' },
                        {
                            title: 'Commodity Group',
                            field: 'commodityGroup',
                            render: (rowData) => {
                                if (
                                    rowData &&
                                    rowData.commodityGroup &&
                                    rowData.commodityGroup.name
                                ) {
                                    return <div>{rowData.commodityGroup.name}</div>;
                                }
                                return <div />;
                            },
                        },
                        {
                            title: 'Stocks Summary',
                            field: 'product_stocks',
                            render: (rowData) => {
                                if (rowData && rowData.product_stocks) {
                                    return (
                                        <div>
                                            {renderStocksForSelectedLocations(
                                                rowData.product_stocks,
                                                props
                                            )}
                                        </div>
                                    );
                                }
                                return <div>---</div>;
                            }, 
                            sorting: false
                        },
                    ]}
                    data={(query) => {
                        return new Promise((resolve, reject) => {
                            if (!isLoading) {
                                const sb = props.userData.selectedBusiness;
                                if (sb && sb.business_id) {
                                    replaceHistory(query, props.history);
                                    let pageData = {
                                        data: [],
                                        totalCount: 0,
                                        page: query.page,
                                    };
                                    setLoading(true);
                                    let orderBy = '';
                                    if (query.orderBy) {                                
                                        orderBy = query.orderBy.field as string;
                                        if(orderBy === 'commodityGroup'){
                                            orderBy = 'commodityGroup__name'
                                        }
                                        orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                                    }
                                    getProductsByPage(
                                        query.page + 1,
                                        query.pageSize,
                                        query.search,
                                        sb.business_id,
                                        orderBy,
                                        null,
                                        filterString
                                    )
                                        .catch(handleError) // handle network issues
                                        .then(checkStatus)
                                        .then(parseJSON)
                                        .then((data: PaginatedResponse<Product>) => {
                                            setErrorStatusVisible(false);
                                            setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                                            pageData = {
                                                data: data.results,
                                                totalCount: data.count,
                                                page: query.page,
                                            };
                                            setTableQuery({
                                                ...tableQuery,
                                                orderBy,
                                                totalCount: data.count,
                                                page: query.page,
                                                pageSize: query.pageSize,
                                            });
                                            setLoading(false);
                                            resolve(pageData);
                                        })
                                        .catch((error: any) => {
                                            if (error) {
                                                if (error.status && error.status === 500) {
                                                    setEmptyDataSourceMessage(ERROR_500);
                                                    setErrorStatusVisible(false);
                                                } else if (
                                                    error.status === 403 ||
                                                    error.status === 401
                                                ) {
                                                    setEmptyDataSourceMessage(ERROR_MSG);
                                                    setErrorStatusVisible(true);
                                                } else {
                                                    setEmptyDataSourceMessage(ERROR_MSG);
                                                    setErrorStatusVisible(false);
                                                }
                                            }
                                            setTableQuery({
                                                ...tableQuery,
                                                totalCount: 0,
                                                page: query.page,
                                                pageSize: query.pageSize,
                                            });
                                            setLoading(false);
                                            resolve(pageData);
                                        });
                                }
                            }
                        });
                    }}
                    title={
                        <CustomTableTitle
                            history={props.history}
                            title={'Products'}
                            icon={SVG_ICONS.product}
                            showHelp={true}
                            helpText={
                                <div>
                                    <Typography>{`List of products with stock info.`}</Typography>
                                    <Typography>{`
								Stock Summary Column:
								`}</Typography>
                                    <Typography>{`
								E.g. 30(2) - Total stock 30 across 2 locations.
								`}</Typography>
                                </div>
                            }
                        />
                    }
                    actions={[
                        {
                            icon: 'save_alt',
                            tooltip: 'Export',
                            isFreeAction: true,
                            onClick: handleExportClick,
                            disabled: isExporting,
                            hidden: !isSuperAdmin(props),
                        },
                        {
                            icon: 'refresh',
                            tooltip: 'Refresh Data',
                            isFreeAction: true,
                            onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                        },
                        {
                            icon: 'filter_list',
                            tooltip: filterVisible ? 'Hide Filters' : 'Show Filters',
                            isFreeAction: true,
                            iconProps: {
                                color: filterVisible ? 'primary' : 'inherit',
                            },
                            // hidden: !isSuperAdmin(props),
                            onClick: toggleFilterPanel,
                        },
                    ]}
                />
            </div>
        </Container>
    );
};



const mapStateToProps = (state: any) => {
    return {
        userData: state.userLocalData,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductListScreen);
