import _difference from 'lodash/difference';
import { Permission } from '../../shared/models/Permission';
import { AccountScreen } from '../../account/screens';
import { Dashboard, SupplierDashboard } from '../../dashboard/screens';
import { SupplierBusinessScreen } from '../../businesses/screens';
import { DeviceManagerWeb } from '../../devices/screens';
import { InventoryListScreen } from '../../inventory/screens';
import { TemplateListScreen } from '../../labeler/screens';
import {
    MasterSupplierScreen,
    MasterProductDetailScreen,
    MasterProductScreen,
    MasterProductLinkScreen,
    LinkBusinessSupplierScreen,
    MasterSupplierLinkScreen,
   
} from '../../master-data/screens';
import {
    ProductListScreen,
    ProductDetailScreen,
    SupplierProductListScreen,
} from '../../products/screens';
import {
    MasterIdentifierDuplicateScreen,
    MasterProductDuplicatesScreen,
    ProductDuplicateNameScreen,
    ProductsWithoutBarcodeScreen,
    UnmappedProductsScreen,
    MasterProductsWithoutBarcodeScreen,
    MasterProductsWithoutUOMScreen,
    MasterProductsWithoutQtyValueScreen,
    MasterProductNameUnitMismatchScreen,
    GlobalProductLookupScreen,
    ExpectedStockByWeekScreen,
    UnknownIdentifierHistoryScreen,
    StorePriceReportScreen,
    // ProductPriceDetailbySoldScreen,
    ProduceCodesScreen,
    SupplierProductListReportScreen,
    RetailerCodeNotLinkedMasterProductIdentifierScreen,
    
} from '../../reports/screens';

import { StockOrderImportScreen, StockOrderListScreen } from '../../stockOrder/screens';
import { StockReceiptListScreen } from '../../stockReceipt/screens';
import { UserManagerWeb } from '../../users/screens';
import { AsyncTaskListScreen } from '../../async-tasks/screens/AsyncTaskList/AsyncTaskList.web';

import {
    SupplierProductsWithoutBarcodeScreen,
    SupplierProductsWithoutCaseCodeScreen,
    SupplierProductsWithoutCaseQtyScreen,
    SupplierProductsWithoutUOMScreen,
    RmsHQItemIssues,
    RmsCostPriceReport,
} from '../../reports/screens';
import { BusinessRouterPaths, MasterDataRouterPaths, SupplierRouterPaths } from './RouterPaths';
import { InvoiceDetailScreen, InvoiceListScreen } from '../../invoice/screens';

export interface Route {
    path: string;
    component: null | React.FC | React.Component;
    requiredPermissions: string[];
}

export const RetailerRoutes: Route[] = [
    {
        path: 'empty',
        component: null,
        requiredPermissions: [],
    },
    {
        path: BusinessRouterPaths.account,
        component: AccountScreen,
        requiredPermissions: [],
    },
    {
        path: BusinessRouterPaths.dashboard,
        component: Dashboard,
        requiredPermissions: [],
    },
    {
        path: BusinessRouterPaths.deviceManager,
        component: DeviceManagerWeb,
        requiredPermissions: ['view_device'],
    },
    {
        path: BusinessRouterPaths.inventories,
        component: InventoryListScreen,
        requiredPermissions: ['view_inventory'],
    },
    {
        path: BusinessRouterPaths.labelerTemplates,
        component: TemplateListScreen,
        requiredPermissions: ['view_labelertemplate'],
    },
    {
        path: BusinessRouterPaths.stockOrderImport,
        component: StockOrderImportScreen,
        requiredPermissions: ['view_stockorder', 'add_stockorder', 'view_mastersupplier'],
    },
    {
        path: BusinessRouterPaths.stockOrderList,
        component: StockOrderListScreen,
        requiredPermissions: ['view_stockorder'],
    },
    {
        path: BusinessRouterPaths.stockReceiptList,
        component: StockReceiptListScreen,
        requiredPermissions: ['view_stockreceipt'],
    },
    {
        path: MasterDataRouterPaths.masterSuppliers,
        component: MasterSupplierScreen,
        requiredPermissions: ['view_mastersupplier'],
    },
    {
        path: MasterDataRouterPaths.masterProductDetail,
        component: MasterProductDetailScreen,
        requiredPermissions: ['view_masterproduct'],
    },
    {
        path: MasterDataRouterPaths.masterProducts,
        component: MasterProductScreen,
        requiredPermissions: ['view_masterproduct'],
    },
    {
        path: MasterDataRouterPaths.masterProductDuplicates,
        component: MasterProductDuplicatesScreen,
        requiredPermissions: ['view_masterproduct'],
    },
    {
        path: MasterDataRouterPaths.produceCodesReport,
        component: ProduceCodesScreen,
        requiredPermissions: ['view_producecodesreport'],
    },
    {
        path: MasterDataRouterPaths.supplierProductListReport,
        component: SupplierProductListReportScreen,
        requiredPermissions: ['view_supplierproductlistreport'],
    },
    {
        path: MasterDataRouterPaths.retailerCodeNotLinkedMasterProductIdentifierReport,
        component: RetailerCodeNotLinkedMasterProductIdentifierScreen,
        requiredPermissions: ['view_retailtercodenotlinkedmasterproductidentifierreport'],
    },
    {
        path: MasterDataRouterPaths.masterProductsLink,
        component: MasterProductLinkScreen,
        requiredPermissions: ['view_masterproduct', 'view_linkmasterbusinessproduct'],
    },
    {
        path: MasterDataRouterPaths.masterBusinessSuppliersLink,
        component: LinkBusinessSupplierScreen,
        requiredPermissions: ['view_mastersupplier', 'view_linkkoronasupplierbusiness'],
    },
    {
        path: MasterDataRouterPaths.masterSuppliersLink,
        component: MasterSupplierLinkScreen,
        requiredPermissions: ['view_mastersupplier', 'view_linkmasterbusinesssupplier'],
    },
    {
        path: SupplierRouterPaths.invoiceDetail,
        component: InvoiceDetailScreen,
        requiredPermissions: ['view_invoice'],
    },
    {
        path: BusinessRouterPaths.invoices,
        component: InvoiceListScreen,
        requiredPermissions: ['view_invoice'],
    },
    {
        path: BusinessRouterPaths.productDetail,
        component: ProductDetailScreen,
        requiredPermissions: ['view_product'],
    },
    {
        path: BusinessRouterPaths.productList,
        component: ProductListScreen,
        requiredPermissions: ['view_product'],
    },
    {
        path: BusinessRouterPaths.productsNotMappedReport,
        component: UnmappedProductsScreen,
        requiredPermissions: [
            'view_product',
            'view_masterproduct',
            'view_linkmasterbusinessproduct',
        ],
    },
    {
        path: BusinessRouterPaths.productsWithDuplicateNameReport,
        component: ProductDuplicateNameScreen,
        requiredPermissions: ['view_product'],
    },
    {
        path: BusinessRouterPaths.productsGlobalLookup,
        component: GlobalProductLookupScreen,
        requiredPermissions: ['superuser'],
    },
    {
        path: BusinessRouterPaths.unknownIdentifierHistoryReport,
        component: UnknownIdentifierHistoryScreen,
        requiredPermissions: ['view_unknownidentifierreport'],
    },
    {
        path: BusinessRouterPaths.expectedStockByWeekReport,
        component: ExpectedStockByWeekScreen,
        requiredPermissions: [
            'view_expectedstockbyweekbycommoditygroup',
            'view_expectedstockbyweekbysupplier',
        ],
    },
    {
        path: BusinessRouterPaths.rmsItemIssuesReport,
        component: RmsHQItemIssues,
        requiredPermissions: ['view_rmshqitemmismatch'],
    },
    {
        path: BusinessRouterPaths.rmsCostPriceReport,
        component: RmsCostPriceReport,
        requiredPermissions: ['view_rmscostpricesd3'],
    },
    {
        path: BusinessRouterPaths.productsWithoutBarcodeReport,
        component: ProductsWithoutBarcodeScreen,
        requiredPermissions: ['view_product'],
    },
    {
        path: BusinessRouterPaths.storePriceReport,
        component: StorePriceReportScreen,
        requiredPermissions: ['view_productwithstoreprice'],
    },
    // {
    //     path: BusinessRouterPaths.productPriceDetailbySoldScreen,
    //     component: ProductPriceDetailbySoldScreen,
    //     requiredPermissions: ['view_productpricedetail'],
    // },
    {
        path: MasterDataRouterPaths.masterProductsWithoutBarcodeReport,
        component: MasterProductsWithoutBarcodeScreen,
        requiredPermissions: ['view_masterproduct'],
    },
    {
        path: MasterDataRouterPaths.masterProductsWithoutQtyValueReport,
        component: MasterProductsWithoutQtyValueScreen,
        requiredPermissions: ['view_masterproduct'],
    },
    {
        path: MasterDataRouterPaths.masterProductsWithoutUnitMeasureReport,
        component: MasterProductsWithoutUOMScreen,
        requiredPermissions: ['view_masterproduct'],
    },
    {
        path: MasterDataRouterPaths.masterProductNameUnitMismatchReport,
        component: MasterProductNameUnitMismatchScreen,
        requiredPermissions: ['view_masterproductnameunitmismatch'],
    },
    {
        path: MasterDataRouterPaths.masterIdentifierDuplicates, //MasterIdentifierDuplicates
        component: MasterIdentifierDuplicateScreen,
        requiredPermissions: ['superuser'],
    },
    {
        path: BusinessRouterPaths.userManager,
        component: UserManagerWeb,
        requiredPermissions: ['view_user'],
    },
    // {
    //     path: BusinessRouterPaths.asyncTasks,
    //     component: AsyncTaskListScreen,
    //     requiredPermissions: [],
    // },
    {
        path: BusinessRouterPaths.settings,
        component: null,
        requiredPermissions: [],
    },
];

export const getAuthorizedRoutes = (
    routeList: Route[],
    userPermissions: Permission[],
    is_superuser: boolean
) => {
    const permissions = userPermissions.map((p) => p.codename);
    let routes: Route[] = [];
    if (is_superuser) {
        return routeList;
    } else {
        routeList.forEach((r) => {
            if (_difference(r.requiredPermissions, permissions).length === 0) {
                routes.push(r);
            }
        });
    }
    return routes;
};

export const SupplierRoutes = [
    {
        path: 'empty',
        component: null,
        requiredPermissions: [],
    },
    {
        path: SupplierRouterPaths.account,
        component: AccountScreen,
        requiredPermissions: [],
    },
    {
        path: SupplierRouterPaths.dashboard,
        component: SupplierDashboard,
        requiredPermissions: [],
    },
    {
        path: SupplierRouterPaths.businesses,
        component: SupplierBusinessScreen,
        requiredPermissions: [],
    },
    {
        path: SupplierRouterPaths.invoiceDetail,
        component: InvoiceDetailScreen,
        requiredPermissions: [],
    },
    {
        path: SupplierRouterPaths.invoices,
        component: InvoiceListScreen,
        requiredPermissions: [],
    },
    {
        path: SupplierRouterPaths.productDetail,
        component: ProductDetailScreen,
        requiredPermissions: [],
    },
    {
        path: SupplierRouterPaths.productList,
        component: SupplierProductListScreen,
        requiredPermissions: [],
    },
    {
        path: MasterDataRouterPaths.masterSupplierProductsWithoutCaseCodeReport,
        component: SupplierProductsWithoutCaseCodeScreen,
        requiredPermissions: [],
    },
    {
        path: MasterDataRouterPaths.masterSupplierProductsWithoutBarcodeReport,
        component: SupplierProductsWithoutBarcodeScreen,
        requiredPermissions: [],
    },
    {
        path: MasterDataRouterPaths.masterSupplierProductsWithoutCaseQtyReport,
        component: SupplierProductsWithoutCaseQtyScreen,
        requiredPermissions: [],
    },
    {
        path: MasterDataRouterPaths.masterSupplierProductsWithoutUnitMeasureReport,
        component: SupplierProductsWithoutUOMScreen,
        requiredPermissions: [],
    },
    {
        path: MasterDataRouterPaths.masterProductDetail,
        component: MasterProductDetailScreen,
        requiredPermissions: ['view_masterproduct'],
    },
    {
        path: BusinessRouterPaths.settings,
        component: null,
        requiredPermissions: [],
    },
];
