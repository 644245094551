import { Theme, createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

export const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  }
}))(Tooltip);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2, 2),
      border: '1px solid',
      borderColor: '#cdcdcd',
      borderRadius: 4
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    paper: {
      // padding: theme.spacing(1, 1),
      borderRadius: 0
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      backgroundColor: '#fff',
      paddingLeft: 24,
      paddingRight: 24
    },
    tableWrapper: {
      padding: theme.spacing(2, 2),
      borderRadius: 0
    },
    headerTitle: {
      padding: theme.spacing(0, 2, 1, 0),
      // paddingLeft: 0
    },
    button: {
      margin: theme.spacing(1, 0, 2, 2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 400,
    },
    summaryWrapper: {
      border: '1px solid #ddd',
      padding: 16,
      marginBottom: 16
    },
    rowSummary: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 8
    },
    errorText: {
      color: theme.palette.error.main
    },
    successText: {
      color: '#43a047'
    },
    secondaryPageTitle: {
      fontWeight: 400,
      fontSize: '1.15rem',
      color: 'rgba(0, 0, 0, 0.87)',
    }
  }),
);