import { EXPECTED_HEADERS, StockOrderItemDatum, StockOrderRequest, StockOrderItem } from '../../models';
import { Product } from '../../../products/models';

/*
  requirements possibility:
  1. supplier product name or the reorder code
  2. (cases delivered & cost per case) 
  or (units delivered & cost per unit)
  or (cases delivered & cost per unit & units per case)
  or (units delivered & cost per case & units per case)
  */
export const validateHeaders = (mappedHeaders) => {
  // debugger;
  const noError = { hasError: false, message: '', message2: '' };
  const error = { hasError: true, message: 'There are insufficient mapped columns.', message2: '' };
  if (mappedHeaders.size === 0) {
    return { hasError: true, message: 'Please select headers for columns.', message2: '' };
  } else {
    const headerValues = Array.from(mappedHeaders.values());
    console.log('headerValues', headerValues);
    if (headerValues.length < 3) {
      return error;
    } else if (
      (headerValues.indexOf(EXPECTED_HEADERS.SUPPLIER_PRODUCT_NAME) === -1) &&
      (headerValues.indexOf(EXPECTED_HEADERS.RE_ORDER_CODE) === -1)
    ) {
      return {
        hasError: true,
        message: 'There are insufficient mapped columns.',
        message2: 'Supplier Product Name or ReOrder Code is required.'
      };
    } else if (
      (headerValues.indexOf(EXPECTED_HEADERS.CASES_DELIVERED) !== -1) &&
      (headerValues.indexOf(EXPECTED_HEADERS.COST_PER_CASE) !== -1)) {
      return noError;
    } else if (
      (headerValues.indexOf(EXPECTED_HEADERS.UNITS_DELIVERED) !== -1) &&
      (headerValues.indexOf(EXPECTED_HEADERS.COST_PER_UNIT) !== -1)) {
      return noError;
    } else if (
      (headerValues.indexOf(EXPECTED_HEADERS.CASES_DELIVERED) !== -1) &&
      (headerValues.indexOf(EXPECTED_HEADERS.COST_PER_UNIT) !== -1) &&
      (headerValues.indexOf(EXPECTED_HEADERS.CASE_QUANTITY) !== -1)) {
      return noError;
    } else if (
      (headerValues.indexOf(EXPECTED_HEADERS.UNITS_DELIVERED) !== -1) &&
      (headerValues.indexOf(EXPECTED_HEADERS.COST_PER_CASE) !== -1) &&
      (headerValues.indexOf(EXPECTED_HEADERS.CASE_QUANTITY) !== -1)) {
      return noError;
    } else {
      return error;
    }
  }
};

export const getInvoiceItemsToMatch = (grid, mappedHeaders) => {
  let requestData = [];
  let index = 0;
  for (let row = 0; row < grid.length; row++) {
    if (row === 0) {
      continue;
    }
    if (grid[row][0] && !(grid[row][0].isSelected)) {
      index += 1;
    }
    else if (grid[row][0] && grid[row][0].isSelected) {
      continue;
    }
    let data: StockOrderItemDatum = {
      itemIndex: index,
      reOrderCode: '',
      supplierProductName: '',
      serialNumber: '',
      caseQuantity: '',
      master_supplier_container_qty: '',
      casesDelivered: '',
      costPerCase: '',
      unitsDelivered: '',
      costPerUnit: '',
      lineTotal: '',
      calculatedLineTotal: '',
      product: {} as Product
    };

    for (let col = 0; col < grid[row].length; col++) {
      if (grid[row][col].isSelected) {
        continue;
      } else {
        const columnHeader = mappedHeaders.get(col);
        // console.log('columnHeader', columnHeader);
        if (columnHeader) {
          switch (columnHeader) {
            case EXPECTED_HEADERS.RE_ORDER_CODE:
              data.reOrderCode = String(grid[row][col].value);
              break;
            case EXPECTED_HEADERS.SUPPLIER_PRODUCT_NAME:
              data.supplierProductName = String(grid[row][col].value);
              break;
            case EXPECTED_HEADERS.CASES_DELIVERED:
              const casesDelivered = String(grid[row][col].value).replace(',', '');
              data.casesDelivered = parseInt(casesDelivered);
              break;
            case EXPECTED_HEADERS.SERIAL_NUMBER:
              data.serialNumber = String(grid[row][col].value);
              break;
            case EXPECTED_HEADERS.COST_PER_CASE:
              const costPerCase = String(grid[row][col].value).replace(',', '');
              data.costPerCase = Number(costPerCase).toFixed(2);
              break;
            case EXPECTED_HEADERS.LINE_TOTAL:
              const lineTotal = String(grid[row][col].value).replace(',', '');
              data.lineTotal = Number(lineTotal).toFixed(2);
              break;
            case EXPECTED_HEADERS.CASE_QUANTITY:
              const caseQuantity = String(grid[row][col].value).replace(',', '');
              data.caseQuantity = parseInt(caseQuantity);
              break;
            case EXPECTED_HEADERS.COST_PER_UNIT:
              const costPerUnit = String(grid[row][col].value).replace(',', '');
              data.costPerUnit = Number(costPerUnit).toFixed(2);
              break;
            case EXPECTED_HEADERS.UNITS_DELIVERED:
              const unitsDelivered = String(grid[row][col].value).replace(',', '');
              data.unitsDelivered = parseInt(unitsDelivered);
              break;
          }
        }
      }
    }
    requestData.push(data);
  }
  return requestData;
};

export const getMatchedHeaders = (grid, mappedHeaders) => {
  let displayReOrderCode = false;
  let displayCasesDelivered = false;
  let displaySerialNumber = false;
  let displayCaseQty = false;
  let displayUnitsDelivered = false;
  for (let row = 0; row < 1; row++) {
    for (let col = 0; col < grid[row].length; col++) {
      if (grid[row][col].isSelected) {
        continue;
      } else {
        const columnHeader = mappedHeaders.get(col);
        if (columnHeader) {
          switch (columnHeader) {
            case EXPECTED_HEADERS.RE_ORDER_CODE:
              displayReOrderCode = true;
              break;
            case EXPECTED_HEADERS.CASES_DELIVERED:
              displayCasesDelivered = true;
              break;
            case EXPECTED_HEADERS.SERIAL_NUMBER:
              displaySerialNumber = true;
              break;
            case EXPECTED_HEADERS.CASE_QUANTITY:
              displayCaseQty = true;
              break;
            case EXPECTED_HEADERS.UNITS_DELIVERED:
              displayUnitsDelivered = true;
              break;
          }
        }
      }
    }
  }
  return {
    casesDelivered: displayCasesDelivered ? true : false,
    serialNumber: displaySerialNumber ? true : false,
    caseQuantity: displayCaseQty ? true : false,
    reOrderCode: displayReOrderCode ? true : false,
    unitsDelivered: displayUnitsDelivered ? true : false,
  };
};

export const getMatchedItemSummary = (stockOrderProductList, stockOrderSummary) => {
  const itemList = [...stockOrderProductList.data];
  const soSummary = { ...stockOrderSummary };
  soSummary.itemCount = itemList.length;
  let expectedTotal = 0;
  let calculatedTotal = 0;
  let unMappedProductCount = 0;
  itemList.forEach(item => {
    expectedTotal = expectedTotal + Number(item.costPerCase) * Number(item.casesDelivered);
    if (item.product.name) {
      item.calculatedLineTotal = Number(item.costPerCase) * Number(item.casesDelivered);
      calculatedTotal = calculatedTotal + item.calculatedLineTotal;
      item.calculatedLineTotal = Number(item.calculatedLineTotal).toFixed(2);

    } else {
      item.calculatedLineTotal = '0.00';
      unMappedProductCount += 1;
    }
    if (!item.caseQuantity) {
      item.caseQuantity = '';
    }
  });
  // setStockOrderProducts(itemList);
  console.log('stockOrderProductList matched data', itemList);
  soSummary.expectedTotal = expectedTotal.toFixed(2);
  soSummary.calculatedTotal = calculatedTotal.toFixed(2);
  soSummary.unMappedProducts = unMappedProductCount;
  return {
    summary: soSummary,
    items: itemList
  };
};

export const getStockOrderRequestData = (selectedSupplier, selectedLocation, description, stockOrderProducts) => {
  const selectedLocationId = selectedLocation.id;
  const orgUnitId = selectedLocation.korona_org_unit;
  const requestData: StockOrderRequest = {
    supplier: selectedSupplier.korona_supplier.uuid, // supplier id
    target: orgUnitId, // org unit id
    description: description,
    items: [],
    location: selectedLocationId
  };
  let isError = false;
  let items: StockOrderItem[] = [];
  stockOrderProducts = stockOrderProducts.filter(p => !p.error);
  for (let i = 0; i < stockOrderProducts.length; i++) {
    const {
      itemIndex,
      supplierProductName,
      reOrderCode,
      product,
      unitsDelivered,
      casesDelivered,
      master_supplier_container_qty,
      costPerUnit,
      costPerCase,
    } = stockOrderProducts[i];

    const item = {
      itemIndex: Number(itemIndex),
      name: supplierProductName,
      supplierItemNumber: reOrderCode ? reOrderCode : null,
      actualGoods: Number(unitsDelivered), // this field is required = desiredOverallQuantity
      articleNumber: null,
      article: null,
      code: null,
      color: null,
      size: null,
      quantity: Number(unitsDelivered), // this field is required
      bookedQuantity: null,
      purchasePrice: null,
      salesPrice: null,
      desiredOverallQuantity: Number(unitsDelivered), // this field is required
      actualOverallQuantity: null,
      receivedQuantity: null,
      containerQuantity: Number(master_supplier_container_qty), // this field is required
      itemPrice: null,
      deleted: false
    };
    if (costPerUnit !== '') {
      item.itemPrice = Number(costPerUnit);
    }
    if (product.name) {
      item.articleNumber = product.number;
      item.article = product.uuid;
    }
    // if (stockOrderItemDatum.caseQuantity === '') {
    //     item.containerQuantity = Number(stockOrderItemDatum.master_supplier_container_qty);
    // }
    if (item.containerQuantity !== 0) {
      if (costPerCase !== '') {
        item.itemPrice = Number(costPerCase) / item.containerQuantity;
      }
      if (Number(casesDelivered) !== 0) {
        if (unitsDelivered === '') {
          item.desiredOverallQuantity = Number(casesDelivered) * item.containerQuantity;
          item.quantity = Number(casesDelivered) * item.containerQuantity;
          item.actualGoods = item.desiredOverallQuantity;
        }
      }
    }
    // item should be validated here
    items.push(item);
  }
  requestData.items = items;
  return { payload: requestData, hasError: false };
};