import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { MasterProduct } from '../../models';
import { PaginatedResponse } from '../../../shared/models';
import {
    addMasterProduct,
    deleteMasterProduct,
    getMasterProductsByPage,
    updateMasterProduct
} from '../../api';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { MasterDataRouterPaths } from '../../../page/Routes/RouterPaths';
import { CustomTableTitle, ErrorStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_MSG, ERROR_500, API_RESPONSE, NO_PERMISSIONS_ERROR_MSG } from '../../../shared/lib/Localization';
import { hasAddPermission, hasUpdatePermission, hasDeletePermission } from '../../../shared/lib/PermissionManager';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
    }),
);

const permission_model = 'masterproduct';

const MasterProductScreen = (props: any) => {
    const classes = useStyles({});
    const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
    const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
    const [tableEditableProp, setTableEditableProp] = useState({});
    const tableRef = useRef<{ onQueryChange: any; }>();
    let locationQuery = useQuery(useLocation);
    const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));

    const getAddFn = () => {
        return (newData: any) => new Promise((resolve, reject) => {
            setTimeout(() => {
                {
                    addMasterProduct(newData)
                        .catch(handleError) // handle network issues
                        .then(checkStatus)
                        .then(parseJSON)
                        .then(jsonData => {
                            props.showToast({
                                message: 'Record added successfully!',
                                options: ToastSuccess
                            });
                            tableRef.current && tableRef.current.onQueryChange();
                        })
                        .catch((error: any) => {
                            let msg = 'Failed to add record.';
                            if (error) {
                                if ((error.status === 403 || error.status === 401)) {
                                    msg = NO_PERMISSIONS_ERROR_MSG;
                                } else if (error.status === 500) {
                                    msg = ERROR_500;
                                }
                            }
                            props.showToast({ message: msg, options: ToastError });
                        });
                }
                resolve();
            }, 1000);
        });
    };

    const getDeleteFn = () => {
        return (oldData: any) => new Promise((resolve, reject) => {
            setTimeout(() => {
                {
                    deleteMasterProduct(oldData)
                        .catch(handleError) // handle network issues
                        .then(checkStatus)
                        .then(parseJSON)
                        .then(jsonData => {
                            props.showToast({
                                message: 'Record deleted successfully!',
                                options: ToastSuccess
                            });
                            tableRef.current && tableRef.current.onQueryChange();
                        })
                        .catch((error: any) => {
                            let msg = 'Failed to delete record.';
                            if (error) {
                                if ((error.status === 403 || error.status === 401)) {
                                    msg = NO_PERMISSIONS_ERROR_MSG;
                                } else if (error.status === 500) {
                                    msg = ERROR_500;
                                }
                            }
                            props.showToast({ message: msg, options: ToastError });
                        });
                }
                resolve();
            }, 1000);
        });

    };
    const getUpdateFn = () => {
        return (newData: any, oldData: any) => new Promise((resolve, reject) => {
            setTimeout(() => {
                {
                    const data = { id: newData.id, name: newData.name };
                    updateMasterProduct(data)
                        .catch(handleError) // handle network issues
                        .then(checkStatus)
                        .then(parseJSON)
                        .then(jsonData => {
                            props.showToast({
                                message: 'Record updated successfully!',
                                options: ToastSuccess
                            });
                            tableRef.current && tableRef.current.onQueryChange();
                        })
                        .catch((error: any) => {
                            let msg = 'Failed to update record.';
                            if (error) {
                                if ((error.status === 403 || error.status === 401)) {
                                    msg = NO_PERMISSIONS_ERROR_MSG;
                                } else if (error.status === 500) {
                                    msg = ERROR_500;
                                }
                            }
                            props.showToast({ message: msg, options: ToastError });
                        });
                }
                resolve();
            }, 1000);
        });
    };

    // useEffect(() => {
    //     const { userData } = props.userData.userData;
    //     const editable = {};
    //     if (userData) {
    //         if (hasAddPermission(userData.is_superuser,
    //             userData.user_permissions, permission_model)) {
    //             editable['onRowAdd'] = getAddFn();
    //         }
    //         if (hasUpdatePermission(userData.is_superuser,
    //             userData.user_permissions, permission_model)) {
    //             editable['onRowUpdate'] = getUpdateFn();
    //         }
    //         if (hasDeletePermission(userData.is_superuser,
    //             userData.user_permissions, permission_model)) {
    //             editable['onRowDelete'] = getDeleteFn();
    //         }
    //         setTableEditableProp(editable);
    //     }
    // }, [props.userData]);

    const navigateToMasterProductDetailScreen = (rowData: any) => {
        const masterProductDetailUrl = MasterDataRouterPaths.masterProducts + '/' + rowData.id;
        props.history.push(masterProductDetailUrl, rowData);
    };

    return (
        <Container maxWidth="md">
            <ErrorStatusBar isVisible={isErrorStatusVisible} />
            <div className="table-wrapper">
                <MaterialTable
                    options={{ 
                        ...tableOptions(tableQuery),
                        thirdSortClick: false,
                        draggable: false,
                        sorting: true  
                    }}
                    tableRef={tableRef}
                    localization={{
                        body: {
                            emptyDataSourceMessage: emptyDataSourceMessage
                        }
                    }}
                    columns={[
                        { title: 'ID', field: 'id', editable: 'never' },
                        { title: 'Product', field: 'name', editable: 'never' },
                    ]}
                    data={query => new Promise((resolve, reject) => {
                        replaceHistory(query, props.history);
                        let pageData = {
                            data: [] as MasterProduct[],
                            totalCount: 0,
                            page: query.page
                        };
                        let orderBy = '';
                        if (query.orderBy) {
                            orderBy = query.orderBy.field as string;
                            orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                        }
                        getMasterProductsByPage(
                            query.page + 1, 
                            query.pageSize, 
                            query.search,
                            orderBy
                        )
                            .catch(handleError) // handle network issues
                            .then(checkStatus)
                            .then(parseJSON)
                            .then((data: PaginatedResponse<MasterProduct>) => {
                                setErrorStatusVisible(false);
                                setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                                pageData = {
                                    data: data.results,
                                    totalCount: data.count,
                                    page: query.page
                                };
                                setTableQuery({
                                    ...tableQuery,
                                    orderBy,
                                    totalCount: data.count,
                                    page: query.page,
                                    pageSize: query.pageSize
                                });
                                resolve(pageData);
                            })
                            .catch((error: any) => {
                                if (error) {
                                    if (error.status && error.status === 500) {
                                        setEmptyDataSourceMessage(ERROR_500);
                                        setErrorStatusVisible(false);
                                    } else if ((error.status === 403 || error.status === 401)
                                    ) {
                                        setEmptyDataSourceMessage(ERROR_MSG);
                                        setErrorStatusVisible(true);
                                    } else {
                                        setEmptyDataSourceMessage(ERROR_MSG);
                                        setErrorStatusVisible(false);
                                    }
                                }
                                setTableQuery({
                                    ...tableQuery,
                                    totalCount: 0,
                                    page: query.page,
                                    pageSize: query.pageSize
                                });
                                resolve(pageData);
                            });
                    })
                    }
                    title={
                        <CustomTableTitle
                            history={props.history}
                            title={'Master Products'}
                            icon={SVG_ICONS.product} />
                    }
                    actions={[
                        {
                            icon: 'refresh',
                            tooltip: 'Refresh Data',
                            isFreeAction: true,
                            onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                        },
                        {
                            icon: 'edit',
                            tooltip: 'Edit',
                            hidden: hasUpdatePermission(props.userData.userData.is_superuser, props.userData.userData.user_permissions, permission_model),
                            onClick: (event, rowData) => navigateToMasterProductDetailScreen(rowData),
                        },
                    ]}
                // editable={tableEditableProp}
                />
            </div>
        </Container>
    );
};

const mapStateToProps = (state: any) => {
    return {
        masterProductList: state.masterModuleData.masterProductList,
        userData: state.userLocalData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterProductScreen);