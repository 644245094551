import { Product } from '../../products/models';
import { BusinessSupplier, MappingSuggestion, MasterProduct } from '../../master-data/models';

export interface StockOrderItem {
    order?: string; // stock order id

    name: string,
    supplierItemNumber: string | null,
    articleNumber: string | null,
    code: string | null,
    color: string | null,
    size: string | null,
    quantity: number | null,
    bookedQuantity: number | null,
    purchasePrice: number | null,
    salesPrice: number | null,
    desiredOverallQuantity: number | null,
    actualOverallQuantity: number | null,
    receivedQuantity: number | null,
    containerQuantity: number | null,
    itemPrice: number | null,
    article: string | null,
    actualGoods: number | null,
    localOrder?: string;
    deleted: boolean;
    itemIndex: number;
    product?: Product;
}

export interface StockOrder {
    uuid?: string;
    createTime: string;
    bookingTime?: string;
    createdBy_id?: string; // user id
    number: string;
    supplier: string | BusinessSupplier; // supplier id
    target: string; // warehouse or org unit or location id
    description: string;
    deleted?: boolean;
    items: StockOrderItem[];
    location: number;
    selected?: boolean;

}
export interface StockOrderRequest {
    createdBy_id?: string; // user id
    supplier: string; // supplier id
    target: string; // warehouse or org unit or location id
    description: string;
    deleted?: boolean;
    items: StockOrderItem[];
    location: number;
}


export class StockOrderImportItem {
    totalQuantity: number;
    reOrderCode: string;
    unitPrice: number;

    constructor(reOrderCode: string, totalQuantity: number, unitPrice: number) {
        this.reOrderCode = reOrderCode;
        this.totalQuantity = totalQuantity;
        this.unitPrice = unitPrice;
    }

}

export interface StockOrderItemDatum {
    itemIndex: string | number;
    serialNumber: string;
    reOrderCode: string;
    supplierProductName: string;
    caseQuantity: string | number;
    master_supplier_container_qty: string | number;
    casesDelivered: string | number;
    costPerCase: string | number;
    unitsDelivered: string | number;
    costPerUnit: string | number;
    lineTotal: string | number;
    product?: Product;
    calculatedLineTotal?: string | number;
    supplier?: BusinessSupplier;
    error?: string;
    error_type?: string;
    suggestions?: MappingSuggestion[];
    supplier_product?: Product;
    supplier_master_product?: MasterProduct;
    reorderCode?: string;
}

export interface StockOrderProductsPayload {
    stockOrderItems: StockOrderItemDatum[];
    business_id: number | string;
    master_supplier_id: number | string;
}

export interface MatchInvoicePayload {
    stockOrderItems: StockOrderItemDatum[],
    business_id: number | string,
    korona_supplier_business_id: number | string;
}

export interface InvoicePDFUpload {
    file: File, korona_supplier_business_id: number | string, business_id: number | string;
}

export const initialStockOrderSummary = {
    itemCount: 0,
    unMappedProducts: 0,
    expectedTotal: '0.00',
    calculatedTotal: '0.00'
};

export const initSpecifiHeaders = {
    caseQuantity: false,
    casesDelivered: false,
    unitsDelivered: false,
    reOrderCode: false,
    serialNumber: false
};
