import { Product } from "../../products/models";
import { Business } from '../../shared/models';

export interface MasterSupplier {
    id: number;
    name: string;
    isSelected?: boolean;
}
export const AttributeTypes = {
    QUANTITY: 'Quantity',
    UNIT_OF_MEASURE: 'Unit of Measure'
};
export interface Attribute {
    id: number;
    name: string;
    checked?: boolean;
    disabled?: boolean;
}
export interface AttributeValue {
    id?: number;
    value: string;
    attribute: Attribute;
}
export interface MasterProductIdentifier {
    id?: number;
    identifier: string;
    quantity: number;
    description: string;
    is_deleted: boolean;
    effective_date: string | null;
    expiry_date: string | null;
}
export interface MasterProduct {
    id: number;
    name: string;
    requires_review?: boolean;
    attributes: AttributeValue[];
    identifiers: MasterProductIdentifier[];
    suppliers?: MasterSupplierProduct[];
    business_products?: MasterProductLinkedRecord[];
}
export interface MasterSupplierProductIdentifier {
    uuid: string;
    mp: number;
    name: string;
    supplier_product_name: string;
    supplier_reorder_code: string;
    supplier_container_qty: number;
    master_supplier_id: number;
    identifier: string;
    master_supplier?: MasterSupplier;
}
export interface MasterProductWithoutIdentifier {
    uuid: string;
    mp_id: number;
    mp_name: string;
    missing_code_type: string;
}
export interface MasterSupplierLinkedRecord {
    id: number;
    business: number;
    master_supplier: MasterSupplier;
    business_supplier: BusinessSupplier;
}
export interface BusinessSupplierLinkRecord {
    id: number;
    business: Business;
    korona_supplier: BusinessSupplier;
    korona_supplier_business: Business;
}
export interface MasterProductLinkedRecord {
    id: number;
    business: Business;
    master_product: MasterProduct;
    business_product: Product;
}
export interface MasterSupplierProduct {
    id: number;
    master_product: MasterProduct;
    master_supplier: MasterSupplier;
    supplier_reorder_code: string;
    supplier_container_qty: number;
    supplier_product_name: string;
}

export interface MappingSuggestion {
    match_type: string;
    master_product: MasterProduct;
    business_products: Product[];
}

export interface BusinessSupplier {
    uuid: string,
    deleted: boolean,
    number: string,
    name: string,
    revision: number,
    customerNumber: string,
    additionalInformation: string,
    contactPersonSalutation: string,
    contactPersonFirstname: string,
    contactPersonSurname: string,
    contactPersonEmail: string,
    contactPersonPhone: string,
    contactPersonMobile: string,
    contactPhone: string,
    contactTelefax: string,
    contactEmail: string,
    contactWebsite: string,
    orderPhone: string,
    orderEmail: string,
    orderWeekdays: string,
    orderTimeFrom: string,
    orderTimeTo: string,
    deliveryWeekdays: string,
    deliveryTime: string,
    shipper: string,
    business: number;
}

export const Code_Filters = {
    NO_CASE_CODE: 'no_case_code',
    NO_RETAIL_CODE: 'no_retail_code',
    NO_CODES: 'no_codes',
    ONLY_RETAIL_CODE: 'only_retail_code',
    ONLY_CASE_CODE: 'only_case_code'
};

export interface BaseFilter {
    id: number;
    name: string;
    displayText: string;
    active: boolean;
    constraint: string;
}

export const CodeFilters: BaseFilter[] = [
    {
        id: 1,
        name: Code_Filters.NO_CODES,
        displayText: 'No Codes',
        constraint: '&master_product_identifier_values__isnull=true',
        active: false
    },
    // {
    //     id: 2,
    //     name: Code_Filters.ONLY_RETAIL_CODE,
    //     displayText: 'Only Retail Code',
    //     constraint: '&master_product_identifier_values__quantity__exact=1',
    //     active: false
    // },
    // {
    //     id: 3,
    //     name: Code_Filters.ONLY_CASE_CODE,
    //     displayText: 'Only Case Code',
    //     constraint: '&master_product_identifier_values__isnull=false&master_product_identifier_values__quantity__gt=1',
    //     active: false
    // },
    {
        id: 4,
        name: Code_Filters.NO_RETAIL_CODE, // can have case code or no code
        displayText: 'No Retail Code',
        constraint: '&no_retail_code=true',
        // constraint: '&master_product_identifier_values__isnull=true&master_product_identifier_values__quantity__gt=1',
        active: false
    },
    {
        id: 5,
        name: Code_Filters.NO_CASE_CODE, // can have retail code or no code
        displayText: 'No Case Code',
        constraint: '&no_case_code=true',
        // constraint: '&master_product_identifier_values__isnull=true&master_product_identifier_values__quantity__exact=1',
        active: false
    }
];
export const brandFilter = {
    id: 1,
    name: 'multi_select_brand', // can have retail code or no code
    displayText: 'Brand',
    constraint: '&master_brand_id__in=',
    active: false
};
export const supplierFilter = {
    id: 1,
    name: 'multi_select_supplier', // can have retail code or no code
    displayText: 'Supplier',
    constraint: '&master_supplier_id__in=',
    active: false
};