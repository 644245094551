// Settings Module
export const GET_DEVICE_LOCATIONS = 'GET_DEVICE_LOCATIONS';
export const GET_DEVICE_LOCATIONS_SUCCESS = 'GET_DEVICE_LOCATIONS_SUCCESS';
export const GET_DEVICE_LOCATIONS_FAILURE = 'GET_DEVICE_LOCATIONS_FAILURE';
export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION';
export const SET_SELECTED_LOCATIONS = 'SET_SELECTED_LOCATIONS';
export const SET_SELECTED_BUSINESS = 'SET_SELECTED_BUSINESS';
export const SET_SELECTED_DASHBOARD_TILES = 'SET_SELECTED_DASHBOARD_TILES';
export const GET_API_URL = 'GET_API_URL';
export const SET_API_URL = 'SET_API_URL';
export const GET_APP_VERSION = 'GET_APP_VERSION';
export const SET_APP_VERSION = 'SET_APP_VERSION';
export const SET_KIOSK_PROFILE = 'SET_KIOSK_PROFILE ';

export const SET_UI_LOADING = 'SET_UI_LOADING';
