import { API_CONFIG } from "../../shared/api/config";
import composeRequest from "../../shared/api/core";

const BASE_URL = API_CONFIG.API_BASE_PATH;

export const API_URLS = {
    stockReceiptsUrl: BASE_URL + 'korona/stockReceipts/',
    stockReceiptSummaryDataUrl: BASE_URL + 'korona/stockReceipts/summary/',
}

export const getStockReceiptsByPage = (
    business_id: number | string,
    page: number, 
    pageSize: number, 
    searchTerm: string,
    orderBy: string | number,
) => {
    let URL = `${API_URLS.stockReceiptsUrl}?business_id=${business_id}&page=${page}&page_size=${pageSize}`;
    if (searchTerm) {
        URL = `${API_URLS.stockReceiptsUrl}?business_id=${business_id}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL , 'GET', null)
}

export const getStockReceiptsDataSummary = (business_id: number | string) => {
    const URL = `${API_URLS.stockReceiptSummaryDataUrl}?business_id=${business_id}`;
    return composeRequest(URL , 'GET', null)
}