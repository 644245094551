import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Theme, createStyles, makeStyles, withStyles } from '@material-ui/core/styles';

import { API_CONFIG } from "../../../shared/api/config";
import composeRequest from '../../../shared/api/core';
import { PaginatedResponse } from '../../../shared/models';


function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

interface Props {
  url: string;
  businessId: string | number;
  labelField: string;
  bindValue: string;
  isResponsePaginated: boolean;
  // multiSelect: boolean;
  onSelectChange: (values: any) => void;
  selected: any[];
}

const BASE_URL = API_CONFIG.API_BASE_PATH;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listbox: {
      maxHeight: 180
    }
  }),
);

export default function AsyncSelect(props: Props) {
  const classes = useStyles({});
  const { url, labelField, businessId, selected, onSelectChange, bindValue, isResponsePaginated } = props;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const loading = open && options.length === 0;
  const [searchTerm, setSearchTerm] = useState('');
  console.log('selected', selected);
  useEffect(() => {
    if (businessId) {
      let active = true;

      if (!loading) {
        return undefined;
      }

      (async () => {
        let URL = `${BASE_URL}${url}/?business_id=${businessId}`;
        if (searchTerm) {
          URL += `&search=${searchTerm}`;
        }
        await sleep(500); // For demo purposes.
        const response = await composeRequest(URL, 'GET', null);
        const data: any = await response.json();

        if (active) {
          if (isResponsePaginated) {
            setOptions(data.results);
          }
          else {
            setOptions(data);
          }
        }
      })();

      return () => {
        active = false;
      };
    }
  }, [loading, searchTerm]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleChange = (event: object, value: any, reason: string) => {
    // console.log('reason: ', reason, 'value: ', value);
    onSelectChange(value);
    // switch(reason){
    //   case 'clear': onSelectChange([]); break;
    //   case 'remove-option': break;
    //   case 'select-option': break;
    // }
  };
  const onInputChange = (e) => {
    const val = e.target ? (e.target.value ? e.target.value.trim() : '') : '';
    setSearchTerm(val);
  };

  return (
    <Autocomplete
      multiple
      size="small"
      // id="async"
      classes={{ listbox: classes.listbox }}
      style={{ width: '100%' }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      filterSelectedOptions={true}
      value={selected}
      getOptionSelected={(option, value) => {
        return option[bindValue] === value[bindValue];
      }}
      getOptionLabel={(option) => option[labelField]}
      onChange={handleChange}
      onInputChange={onInputChange}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          // onChange={(e) => {
          //   e.persist();
          //   const val = e.target ? (e.target.value ? e.target.value.trim() : '') : '';
          //   setSearchTerm(val);
          // }
          // }
          // label="Asynchronous"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="primary" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}