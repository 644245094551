import {
    GET_STOCK_ORDER_PRODUCTS, GET_STOCK_ORDER_PRODUCTS_FAILURE,
    GET_STOCK_ORDER_PRODUCTS_SUCCESS, CREATE_STOCK_ORDER,
    CREATE_STOCK_ORDER_FAILURE, CREATE_STOCK_ORDER_SUCCESS,
    CLEAR_STOCK_ORDER_PRODUCTS, UPDATE_STOCK_ORDER_PRODUCT_IN_LIST,
    GET_STOCK_ORDER_SUMMARY, GET_STOCK_ORDER_SUMMARY_FAILURE,
    GET_STOCK_ORDER_SUMMARY_SUCCESS
} from './actionTypes';
import { addStockOrder, getMatchedInvoiceItemList, getStockOrdersSummary } from '../../api';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { StockOrderItemDatum, MatchInvoicePayload, StockOrder, StockOrderRequest } from '../../models';

export const getMatchedInvoiceItems = (payload: MatchInvoicePayload) => {
    return (dispatch: any) => {
        dispatch(getStockOrderProducts());
        getMatchedInvoiceItemList(payload)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then((data: StockOrderItemDatum[]) => {
                const suggestions = data.filter(i => i.suggestions && i.suggestions.length > 0);
                console.log('suggestions', suggestions);
                dispatch(getStockOrderProductsSuccess(data));
            })
            .catch((err: any) => {
                dispatch(getStockOrderProductsFailure(err));
            });
    };
};

export const createStockOrderAPI = (data: StockOrderRequest) => {
    return (dispatch: any) => {
        dispatch(createStockOrder());
        addStockOrder(data)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then(jsonData => {
                console.log('createStockOrder success', jsonData);
                dispatch(createStockOrderSuccess(jsonData));
            })
            .catch((error: any) => {
                console.log(error);
                dispatch(createStockOrderFailure(error));
            });
    };
};

export interface StockOrderSummaryResponse {
    lastCreatedOn: string;
    oldestOpenDate: string;
    openCount: number;
    totalCount: number;
}

export function getStockOrderDataSummary(business_id: number | string) {
    return (dispatch: any) => {
        dispatch(getStockOrderSummary());
        getStockOrdersSummary(business_id)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then((data: StockOrderSummaryResponse) => {
                dispatch(getStockOrderSummarySuccess(data));
            })
            .catch((err: any) => {
                dispatch(getStockOrderSummaryFailure(err));
            });
    };
}

export const clearStockOrderProducts = () => {
    return {
        type: CLEAR_STOCK_ORDER_PRODUCTS
    };
};

export const updateStockOrderProductInList = (data: any) => {
    return {
        type: UPDATE_STOCK_ORDER_PRODUCT_IN_LIST,
        data
    };
};

const getStockOrderProducts = () => {
    return {
        type: GET_STOCK_ORDER_PRODUCTS
    };
};

const getStockOrderProductsSuccess = (data: StockOrderItemDatum[]) => {
    return {
        type: GET_STOCK_ORDER_PRODUCTS_SUCCESS,
        data
    };
};

const getStockOrderProductsFailure = (err: any) => {
    return {
        type: GET_STOCK_ORDER_PRODUCTS_FAILURE,
        err
    };
};
const createStockOrder = () => {
    return {
        type: CREATE_STOCK_ORDER
    };
};

const createStockOrderSuccess = (data: StockOrderRequest) => {
    return {
        type: CREATE_STOCK_ORDER_SUCCESS,
        data
    };
};

const createStockOrderFailure = (err: any) => {
    return {
        type: CREATE_STOCK_ORDER_FAILURE,
        err
    };
};

const getStockOrderSummary = () => {
    return {
        type: GET_STOCK_ORDER_SUMMARY
    };
};

const getStockOrderSummarySuccess = (data: StockOrderSummaryResponse) => {
    return {
        type: GET_STOCK_ORDER_SUMMARY_SUCCESS,
        data
    };
};

const getStockOrderSummaryFailure = (err: any) => {
    return {
        type: GET_STOCK_ORDER_SUMMARY_FAILURE,
        err
    };
};