import React, { useEffect, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import SvgIcon from '@material-ui/core/SvgIcon';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import RefreshIcon from '@material-ui/icons/Refresh';
import { getRandomString } from '../../../shared/lib/utils';
import { addDevice, getDeviceRequests } from '../../api';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { ToastType } from '../../../shared/models';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import WebSocketInstance from '../../../shared/api/core/Websocket';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const QRCode = require('qrcode.react');

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        heading: {
            // paddingTop: 16,
            paddingBottom: 16,
        },
        subHeading: {
            padding: '16px 0',
        },
        stepRootContent: {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            padding: 16,
            backgroundColor: '#f6f7fc',
            borderRadius: 4,
        },
        content: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
            padding: 16,
            backgroundColor: '#f6f7fc',
            borderRadius: 4,
        },
        instruction: {
            fontSize: '1rem',
            padding: '8px 0',
        },
        instructionLarge: {
            fontSize: '1.35rem',
            paddingBottom: 8,
            // padding: '8px 0'
        },
        link: {
            fontSize: '1.15rem',
            paddingBottom: 8,
            // padding: '8px 0',
            color: theme.palette.primary.main,
        },
        primary: {
            color: theme.palette.primary.main,
        },
        requestBox: {
            padding: '0 24px',
            // border: '2px solid #cdcdcd',
            // borderRadius: 4
        },
        reload: {
            width: 180,
            height: 180,
            borderRadius: '50%',
            position: 'absolute',
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            fontSize: 16,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
            },
            boxSizing: 'border-box',
            boxShadow:
                '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
        },
        button: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
            alignSelf: 'flex-end',
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
    })
);

interface RegisterDeviceProps {
    onClose?: (data?: any) => void;
    isOpen: boolean;
    onOk?: () => void;
    userData?: any;
}

function getSteps() {
    return ['Get The Konnect App For Android', 'Scan QR Code', 'Approve Device'];
}
const baseUrl = 'http://konnect.conntrix.net/app/';

export interface DeviceRequest {
    scan_code: string;
    model: string;
    full_info: string;
    device_id: string;
    device_platform: string;
    detail?: any;
}

const RegisterDeviceModal = (props: RegisterDeviceProps) => {
    const classes = useStyles({});
    const [qrcode, setQRCode] = useState<string | null>(null);
    const [allowApproval, setAllowApproval] = useState(true);
    const [deviceRequest, setDeviceRequest] = useState<DeviceRequest | null>(null);
    const [toast, setToast] = useState({
        open: false,
        message: '',
        variant: 'success',
    } as ToastType);
    const [deviceLabel, setDeviceLabel] = useState('');
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const [deviceDldLink, setDeviceDldLink] = React.useState('');
    const [playstoreCompatible, setPlaystoreCompatible] = React.useState('');

    const handleChange = (event: React.ChangeEvent<{ value: unknown; }>) => {
        setPlaystoreCompatible(event.target.value as string);
        switch (event.target.value) {
            case '0':
                setDeviceDldLink(`${baseUrl}dl/`);
                break;
            case '1':
                setDeviceDldLink(`${baseUrl}store/dl/`);
                break;
            default:
                setDeviceDldLink('');
        }
    };

    useEffect(() => {
        if (props.isOpen) {
            generateAuthorizationCode();
        }
    }, [props.isOpen]);

    const handleNext = () => {
        if (activeStep === 2) {
            resetData();
            setActiveStep(0);
            closeModal(true);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        resetData();
        setActiveStep(0);
    };

    useEffect(() => {
        let codeInterval: any = null;
        if (props.isOpen) {
            codeInterval = setInterval(() => {
                setQRCode(null);
                WebSocketInstance && WebSocketInstance.disconnect();
            }, 60000);
        }
        return () => clearInterval(codeInterval);
    }, [props.isOpen]);

    const getIncomingRequest = (data: DeviceRequest, deviceId: string, qrcode: string) => {
        if (!('detail' in data)) {
            const { scan_code } = data;
            if (scan_code && scan_code === qrcode) {
                setDeviceRequest(data);
                const label = data.model.replace(/\s/g, '_');
                setDeviceLabel(label);
                setQRCode(null);
                setActiveStep(2);
            }
        }
    };

    const onApproveDeviceCallback = (
        data: { detail: any; device_id: string; },
        deviceId: string
    ) => {
        if (data) {
            const { detail, device_id } = data;
            if (detail && device_id === deviceId) {
                let msg = 'New Device Approved Successfully!';
                setAllowApproval(false);
                setToast({
                    open: true,
                    message: msg,
                    variant: 'success',
                });
            }
        }
    };

    const onApproveDevice = () => {
        if (!deviceLabel) {
            setToast({
                open: true,
                message: 'Please enter a device label',
                variant: 'error',
            });
            return;
        }
        const data = deviceRequest;
        const { business_id } = props.userData.selectedBusiness;
        if (data) {
            const payload = {
                device_id: data['device_id'],
                business: business_id,
                device_name: deviceLabel,
                device_platform: data['device_platform'],
                active: true,
                full_info: data['full_info'],
            };
            WebSocketInstance.setDeviceId(data['device_id']);
            WebSocketInstance.approveDeviceRequest(payload);
        }
    };

    const handleInputChange = (event: any) => {
        setDeviceLabel(event.target.value);
    };

    const generateAuthorizationCode = () => {
        const authorization_code = getRandomString(16);
        setQRCode(authorization_code);
        setDeviceRequest(null);
        WebSocketInstance.setRequestCode(authorization_code);
        WebSocketInstance.addCallbacks(getIncomingRequest, onApproveDeviceCallback);
        WebSocketInstance.connect();

        // WebSocketInstance.fetchIncomingRequests(authorization_code);
    };

    const approveDevice = () => {
        if (!deviceLabel) {
            setToast({
                open: true,
                message: 'Please enter a device label',
                variant: 'error',
            });
            return;
        }
        const data = deviceRequest;
        const { business_id } = props.userData.selectedBusiness;
        if (data) {
            const payload = {
                device_id: data['device_id'],
                business: business_id,
                device_name: deviceLabel,
                device_platform: data['device_platform'],
                active: true,
                full_info: data['full_info'],
            };
            addDevice(payload)
                .catch(handleError) // handle network issues
                .then(checkStatus)
                .then(parseJSON)
                .then((data) => {
                    let msg = 'New Device Approved Successfully!';
                    setToast({
                        open: true,
                        message: msg,
                        variant: 'success',
                    });
                    setAllowApproval(false);
                    // resetData();
                    // handleNext();
                    // closeModal(false);
                })
                .catch((error: any) => {
                    let msg = 'Failed to Approve Device';
                    console.log(error);
                    if (error && error.data) {
                        if ('device_id' in error.data) {
                            msg = 'This device already exists!';
                            resetData();
                            setAllowApproval(false);
                        }
                    }
                    setToast({
                        open: true,
                        message: msg,
                        variant: 'error',
                    });
                });
        }
    };

    const resetData = () => {
        setQRCode(null);
        setDeviceRequest(null);
        setDeviceLabel('');
        setAllowApproval(true);
        WebSocketInstance.setRequestCode(null);
    };
    const closeModal = (success = false) => {
        resetData();
        setActiveStep(0);
        handleCloseToast();
        WebSocketInstance.setRequestCode(null);
        WebSocketInstance.setReopenConnection(false);
        WebSocketInstance.disconnect();
        props.onClose && props.onClose(success);
    };
    const handleCloseToast = (event?: React.SyntheticEvent, reason?: string) => {
        setToast({ open: false, message: '', variant: 'success' });
    };

    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <div
                        className={classes.stepRootContent}
                        style={{ minHeight: 200, flexDirection: 'column' }}
                    >
                        <div>
                            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 8 }}>
                                <Typography variant="h6">
                                    Is Google Playstore installed on the device?
                                </Typography>
                                <Select
                                    id="demo-simple-select"
                                    value={playstoreCompatible}
                                    onChange={handleChange}
                                    style={{ marginLeft: 16, minWidth: 75 }}
                                >
                                    <MenuItem value={'1'}>Yes</MenuItem>
                                    <MenuItem value={'0'}>No</MenuItem>
                                </Select>
                            </div>
                            <div style={{ fontSize: '0.95rem', paddingBottom: 16 }}>
                                Note: For Axist devices, please select <strong>No</strong>                            </div>
                        </div>
                        {deviceDldLink && (
                            <div className={classes.content} style={{ padding: 0 }}>
                                <div
                                    style={{
                                        flex: 2,
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: 220,
                                            justifyContent: 'space-around',
                                        }}
                                    >
                                        <div>
                                            <Typography
                                                variant="body1"
                                                className={classes.instructionLarge}
                                            >
                                                Download the app from:
                                            </Typography>
                                            <Typography variant="body1" className={classes.link}>
                                                {deviceDldLink}
                                            </Typography>
                                        </div>
                                        <Typography
                                            variant="body1"
                                            className={classes.instructionLarge}
                                        >
                                            Or scan QR Code to download.
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ flex: 1, textAlign: 'end' }}>
                                    <QRCode
                                        id="qrcode-app-link"
                                        value={deviceDldLink}
                                        size={220}
                                        level={'H'}
                                        includeMargin={false}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                );
            case 1:
                return (
                    <div className={classes.content}>
                        <div style={{ alignSelf: 'center' }}>
                            <Typography variant="h6" className={classes.heading}>
                                To add a new device to your account:
                            </Typography>
                            <div>
                                <Typography variant="body1" className={classes.instruction}>
                                    1. Open Konnect on your device
                                </Typography>
                                <Typography variant="body1" className={classes.instruction}>
                                    2. Tap on{' '}
                                    <span className={classes.primary}>New Device?</span> and Tap{' '}
                                    <span className={classes.primary}>Scan</span>
                                </Typography>
                                <Typography variant="body1" className={classes.instruction}>
                                    3. Point your phone to this screen to capture the code
                                </Typography>
                            </div>
                        </div>
                        <div style={{ boxSizing: 'border-box' }}>
                            {qrcode && (
                                <QRCode
                                    id="qrcode"
                                    value={qrcode}
                                    size={220}
                                    level={'H'}
                                    includeMargin={false}
                                />
                            )}
                            {!qrcode && (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        boxSizing: 'border-box',
                                    }}
                                >
                                    <img
                                        src={`${window.location.origin}/assets/qrcode.png`}
                                        height="220"
                                        width="220"
                                    />
                                    <div
                                        className={classes.reload}
                                        onClick={generateAuthorizationCode}
                                    >
                                        <RefreshIcon style={{ fontSize: '3rem' }} />
                                        <div>Reload QR Code</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div style={{ backgroundColor: '#f6f7fc' }}>
                        <Container maxWidth="sm" style={{ paddingTop: 16, paddingBottom: 16 }}>
                            <div className={classes.requestBox}>
                                <Typography
                                    variant="h6"
                                    style={{ paddingTop: 16, paddingBottom: 8 }}
                                >
                                    Incoming Request From Device:
                                </Typography>
                                <Typography variant="body1" className={classes.heading}>
                                    {deviceRequest
                                        ? deviceRequest.full_info
                                            ? deviceRequest.full_info
                                            : deviceRequest.model
                                                ? deviceRequest.model
                                                : ''
                                        : ''}
                                </Typography>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        paddingBottom: 16,
                                    }}
                                >
                                    <TextField
                                        id="device-name"
                                        label="Enter Device Name"
                                        placeholder="Device Name"
                                        style={{ marginRight: 16 }}
                                        required
                                        value={deviceLabel}
                                        onChange={(event) => handleInputChange(event)}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        // className={classes.button}
                                        onClick={onApproveDevice}
                                        disabled={!deviceLabel || !allowApproval}
                                    >
                                        Approve
                                    </Button>
                                </div>
                            </div>
                        </Container>
                    </div>
                );
            default:
                return 'Unknown step';
        }
    };

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={props.isOpen}
                onClose={() => closeModal()}
                aria-labelledby="form-dialog-title"
                disableBackdropClick={true}
            >
                <DialogContent style={{ padding: '24px' }}>
                    <div style={{ textAlign: 'right' }}>
                        <IconButton aria-label="close" onClick={() => closeModal()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <Stepper
                        activeStep={activeStep}
                        orientation="vertical"
                        style={{ paddingTop: 0, marginTop: -16 }}
                    >
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                                <StepContent>
                                    <div>{getStepContent(index)}</div>
                                    <div className={classes.actionsContainer}>
                                        <div>
                                            {allowApproval && (
                                                <Button
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                    className={classes.button}
                                                >
                                                    Back
                                                </Button>
                                            )}
                                            {activeStep === 2 && (
                                                <Button
                                                    onClick={handleReset}
                                                    className={classes.button}
                                                >
                                                    Reset
                                                </Button>
                                            )}
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={
                                                    (activeStep === 0 && !deviceDldLink) ||
                                                    (activeStep === 1 && !deviceRequest) ||
                                                    (activeStep === 2 && allowApproval)
                                                }
                                                onClick={handleNext}
                                                className={classes.button}
                                            >
                                                {activeStep === steps.length - 1
                                                    ? 'Finish'
                                                    : 'Next'}
                                            </Button>
                                        </div>
                                    </div>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </DialogContent>
                <Snackbar open={toast.open} autoHideDuration={6000} onClose={handleCloseToast}>
                    <Alert onClose={handleCloseToast} severity={toast.variant}>
                        {toast.message}
                    </Alert>
                </Snackbar>
            </Dialog>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        userData: state.userLocalData,
    };
};

export default connect(
    mapStateToProps,
    null
)(RegisterDeviceModal);
