import { Query } from 'material-table';
// import { TextFieldVariant }
import { Options } from 'material-table/types';
import RowData from 'material-table/types';

export const replaceHistory = (query, history, state?: any) => {
    state = state || {};
    if (history && history.location && history.location.pathname) {
        const { page, pagSize, search, orderDirection, orderBy } = query;
        let searchParams = `?page=${query.page}&pageSize=${query.pageSize}`;
        if (query.search) {
            searchParams += `&search=${query.search}`;
        }
        console.log('15', query);
        if (orderDirection && orderBy) {
            console.log(query);
            if (orderBy.field) {
                searchParams += `&orderBy=${orderBy.field}&orderDirection=${orderDirection}`;
            }
        }
        console.log('replacing searchparams', searchParams);
        history.replace({
            pathname: history.location.pathname,
            search: searchParams,
            state: state,
        });
    }
};

export const defaultGridPaginationQuery = {
    filters: [],
    orderBy: undefined,
    orderDirection: 'asc',
    page: 1,
    pageSize: 24,
    search: '',
    totalCount: 0,
    pageCount: 5,
    paginationDisabled: false,
};

const initialTableQuery = {
    filters: [],
    orderBy: undefined,
    orderDirection: 'asc',
    page: 0,
    pageSize: 10,
    search: '',
    totalCount: 0,
} as Query<any>;

export const initQueryParams = (query) => {
    const q = {
        filters: [],
        orderBy: getOrderByField(query),
        orderDirection: getOrderDirection(query),
        pageSize: getPageSize(query),
        page: getPageNumber(query),
        search: getSearchText(query),
        totalCount: 0,
        state: {},
    };
    // console.log('q', q);
    return q;
};

export const getPageSize = (query) => {
    const qPageSize = query.get('pageSize');
    const pageSize = qPageSize ? Number(qPageSize) : 10;
    return pageSize;
};

export const getPageNumber = (query) => {
    const qPage = query.get('page');
    const page = qPage ? Number(qPage) : 0;
    return page;
};

export const getSearchText = (query) => {
    const s = query.get('search');
    const search = s ? String(s) : '';
    return search;
};

export const getOrderByField = (query) => {
    const s = query.get('orderBy');
    const orderBy = s ? String(s) : undefined;
    return orderBy;
};

export const getOrderDirection = (query) => {
    const s = query.get('orderDirection');
    const orderDirection = s ? String(s) : 'asc';
    return orderDirection;
};

export const useQuery = (useLocation) => {
    return new URLSearchParams(useLocation().search);
};

//@ts-ignore
export const tableOptions = (tableQuery?: any) => {
    const options: Options<any> = {
        debounceInterval: 650,
        emptyRowsWhenPaging: false,
        pageSize: 10,
        initialPage: 0, // using hotfix mbrn library
        ...{ searchText: '' },
        // ...{ searchText: '' },
        pageSizeOptions: [10, 50, 100, 500, 1000],
        headerStyle: {
            backgroundColor: '#eee',
        },
        sorting: false,
        searchFieldVariant: 'outlined',
        thirdSortClick: false,
    };
    if (tableQuery) {
        return {
            ...options,
            sorting: tableQuery.sorting,
            pageSize: tableQuery.pageSize,
            initialPage: tableQuery.page, // using hotfix mbrn library
            ...{ searchText: tableQuery.search },
        };
    }
    return tableOptions;
};
