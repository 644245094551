import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable, { MaterialTableProps } from 'material-table';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { Device } from '../../models';

import { getDevicesByPage, deleteDevice, updateDevice } from '../../api';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { addDevice } from '../../data/actions';
import { getBusinessListFromAPI } from '../../../shared/data/actions';
import { PaginatedResponse, Business } from '../../../shared/models';
import { CustomTableTitle, ErrorStatusBar } from '../../../shared/components';
import {
    EMPTY_ROW_MSG,
    ERROR_MSG,
    ERROR_500,
    NO_PERMISSIONS_ERROR_MSG,
} from '../../../shared/lib/Localization';
import {
    isSuperAdmin,
    hasAddPermission,
    hasUpdatePermission,
    hasDeletePermission,
} from '../../../shared/lib/PermissionManager';
import {
    initQueryParams,
    replaceHistory,
    tableOptions,
    useQuery,
} from '../../../shared/lib/TableQueryParams';
import {
    Toast,
    enqueueSnackbar,
    ToastSuccess,
    ToastError,
} from '../../../shared/components/Notifier';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { RegisterDeviceModal } from '../../components';
// const QRCode = require('qrcode.react');
const useStyles = makeStyles((theme: Theme) => createStyles({}));
const permission_model = 'device';

const registerDeviceModalData = {
    open: false,
    onClose: null,
};

const DeviceManagerScreen = (props: any) => {
    const classes = useStyles({});
    const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
    const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
    const [tableEditableProp, setTableEditableProp] = useState({});
    const tableRef = useRef<{ onQueryChange: any; }>();
    let locationQuery = useQuery(useLocation);
    const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
    const [isLoading, setLoading] = useState(false);
    const [deviceCode, setDeviceCode] = useState('');
    const [newDeviceModalData, setNewDeviceModalData] = useState(registerDeviceModalData);

    const getAddFn = () => {
        return (newData: any) =>
            new Promise((resolve, reject) => {
                setTimeout(() => {
                    {
                        props.addDevice(newData);
                    }
                    resolve();
                }, 1000);
            });
    };

    const getDeleteFn = () => {
        return (oldData: any) =>
            new Promise((resolve, reject) => {
                deleteDevice(oldData)
                    .catch(handleError) // handle network issues
                    .then(checkStatus)
                    .then(parseJSON)
                    .then((jsonData) => {
                        setErrorStatusVisible(false);
                        setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                        props.showToast({
                            message: 'Device deleted successfully!',
                            options: ToastSuccess,
                        });
                        resolve();
                    })
                    .catch((error: any) => {
                        let msg = 'Failed to delete device.';
                        if (error && error.status) {
                            if (error.status === 403 || error.status === 401) {
                                msg = NO_PERMISSIONS_ERROR_MSG;
                            } else if (error.status === 500) {
                                msg = ERROR_500;
                            }
                        }
                        props.showToast({ message: msg, options: ToastError });
                        resolve();
                    });
            });
    };

    const getUpdateFn = () => {
        return (newData: any, oldData: any) =>
            new Promise((resolve, reject) => {
                updateDevice(newData)
                    .catch(handleError) // handle network issues
                    .then(checkStatus)
                    .then(parseJSON)
                    .then((jsonData) => {
                        setErrorStatusVisible(false);
                        setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                        props.showToast({
                            message: 'Device updated successfully!',
                            options: ToastSuccess,
                        });
                        resolve();
                    })
                    .catch((error: any) => {
                        let msg = 'Failed to update device.';
                        if (error && error.status) {
                            if (error.status === 403 || error.status === 401) {
                                msg = NO_PERMISSIONS_ERROR_MSG;
                            } else if (error.status === 500) {
                                msg = ERROR_500;
                            }
                        }
                        props.showToast({ message: msg, options: ToastError });
                        resolve();
                    });
            });
    };

    useEffect(() => {
        const { userData } = props.userData.userData;
        const editable: any = {};
        if (userData) {
            // if (hasAddPermission(userData.is_superuser,
            //     userData.user_permissions, permission_model)) {
            //     editable['onRowAdd'] = getAddFn();
            // }
            if (
                hasUpdatePermission(
                    userData.is_superuser,
                    userData.user_permissions,
                    permission_model
                )
            ) {
                editable['onRowUpdate'] = getUpdateFn();
            }
            if (
                hasDeletePermission(
                    userData.is_superuser,
                    userData.user_permissions,
                    permission_model
                )
            ) {
                editable['onRowDelete'] = getDeleteFn();
            }
            setTableEditableProp(editable);
        }
    }, [props.userData]);

    useEffect(() => {
        if (isSuperAdmin(props)) {
            props.getBusinessList();
        }
    }, []);

    useEffect(() => {
        if (isSuperAdmin(props) && !isLoading) {
            tableRef.current && tableRef.current.onQueryChange();
        }
    }, [props.userData.selectedBusiness]);

    const handleSwitchChange = (field: string, deviceData: any) => {
        deviceData[field] = !deviceData[field];
    };

    const SelectBusinessEditComponent = (componentProps: any) => {
        const { value, onChange } = componentProps.colDataProps;
        return (
            <Select
                value={value ? value : ''}
                onChange={(e) => {
                    onChange(e.target.value);
                }}
                inputProps={{
                    name: 'business',
                    id: 'select-business',
                }}
            >
                {props.businessList &&
                    props.businessList.data &&
                    props.businessList.data.map((b: Business) => (
                        <MenuItem value={b.id} key={String(b.id)}>
                            {b.name}
                        </MenuItem>
                    ))}
            </Select>
        );
    };

    const isSuperUser = () => { };

    return (
        <Container maxWidth="lg">
            <ErrorStatusBar isVisible={isErrorStatusVisible} />
            <div className="table-wrapper">
                <MaterialTable
                    options={{ 
                        ...tableOptions(tableQuery),
                        thirdSortClick: false,
                        draggable: false,
                        sorting: true 
                    }}
                    tableRef={tableRef}
                    localization={{
                        body: {
                            emptyDataSourceMessage: emptyDataSourceMessage,
                        },
                    }}
                    columns={[
                        { title: 'Label', field: 'device_name' },
                        { title: 'Device SN', field: 'device_id', headerStyle: { fontSize: 12 } },
                        {
                            title: 'Business',
                            field: 'business',
                            hidden: !isSuperAdmin(props),
                            editComponent: (props) => {
                                return <SelectBusinessEditComponent colDataProps={props} />;
                            },
                            render: (rowData: any) => {
                                if (props.businessList && props.businessList.data) {
                                    const filteredBusiness = props.businessList.data.filter(
                                        (b: Business) => b.id === rowData.business
                                    )[0];
                                    if (filteredBusiness) {
                                        return <div>{filteredBusiness.name}</div>;
                                    }
                                }
                                return null;
                            },
                        },
                        {
                            title: 'Enabled',
                            field: 'active',
                            type: 'boolean',
                            sorting: false,
                            render: (rowData: any) => (
                                <Switch
                                    checked={rowData.active}
                                    // onClick={() => handleSwitchChange('active',rowData)}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                            ),
                        },
                    ]}
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            if (!isLoading && props.userData) {
                                const sb = props.userData.selectedBusiness;
                                replaceHistory(query, props.history);
                                let pageData = {
                                    data: [] as Device[],
                                    totalCount: 0,
                                    page: query.page,
                                };
                                setLoading(true);
                                let orderBy = '';
                                if (query.orderBy) {
                                    orderBy = query.orderBy.field as string;
                                    orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                                }
                                if (sb && sb.business_id) {
                                    getDevicesByPage(
                                        sb.business_id,
                                        query.page + 1,
                                        query.pageSize,                                        
                                        query.search,
                                        orderBy
                                    )
                                        .catch(handleError) // handle network issues
                                        .then(checkStatus)
                                        .then(parseJSON)
                                        .then((data: PaginatedResponse<Device>) => {
                                            setErrorStatusVisible(false);
                                            setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                                            pageData = {
                                                data: data.results,
                                                totalCount: data.count,
                                                page: query.page,
                                            };
                                            setTableQuery({
                                                ...tableQuery,
                                                orderBy,
                                                totalCount: data.count,
                                                page: query.page,
                                                pageSize: query.pageSize,
                                            });
                                            setLoading(false);
                                            resolve(pageData);
                                        })
                                        .catch((error: any) => {
                                            if (error) {
                                                if (error.status && error.status === 500) {
                                                    setEmptyDataSourceMessage(ERROR_500);
                                                    setErrorStatusVisible(false);
                                                } else if (
                                                    error.status === 403 ||
                                                    error.status === 401
                                                ) {
                                                    setEmptyDataSourceMessage(ERROR_MSG);
                                                    setErrorStatusVisible(true);
                                                } else {
                                                    setEmptyDataSourceMessage(ERROR_MSG);
                                                    setErrorStatusVisible(false);
                                                }
                                            }
                                            setTableQuery({
                                                ...tableQuery,
                                                totalCount: 0,
                                                page: query.page,
                                                pageSize: query.pageSize,
                                            });
                                            setLoading(false);
                                            resolve(pageData);
                                        });
                                }
                            }
                        })
                    }
                    title={
                        <CustomTableTitle
                            history={props.history}
                            title={'Devices'}
                            icon={SVG_ICONS.devices}
                        />
                    }
                    actions={[
                        {
                            icon: 'refresh',
                            tooltip: 'Refresh Data',
                            isFreeAction: true,
                            onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                        },
                        {
                            icon: 'add_box',
                            tooltip: 'Add Device',
                            isFreeAction: true,
                            onClick: (event) =>
                                setNewDeviceModalData({ ...newDeviceModalData, open: true }),
                        },
                    ]}
                    editable={tableEditableProp}
                />
            </div>
            <RegisterDeviceModal
                isOpen={newDeviceModalData.open}
                onClose={(data) => {
                    if (data) {
                        tableRef.current && tableRef.current.onQueryChange();
                    }
                    setNewDeviceModalData({ ...newDeviceModalData, open: false });
                }}
            />
        </Container>
    );
};

const mapStateToProps = (state: any) => {
    return {
        businessList: state.sharedModuleData.businessList,
        userData: state.userLocalData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        addDevice: (deviceData: any) => dispatch(addDevice(deviceData)),
        getBusinessList: () => dispatch(getBusinessListFromAPI()),
        showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceManagerScreen);
