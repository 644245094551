
const GlobalStyles = {
    primaryColor: '#1976d2',
    primaryDark: '#0d47a1',
    primaryLight: '#f5f5f5',
    secondary: '#455a64',
    lightTheme: '#ffffff',
    textLight: '#757575',
    statusBarBackground: '#c2c2c2',
    errorText: '#721C24',
    successText: '#125724',
    infoText: '#1B1E21',
    warningText: '#ffa000',
    errorBackground: '#F8D7DA',
    successBackground: '#D4EDDA',
    infoBackground: '#D6D8D9',
    iconColor: '#757575',
    iconColorLight: '#6c6c6c',
    navMenuIconTintColor: '#757575',
    button: {
        borderRadius: 4,
        paddingTop: 0,
        paddingBottom: 0,
        height: 39,
    },
    tileErrorBackground: '#ffebee',
    tileErrorBackgroundBorder: '#ffcdd2',
    tileInfoBackground: '#eceff1',
    tileInfoBackgroundBorder: '#cfd8dc',
    tileSuccessBackground: '#e8f5e9',
    tileSuccessBackgroundBorder: '#c8e6c9',
    tileWarningBackground: '#fff8e1',
    tileWarningBackgroundBorder: '#ffecb3',
    chipBackground: '#eeeeee',
    lightBlue: '#43A5F5',
    green: '#9BCC65',
    // orange: '#FF7143'
    orange: '#f57c00',
    error: '#d32f2f',
    red: '#dc3545'
};

// const GlobalStyles = {
//     primaryColor: '#3F51B5',
//     primaryLight: '#f5f5f5',
//     textLight: '#757575',
//     statusBarBackground: '#c2c2c2',
//     errorText: '#721C24',
//     successText: '#125724',
//     infoText: '#1B1E21',
//     warningText: '#ffa000',
//     errorBackground: '#F8D7DA',
//     successBackground: '#D4EDDA',
//     infoBackground: '#D6D8D9',
//     iconColor: '#757575',
//     navMenuIconTintColor: '#757575',
//     button: {
//         borderRadius: 4,
//         paddingTop: 0,
//         paddingBottom: 0,
//         height: 39,
//     },
//     tileErrorBackground: '#ffebee',
//     tileErrorBackgroundBorder: '#ffcdd2',
//     tileInfoBackground: '#eceff1',
//     tileInfoBackgroundBorder: '#cfd8dc',
//     tileSuccessBackground: '#e8f5e9',
//     tileSuccessBackgroundBorder: '#c8e6c9',
//     tileWarningBackground: '#fff8e1',
//     tileWarningBackgroundBorder: '#ffecb3',
//     chipBackground: '#eeeeee',
// }

export default GlobalStyles;