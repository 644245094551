import {
    GET_PRODUCT_BY_CODE, GET_PRODUCT_BY_CODE_SUCCESS, GET_PRODUCT_BY_CODE_FAILURE, CLEAR_PRODUCT_FROM_STORE,
    GET_PRODUCT_BY_CODE_AND_SUPPLIER, GET_PRODUCT_BY_CODE_AND_SUPPLIER_SUCCESS, GET_PRODUCT_BY_CODE_AND_SUPPLIER_FAILURE,
    
 } from '../actions/actionTypes';
import { API_CONFIG } from '../../../shared/api/config';
import composeRequest from '../../../shared/api/core';
import { checkStatus, handleError, parseJSON } from '../../../shared/api/core';

import { FieldValues, ZPLConverter } from '../../../labeler/utils/ZPLConverter';
import { getZPLLabelPreview } from 'components/src/modules/labeler/data/actions/labelerActions';
import { LabelerTemplate } from '../../../labeler/models';
import { Product, ProductCode } from '../../models';
import moment from 'moment';
import { API_URLS } from '../../api/ApiUrls';

export const getProductByCodeFromAPI = (businessId: number | string,
    code: string) => {
    return (dispatch: any) => {
        dispatch(getProductByCode());
        let productCode = code.trim();
        const URL = API_URLS.productByCodeUrl + '?business_id=' + businessId +
            '&pcode=' + productCode;
        return composeRequest(URL, 'GET', null)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then((data: any) => {
                dispatch(getProductByCodeSuccess(data));
            })
            .catch((err: any) => {
                // console.log(err); 
                dispatch(getProductByCodeFailure(err));
            });
    };
};




// Combined Reducer
export const getProductAndTemplatePreview = (businessId: number | string,
    code: string, selectedTemplate: LabelerTemplate) => {
    return (dispatch: any, getState: any) => {
        return dispatch(getProductByCodeFromAPI(businessId, code)).then(() => {
            // console.log('getProductAndTemplatePreview', getState().product)
            const { error, errorData, product } = getState().product;
            if (!error && !errorData) {
                if (selectedTemplate && !product.error) {
                    const selTemp = selectedTemplate;
                    // adjustment due to rn camera library which 
                    // converts upc-a codes to ean13 by adding a leading 0 to upc-a codes
                    // if (code.length === 13 && code.startsWith('0')) {
                    const scannedCode = product.article_codes.filter((c: ProductCode) => c.code === code.slice(1))[0];
                    if (scannedCode) {
                        code = scannedCode.code;
                    }
                    // }
                    const zpl = ZPLConverter.convertJSONToZPL(selTemp.content);
                    const productPrice = getProductPrice(product, code);
                    const productName = getProductNameForLabel(product);
                    const values: FieldValues = {
                        productName: productName,
                        productPrice: productPrice,
                        productLookupCode: code,
                        number: product.number
                    };
                    if (product.commodityGroup) {
                        if (product.commodityGroup.number) {
                            values.commodityGroupNumber = product.commodityGroup.number;
                        }
                        if (product.commodityGroup.name) {
                            values.commodityGroupName = product.commodityGroup.name;
                        }
                    }

                    const filledInZpl = ZPLConverter.fillInfoInZPL(zpl, values);
                    return dispatch(getZPLLabelPreview(filledInZpl));

                } else {
                    return;
                }
            }

        });
    };
};

export const getProductPrice = (product: Product, code: string) => {
    if (product && product.prices) {
        if (product.prices.length > 0) {
            const codedPrices = product.prices.filter(p => p.articleCode === code);
            if (codedPrices.length > 0) {
                return codedPrices[0].value.toString();
            }

            let pricesSortedByDate = [...product.prices];
            pricesSortedByDate.sort((a, b) => {
                const momentA = moment(a.validFrom);
                const momentB = moment(b.validFrom);
                if (momentA.isAfter(momentB)) {
                    return -1;
                } else if (momentA.isBefore(momentB)) {
                    return 1;
                }
                return 0;
            });
            return pricesSortedByDate[0].value.toString();
        }
    }
    return '0.00';
};

const getProductNameForLabel = (product: Product) => {
    let productName = product.name;
    if (product.article_texts && product.article_texts.length > 0) {
        const labelTexts = product.article_texts.filter(t => t.type === 'ARTICLETEXT_LABEL_TEXT');
        if (labelTexts.length > 0) {
            productName = labelTexts[0].text;
        }
    }
    return productName;
};



const getProductByCode = () => {
    return {
        type: GET_PRODUCT_BY_CODE
    };
};

const getProductByCodeSuccess = (data: any) => {
    return {
        type: GET_PRODUCT_BY_CODE_SUCCESS,
        data
    };
};

const getProductByCodeFailure = (error: any) => {
    return {
        type: GET_PRODUCT_BY_CODE_FAILURE,
        error
    };
};

const getProductByCodeAndSupplier = () => {
    return {
        type: GET_PRODUCT_BY_CODE_AND_SUPPLIER
    };
};

const getProductByCodeAndSupplierSuccess = (data: any) => {
    return {
        type: GET_PRODUCT_BY_CODE_AND_SUPPLIER_SUCCESS,
        data
    };
};

const getProductByCodeAndSupplierFailure = (error: any) => {
    return {
        type: GET_PRODUCT_BY_CODE_AND_SUPPLIER_FAILURE,
        error
    };
};

export const removeProductFromStore = () => {
    return {
        type: CLEAR_PRODUCT_FROM_STORE
    };
};

export const selectProductForLabelerModule = (data: Product) => {
    return {
        type: GET_PRODUCT_BY_CODE_SUCCESS,
        data
    };
};