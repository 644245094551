import { SVG_ICONS } from '../../../../shared/icons/SvgIcons';
import { SideMenuItem } from './models';
import { BusinessRouterPaths, MasterDataRouterPaths } from '../../../Routes/RouterPaths';

export const RetailerPrimaryMenuItems: SideMenuItem[] = [
    {
        id: '0',
        name: 'dashboard',
        title: 'Dashboard',
        route: BusinessRouterPaths.dashboard,
        icon: SVG_ICONS.dashboard,
        requiredPermissions: [],
    },
    {
        id: '1',
        name: 'inventory',
        title: 'Inventory',
        route: BusinessRouterPaths.inventories,
        icon: SVG_ICONS.inventory,
        requiredPermissions: ['view_inventory'],
    },
    {
        id: '2',
        name: 'products',
        title: 'Products',
        route: BusinessRouterPaths.productList,
        icon: SVG_ICONS.product,
        requiredPermissions: ['view_product'],
    },
    {
        id: '9',
        name: 'invoices',
        title: 'Invoices',
        route: BusinessRouterPaths.invoices,
        icon: SVG_ICONS.invoice,
        requiredPermissions: ['view_invoice'],
    },
    {
        id: '3',
        name: 'stock-order',
        title: 'Stock Order',
        route: '/web/stock-order',
        icon: SVG_ICONS.stockOrder,
        subItems: [
            {
                id: '3.1',
                name: 'stock-order',
                title: 'Import Stock Order',
                route: BusinessRouterPaths.stockOrderImport,
                activeRoute: BusinessRouterPaths.stockOrderImport.substring(4),
                icon: SVG_ICONS.import,
                requiredPermissions: ['view_stockorder', 'add_stockorder', 'view_mastersupplier'],
            },
            {
                id: '3.2',
                name: 'stock-order',
                title: 'Stock Orders',
                route: BusinessRouterPaths.stockOrderList,
                activeRoute: BusinessRouterPaths.stockOrderList.substring(4),
                icon: SVG_ICONS.stockOrder,
                requiredPermissions: ['view_stockorder'],
            },
        ],
    },
    {
        id: '4',
        name: 'stock-receipts',
        title: 'Stock Receipts',
        route: BusinessRouterPaths.stockReceiptList,
        icon: SVG_ICONS.stockReceipt,
        requiredPermissions: ['view_stockreceipt'],
    },
    {
        id: '5',
        name: 'labeler',
        title: 'Labeler',
        route: '/web/labeler',
        icon: SVG_ICONS.labeler,
        subItems: [
            {
                id: '5.1',
                name: 'labeler',
                title: 'Templates',
                route: BusinessRouterPaths.labelerTemplates,
                activeRoute: BusinessRouterPaths.labelerTemplates.substring(4),
                icon: SVG_ICONS.template,
                requiredPermissions: ['view_labelertemplate'],
            },
        ],
    },
    {
        id: '6',
        name: 'reports',
        title: 'Reports',
        route: '/web/reports/',
        icon: SVG_ICONS.reports,
        subItems: [
            {
                id: '6.0',
                name: 'reports',
                title: 'Expected Stock By Week',
                route: BusinessRouterPaths.expectedStockByWeekReport,
                activeRoute: BusinessRouterPaths.expectedStockByWeekReport.substring(4),
                icon: SVG_ICONS.stockOrder,
                requiredPermissions: [
                    'view_expectedstockbyweekbycommoditygroup',
                    'view_expectedstockbyweekbysupplier',
                ],
            },

            {
                id: '6.1',
                name: 'reports',
                title: 'Products Without Barcode',
                route: BusinessRouterPaths.productsWithoutBarcodeReport,
                activeRoute: BusinessRouterPaths.productsWithoutBarcodeReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_product'],
            },
            {
                id: '6.2',
                name: 'reports',
                title: 'Products With Duplicate Name',
                route: BusinessRouterPaths.productsWithDuplicateNameReport,
                activeRoute: BusinessRouterPaths.productsWithDuplicateNameReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_product'],
            },
            {
                id: '6.4',
                name: 'reports',
                title: 'Products Not Mapped',
                route: BusinessRouterPaths.productsNotMappedReport,
                activeRoute: BusinessRouterPaths.productsNotMappedReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: [
                    'view_product',
                    'view_masterproduct',
                    'view_linkmasterbusinessproduct',
                ],
            },
            {
                id: '6.5',
                name: 'reports',
                title: 'Unknown Identifiers',
                route: BusinessRouterPaths.unknownIdentifierHistoryReport,
                activeRoute: BusinessRouterPaths.unknownIdentifierHistoryReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_unknownidentifierreport'],
            },
            {
                id: '6.6',
                name: 'storePrice',
                title: 'Store Price',
                route: BusinessRouterPaths.storePriceReport,
                activeRoute: BusinessRouterPaths.storePriceReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_productwithstoreprice'],
            },
            // {
            //     id: '6.7',
            //     name: 'ProductPriceDetailbySoldScreen',
            //     title: 'Product Price Detail By Last Sold',
            //     route: BusinessRouterPaths.productPriceDetailbySoldScreen,
            //     activeRoute: BusinessRouterPaths.productPriceDetailbySoldScreen.substring(4),
            //     icon: SVG_ICONS.product,
            //     requiredPermissions: ['view_productpricedetail'],
            // },
        ],
    },
    {
        id: '10',
        name: 'rms-reports',
        title: 'RMS Reports',
        route: '/web/rms/',
        icon: SVG_ICONS.reports,
        subItems: [
            {
                id: '10.1',
                name: 'rms-reports',
                title: 'HQ Item Issues',
                route: BusinessRouterPaths.rmsItemIssuesReport,
                activeRoute: BusinessRouterPaths.rmsItemIssuesReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_rmshqitemmismatch'],
            },
            {
                id: '10.2',
                name: 'rms-reports',
                title: 'Cost Price',
                route: BusinessRouterPaths.rmsCostPriceReport,
                activeRoute: BusinessRouterPaths.rmsCostPriceReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_rmscostpricesd3'],
            },
        ],
    },
    {
        id: '7',
        name: 'master-data',
        title: 'Master Data',
        route: '/web/master-data',
        icon: SVG_ICONS.masterData,
        subItems: [
            {
                id: '7.0',
                name: 'reports',
                title: 'Global Lookup',
                route: BusinessRouterPaths.productsGlobalLookup,
                activeRoute: BusinessRouterPaths.productsGlobalLookup.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['superuser'],
            },
            {
                id: '7.1',
                name: 'master-data',
                title: 'Products',
                route: MasterDataRouterPaths.masterProducts,
                activeRoute: MasterDataRouterPaths.masterProducts.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_masterproduct'],
            },
            {
                id: '7.2',
                name: 'master-data',
                title: 'Link Products',
                route: MasterDataRouterPaths.masterProductsLink,
                activeRoute: MasterDataRouterPaths.masterProductsLink.substring(4),
                icon: SVG_ICONS.linkPlus,
                requiredPermissions: ['view_masterproduct', 'view_linkmasterbusinessproduct'],
            },
            {
                id: '7.3',
                name: 'master-data',
                title: 'Suppliers',
                route: MasterDataRouterPaths.masterSuppliers,
                activeRoute: MasterDataRouterPaths.masterSuppliers.substring(4),
                icon: SVG_ICONS.suppliers,
                requiredPermissions: ['view_mastersupplier'],
            },
            {
                id: '7.4',
                name: 'master-data',
                title: 'Link Suppliers',
                route: MasterDataRouterPaths.masterBusinessSuppliersLink,
                activeRoute: MasterDataRouterPaths.masterBusinessSuppliersLink.substring(4),
                icon: SVG_ICONS.linkPlus,
                requiredPermissions: ['view_linkkoronasupplierbusiness'],
            },
            // {
            //     id: '7.41',
            //     name: 'master-data',
            //     title: 'Link Suppliers OLD',
            //     route: MasterDataRouterPaths.masterSuppliersLink,
            //     activeRoute: MasterDataRouterPaths.masterSuppliersLink.substring(4),
            //     icon: SVG_ICONS.linkPlus,
            //     requiredPermissions: ["view_mastersupplier", "view_linkmasterbusinesssupplier"]
            // },
            // {
            //     id: '7.5',
            //     name: 'master-data',
            //     title: 'Link Supplier Products',
            //     route: MasterDataRouterPaths.masterSupplierProductsLink,
            //     activeRoute: MasterDataRouterPaths.masterSupplierProductsLink.substring(4),
            //     icon: SVG_ICONS.linkPlus,
            //     requiredPermissions: ['view_masterproduct', 'view_mastersupplierproduct'],
            // },
            // {
            //     id: '7.6',
            //     name: 'master-data',
            //     title: 'Supplier Products',
            //     route: MasterDataRouterPaths.masterBusinessSupplierProducts,
            //     activeRoute: MasterDataRouterPaths.masterBusinessSupplierProducts.substring(4),
            //     icon: SVG_ICONS.linkPlus,
            //     requiredPermissions: ['view_masterproduct', 'view_linkmasterbusinessproduct'],
            // },
        ],
    },
    {
        id: '8',
        name: 'master-reports',
        title: 'Master Reports',
        route: '/web/master-reports/',
        icon: SVG_ICONS.reports,
        subItems: [
            {
                id: '8.1',
                name: 'master-reports',
                route: MasterDataRouterPaths.masterProductsWithoutBarcodeReport,
                activeRoute: MasterDataRouterPaths.masterProductsWithoutBarcodeReport.substring(4),
                title: 'Products Without Barcode',
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_masterproduct'],
            },
            {
                id: '8.2',
                name: 'master-reports',
                title: 'Products Without Quantity',
                route: MasterDataRouterPaths.masterProductsWithoutQtyValueReport,
                activeRoute: MasterDataRouterPaths.masterProductsWithoutQtyValueReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_masterproduct'],
            },
            {
                id: '8.3',
                name: 'master-reports',
                title: 'Products Without Unit Measure',
                route: MasterDataRouterPaths.masterProductsWithoutUnitMeasureReport,
                activeRoute: MasterDataRouterPaths.masterProductsWithoutUnitMeasureReport.substring(
                    4
                ),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_masterproduct'],
            },
            {
                id: '8.4',
                name: 'reports',
                title: 'Product Duplicates',
                route: MasterDataRouterPaths.masterProductDuplicates,
                activeRoute: MasterDataRouterPaths.masterProductDuplicates.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_masterproduct', 'view_masterproductduplicate'],
            },
            {
                id: '8.5',
                name: 'master-reports',
                title: 'Product Name Unit Mismatch',
                route: MasterDataRouterPaths.masterProductNameUnitMismatchReport,
                activeRoute: MasterDataRouterPaths.masterProductNameUnitMismatchReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_masterproductnameunitmismatch'],
            },
            {
                id: '8.6',
                name: 'master-reports',
                title: 'Produce Codes',
                route: MasterDataRouterPaths.produceCodesReport,
                activeRoute: MasterDataRouterPaths.produceCodesReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_masterproduct'],
            },
            {
                id: '8.7',
                name: 'master-reports',
                title: 'Supplier Products',
                route: MasterDataRouterPaths.supplierProductListReport,
                activeRoute: MasterDataRouterPaths.supplierProductListReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_masterproduct', 'view_supplierproductlistreport'],
            },
            {
                id: '8.8',
                name: 'master-reports',
                title: 'Retailer Code Not Linked Master Product Identifier',
                route: MasterDataRouterPaths.retailerCodeNotLinkedMasterProductIdentifierReport,
                activeRoute: MasterDataRouterPaths.retailerCodeNotLinkedMasterProductIdentifierReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_masterproduct', 'view_retailtercodenotlinkedmasterproductidentifierreport'],
            },
            {
                id: '8.9',
                name: 'master-reports',
                title: 'Master Identifier Duplicate',
                route: MasterDataRouterPaths.masterIdentifierDuplicates,
                activeRoute: MasterDataRouterPaths.masterIdentifierDuplicates.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['superuser'],
            },
        ],
    },
];

export const RetailerSecondaryMenuItems: SideMenuItem[] = [
    // {
    //     id: '-1',
    //     name: 'async-tasks',
    //     title: 'Async Tasks',
    //     route: BusinessRouterPaths.asyncTasks,
    //     icon: SVG_ICONS.devices,
    //     requiredPermissions: [],
    // },
    {
        id: '0',
        name: 'devices',
        title: 'Devices',
        route: BusinessRouterPaths.deviceManager,
        icon: SVG_ICONS.devices,
        requiredPermissions: ['view_device'],
    },
    {
        id: '1',
        name: 'users',
        title: 'Users',
        route: BusinessRouterPaths.userManager,
        icon: SVG_ICONS.users,
        requiredPermissions: ['view_user'],
    },
    // {
    //     id: '2',
    //     name: 'account',
    //     title: 'Account',
    //     route: BusinessRouterPaths.account,
    //     icon: SVG_ICONS.account,
    // },
    // {
    //     id: '3',
    //     name: 'settings',
    //     title: 'Settings',
    //     route: BusinessRouterPaths.settings,
    //     icon: SVG_ICONS.settings,
    // },
];
