import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Button, Paper, Tooltip, TextField } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import IconButton from '@material-ui/core/IconButton';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import {
  StockOrderItemDatum, StockOrderRequest,
  initialStockOrderSummary, initSpecifiHeaders,
  GridElement, stockOrderHeaders, MatchInvoicePayload
} from '../../models';
import { getMatchedInvoiceItems, createStockOrderAPI, clearStockOrderProducts } from '../../data/actions';
import { MasterSupplierLinkedRecord, BusinessSupplierLinkRecord } from '../../../master-data/models';
import { Product } from '../../../products/models';
import { Theme, useTheme, withStyles } from '@material-ui/core/styles';
import Papa from 'papaparse';

import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG, NO_PERMISSIONS_ERROR_MSG } from '../../../shared/lib/Localization';
import { HtmlTooltip, useStyles } from './styles';
import { FileImport, VerifyImportData, MatchInvoiceProduct } from '../../components';
import { validateHeaders, getInvoiceItemsToMatch, getMatchedHeaders, getMatchedItemSummary, getStockOrderRequestData } from './helper';
import { PageToolbar } from '../../../page/components';
import { PaginatedResponse } from '../../../shared/models';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import useWindowDimensions from '../../../shared/hooks/WindowDimensions';
import { getLinkedKoronaBusinessSuppliers } from '../../../master-data/api';
import { uploadInvoicePDf } from '../../api';
import moment from 'moment';
import { setSelectedLocations } from '../../../../store/actions/userLocation';

const getSteps = () => {
  return ['Import Invoice Data', 'Verify Imported Data', 'Map Stock Order Items', 'Finish'];
};

const NoError = {
  hasError: false,
  message: ''
};

const NoErrorInHeaders = {
  hasError: false,
  message: '',
  message2: ''
};

const StockOrderImportScreen = (props: any) => {
  const classes = useStyles({});
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [uiLoading, setUILoading] = useState(false);
  const [linkedSuppliers, setLinkedSuppliers] = useState<BusinessSupplierLinkRecord[]>([]);
  const [parsedPdfData, setParsedPdfData] = useState<any>(null);
  const [selectedFile, setSelectedFile] = useState(null as any);
  const [selectedFileError, setSelectedFileError] = useState(NoError);
  const [selectedSupplier, setSelectedSupplier] = useState<BusinessSupplierLinkRecord | null | undefined>(null);
  const [supplierError, setSupplierError] = useState(NoError);
  const [parserError, setParserError] = useState(false);
  const [definedHeaders, setDefinedHeaders] = useState(stockOrderHeaders);
  const [gridData, setGridData] = useState<GridElement[][]>([]);
  const [mappedHeaders, setMappedHeaders] = useState(new Map<number, string>());
  const [headersError, setHeadersError] = useState(NoErrorInHeaders);
  const [checkRowsInput, setCheckRowsInput] = useState('');
  const [checkColumnsInput, setCheckColumnsInput] = useState('');
  const [stockOrderSummary, setStockOrderSummary] = useState(initialStockOrderSummary);
  const [specificHeaders, setSpecificHeadersMap] = useState(initSpecifiHeaders);
  const [caseQtyDataError, setCaseQtyDataError] = useState(NoError);
  const [isCaseQtyMapped, setIsCaseQtyMapped] = useState(false);
  const [stepOneSuccess, setStepOneSuccess] = useState(false);
  const [stockOrderFormValues, setStockOrderFormValues] = useState({ description: '' });
  const [isPDFUploaded, setPDFUploaded] = useState(false);
  const [stockOrderProducts, setStockOrderProducts] = useState<StockOrderItemDatum[]>([]);
  const [shouldUpdateRowColSelection, triggerRowColSelection] = useState(false);
  const [selectedOrgUnit, setSelectedOrgUnit] = useState(null);

  const { width, height } = useWindowDimensions();
  // console.log('height', height);
  // console.log('width', width);
  const theme = useTheme();

  useEffect(() => {
    if (props.userLocalData && props.userLocalData.selectedBusiness) {
      const sb = props.userLocalData.selectedBusiness;
      getLinkedKoronaBusinessSuppliers(sb.business_id, 1, 50, null)
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then(parseJSON)
        .then((data: PaginatedResponse<BusinessSupplierLinkRecord>) => {
          setLinkedSuppliers(data.results);
        })
        .catch((error: any) => {
          setLinkedSuppliers([]);
        });
    }
  }, [props.userLocalData.selectedBusiness]);

  useEffect(() => {
    if (selectedSupplier) {
      setSupplierError(NoError);
    }
  }, [selectedSupplier]);

  useEffect(() => {
    if (selectedFile) {
      setSelectedFileError(NoError);
    }
  }, [selectedSupplier]);

  useEffect(() => {
    if (stepOneSuccess) {
      setActiveStep(1);
    }
  }, [stepOneSuccess]);

  useEffect(() => {
    if (activeStep === steps.length) {
      handleReset();
    }
  }, [activeStep]);

  useEffect(() => {
    // console.log(props.userLocalData);
    const { selectedLocations } = props.userLocalData;
    // console.log('selected locations', selectedLocations);
    if (activeStep === 0 && selectedLocations.length === 1) {
      setSelectedOrgUnit(selectedLocations[0]);
    } else if (activeStep === 0 && selectedLocations.length !== 1) {
      setSelectedOrgUnit(null);
    }
  }, [props.userLocalData.selectedLocations]);

  const parseCSV = () => {
    if (selectedFile) {
      const CSVParserConfig = {
        complete: function (results: any, file: any) {
          // console.log('results', results)
          // console.log("Finished:", results.data);
          if (results.data.length > 0) {
            // console.log('papa parser', results.data);
            mapCSVDataToGrid(results.data);
            mapHeadersFromConfig();
            setStepOneSuccess(true);
          }
          setParserError(false);
        },
        dynamicTyping: false,
        error: function (error: any, file: any) {
          console.log("Error occurred while parsing csv!");
          setParserError(true);
        },
        skipEmptyLines: true
      };
      Papa.parse(selectedFile, CSVParserConfig);
    }
  };

  const mapHeadersFromConfig = () => {
    let config: any = null;
    // console.log("selectedSupplier", selectedSupplier);
    if (selectedSupplier) {
      const { parser_settings } = selectedSupplier.korona_supplier_business;
      if (parser_settings && parser_settings.length > 0) {
        const setting = parser_settings[0];
        if (setting.stock_order_header_config) {
          try {
            config = JSON.parse(setting.stock_order_header_config);
          }
          catch (e) {
            // error parsing header config
            config = null;
          }
        }
      }
      if (config) {
        if (config.columnsToIgnore) {
          setCheckColumnsInput(config.columnsToIgnore);
        }
        if (config.rowsToIgnore) {
          setCheckRowsInput(config.rowsToIgnore);
        }
        if (config.columns) {
          const map = new Map(mappedHeaders);
          Object.keys(config.columns).forEach(k => {
            const header = definedHeaders.find(h => h.toLowerCase() === config.columns[k].toLowerCase());
            if (header) {
              map.set(Number(k), header);
            }
          });
          setMappedHeaders(map);
        }
        triggerRowColSelection(!shouldUpdateRowColSelection);
      }
    }
  };

  const validateCaseQuantities = () => {
    const caseQtyBlankError = {
      hasError: true,
      message: 'Supplier Case Quantity cannot be blank.'
    };
    const { stockOrderProductList } = props;
    if (stockOrderProductList &&
      stockOrderProductList.data &&
      stockOrderProductList.data.length > 0) {
      const list = stockOrderProductList.data.slice();
      if (isCaseQtyMapped) {
        for (let i = 0; i < list.length; i++) {
          if (!list[i].master_supplier_container_qty) {
            setCaseQtyDataError(caseQtyBlankError);
            return false;
          }
          if (Number(list[i].caseQuantity) !== Number(list[i].master_supplier_container_qty)) {
            setCaseQtyDataError(
              {
                hasError: true,
                message: 'Please resolve the difference between Case Quantity and Supplier Case Quantity Values.'
              });
            return false;
          }
        }
      } else {
        for (let i = 0; i < list.length; i++) {
          if (!list[i].master_supplier_container_qty) {
            setCaseQtyDataError(caseQtyBlankError);
            return false;
          }
        }
      }
      return true;
    }
    return false;
  };

  const handleNext = () => {
    if (activeStep === 0) {
      if (stepOneSuccess) {
        setActiveStep(1);
        return;
      }
    } else if (activeStep === 1) {
      const headersMappedResult = validateHeaders(mappedHeaders);
      if (headersMappedResult.hasError) {
        setHeadersError(headersMappedResult);
        return;
      } else {
        setHeadersError(NoErrorInHeaders);
      }
      if (selectedSupplier && !headersMappedResult.hasError) {
        setActiveStep(prevActiveStep => {
          if (prevActiveStep + 1 === 2) {
            getStockOrderProducts();
          }
          return prevActiveStep + 1;
        });
      }
    } else if (activeStep === 2) {
      const areCaseQuantitiesValid = validateCaseQuantities();
      if (areCaseQuantitiesValid) {
        createStockOrder();
      }
    } else {
      setActiveStep(prevActiveStep => {
        return prevActiveStep + 1;
      });
    }

  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    const { selectedLocations } = props.userLocalData;
    if (selectedLocations && selectedLocations.length === 1) {
      setSelectedOrgUnit(selectedLocations[0]);
    } else {
      setSelectedOrgUnit(null);
    }
    setSelectedFile(null);
    setGridData([]);
    setSelectedSupplier(null);
    setMappedHeaders(new Map<number, string>());
    setStockOrderSummary(initialStockOrderSummary);
    setSpecificHeadersMap(initSpecifiHeaders);
    props.clearStockOrderProductList();
    setStockOrderFormValues({ description: '' });
    setStockOrderProducts([]);
    setStepOneSuccess(false);
    setActiveStep(0);
    setPDFUploaded(false);
  };

  const getStockOrderProducts = () => {
    // setSpecificHeadersMap(initSpecifiHeaders);
    setCaseQtyDataError(NoError);
    props.clearStockOrderProductList();
    const matchedHeaders = getMatchedHeaders(gridData, mappedHeaders);
    setSpecificHeadersMap(matchedHeaders);
    const sb = props.userLocalData.selectedBusiness;
    const businessId = sb.business_id;
    const requestData = getInvoiceItemsToMatch(gridData, mappedHeaders);

    if (businessId && selectedSupplier && requestData.length > 0) {
      const payload = {
        stockOrderItems: requestData,
        business_id: businessId,
        korona_supplier_business_id: selectedSupplier.korona_supplier_business.id
      };
      props.getStockOrderProductList(payload);
    } else {
      props.clearStockOrderProductList();
    }
  };

  const handleStockOrderFormChange = (event: any, name: string) => {
    setStockOrderFormValues({ ...stockOrderFormValues, [name]: event.target.value });
  };

  useEffect(() => {
    const { stockOrderProductList } = props;
    if (stockOrderProductList && stockOrderProductList.error) {
      let msg = 'Failed to Match Invoice Items!';
      props.showToast({ message: msg, options: ToastError });
    }
    if (stockOrderProductList && stockOrderProductList.data) {
      if (stockOrderProductList.data.length > 0) {
        const matchedItemSummary = getMatchedItemSummary(stockOrderProductList, stockOrderSummary);
        setStockOrderProducts(matchedItemSummary.items);
        setStockOrderSummary(matchedItemSummary.summary);
      }
    }
  }, [props.stockOrderProductList]);

  useEffect(() => {
    if (props.createStockOrderData.success) {
      props.showToast({ message: 'Stock Order created successfully!', options: ToastSuccess });
    }
    if (props.createStockOrderData.error) {
      let msg = 'Failed to create Stock Order!';
      const { errorData } = props.createStockOrderData;
      if (errorData) {
        if ((errorData.status === 403 || errorData.status === 401)) {
          msg = NO_PERMISSIONS_ERROR_MSG;
        } else if (errorData.status === 500) {
          msg = ERROR_500;
        }
      }
      props.showToast({ message: msg, options: ToastError });
    }
  }, [props.createStockOrderData]);

  const createStockOrder = () => {
    if (selectedOrgUnit) {
      // console.log('selectedOrgUnit', selectedOrgUnit);
      const description = stockOrderFormValues.description;
      // console.log(stockOrderProducts);
      const requestData = getStockOrderRequestData(selectedSupplier, selectedOrgUnit, description, stockOrderProducts);
      // console.log(requestData);
      if (!requestData.hasError) {
        props.createStockOrder(requestData.payload);
      }
    }

  };

  const handleLinkedSupplierChange = (event: any) => {
    const linkedSupplier = linkedSuppliers.find(s => s.id === event.target.value);
    setSelectedSupplier(linkedSupplier);
  };

  const onFileSelectChangeHandler = (event: any) => {
    if (event.target) {
      if (event.target.files && event.target.files[0]) {
        setSelectedFile(event.target.files[0]);
      }
    }
  };

  const mapCSVDataToGrid = (data: any[][]) => {
    let result: GridElement[][] = [];
    for (let i = -1; i < data.length; i++) {
      let row = null;
      if (i === -1) {
        row = data[0];
      } else {
        row = data[i];
      }
      let newRow: GridElement[] = [];
      for (let j = -1; j < row.length; j++) {
        const gridElement = { value: '', isSelected: false, isIgnored: false, type: 'data' } as GridElement;
        if (i === -1 && j === -1) { // row=0, col=0
          gridElement.type = 'data';
          // gridElement.value = 'Headers'
        } else if (i === -1) { // row=0
          gridElement.type = 'header';
        }
        else if (j === -1) { // col=0
          gridElement.type = 'checkbox';
        } else {
          gridElement.type = 'data';
          if (!row[j]) {
            gridElement.value = '';
          } else {
            gridElement.value = row[j];
          }
        }
        newRow.push(gridElement);
      }
      result.push(newRow);
    }
    setGridData(result);
  };

  const uploadPDF = () => {
    if (!selectedSupplier) {
      props.showToast({ message: "Please Select A Supplier", options: ToastError });
      return;
    }
    if (selectedFile && selectedSupplier) {
      if (!isPDFUploaded) {
        const sb = props.userLocalData.selectedBusiness;
        const data = new FormData();
        data.append('file', selectedFile);
        data.append('korona_supplier_business_id', String(selectedSupplier.korona_supplier_business.id));
        data.append('business_id', String(sb.business_id));
        setUILoading(true);
        uploadInvoicePDf(data)
          .catch(handleError) // handle network issues
          .then(checkStatus)
          .then(parseJSON)
          .then(res => {
            props.showToast({ message: 'PDF Uploaded Successfully!', options: ToastSuccess });
            if (res['parsed_data'].length === 0) {
              const msg = 'Oops! An Error Occured. Data could not be parsed from file.';
              props.showToast({ message: msg, options: ToastError });
            }
            setParsedPdfData(res['parsed_data']);
            mapCSVDataToGrid(res['parsed_data']);
            if (res['headers']) {
              if (res['headers']['description']) {
                setStockOrderFormValues({ description: res['headers']['description'] });
              }
            }
            mapHeadersFromConfig();
            setUILoading(false);
            setPDFUploaded(true);
            setStepOneSuccess(true);
          })
          .catch((error: any) => {
            const msg = 'Oops! An Error Occured.';
            props.showToast({ message: msg, options: ToastError });
            // if (error && error.data) {
            //   if (error.data.error) {
            //     props.showToast({ message: error.data.error, options: ToastError });
            //   }
            // }
            setUILoading(false);
          });
      }
    }
  };

  const exportToCSV = () => {
    if (parsedPdfData && selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop();
      if (fileExtension === 'pdf') {
        let csvContent = "data:text/csv;charset=utf-8,"
          + parsedPdfData.map((e: any) => e.join("\t")).join("\n");
        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        const filename = selectedFile.name ? selectedFile.name : `invoice_${moment().format('YYYY_MM_DD')}`;
        link.setAttribute("download", `${filename}.tsv`);
        document.body.appendChild(link); // Required for FF
        link.click();
      }
    }
  };

  const renderFileName = () => {
    if (selectedFile && selectedFile.name) {
      const ext = selectedFile.name.split('.').pop();
      const filename = `File: ${selectedFile.name}`;
      if (ext === "pdf") {
        return (
          <>
            <span className={classes.secondaryPageTitle}>
              &nbsp;&nbsp;&ndash;&nbsp;&nbsp;{filename}
            </span>
            {
              parsedPdfData &&
              <Tooltip title="Export TSV" onClick={exportToCSV}
                style={{ alignSelf: 'center', marginLeft: 8 }}>
                <IconButton aria-label="export-tsv">
                  <SaveAltIcon />
                </IconButton>
              </Tooltip>
            }
          </>);
      }
      return filename;
    }
    return null;
  };

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <FileImport
            history={props.history}
            uploadPDF={uploadPDF}
            onFileSelectChangeHandler={onFileSelectChangeHandler}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            selectedFileError={selectedFileError}
            selectedOrgUnit={selectedOrgUnit}
            selectedSupplier={selectedSupplier}
            setSelectedSupplier={setSelectedSupplier}
            supplierError={supplierError}
            uiLoading={uiLoading}
            setUILoading={setUILoading}
            handleLinkedSupplierChange={handleLinkedSupplierChange}
            linkedSuppliers={linkedSuppliers}
            mapCSVDataToGrid={mapCSVDataToGrid}
            parseCSV={parseCSV}
            stepOneSuccess={stepOneSuccess}
          />
        );
      case 1:
        return (
          <VerifyImportData
            parsedPdfData={parsedPdfData}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            selectedFileError={selectedFileError}
            mapCSVDataToGrid={mapCSVDataToGrid}
            mapHeadersFromConfig={mapHeadersFromConfig}
            gridData={gridData}
            setGridData={setGridData}
            parseCSV={parseCSV}
            selectedSupplier={selectedSupplier}
            setSelectedSupplier={setSelectedSupplier}
            supplierError={supplierError}
            mappedHeaders={mappedHeaders}
            setMappedHeaders={setMappedHeaders}
            headersError={headersError}
            uiLoading={uiLoading}
            setUILoading={setUILoading}
            windowWidth={width}
            windowHeight={height}
            checkColumnsInput={checkColumnsInput}
            setCheckColumnsInput={setCheckColumnsInput}
            checkRowsInput={checkRowsInput}
            setCheckRowsInput={setCheckRowsInput}
            definedHeaders={definedHeaders}
            shouldUpdateRowColSelection={shouldUpdateRowColSelection}
          />
        );
      case 2:
        return (
          <MatchInvoiceProduct
            selectedSupplier={selectedSupplier}
            stockOrderSummary={stockOrderSummary}
            getStockOrderProducts={getStockOrderProducts}
            specificHeaders={specificHeaders}
            caseQtyDataError={caseQtyDataError}
            isCaseQtyMapped={isCaseQtyMapped}
            uiLoading={uiLoading}
            setUILoading={setUILoading}
            windowWidth={width}
            windowHeight={height}
          />
        );
      default:
        return 'Uknown stepIndex';
    }
  };

  return (
    <div style={{ maxWidth: '100%', marginTop: -16 }}>
      <PageToolbar
        title='Import Stock Order'
        history={props.history}
        secondaryTitle={renderFileName()}
        actions={
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            {
              activeStep === 0 &&
              <div style={{ alignSelf: 'center', marginTop: 8 }}>
                <Button variant="contained" onClick={handleReset}>Reset</Button>
              </div>
            }
            {
              activeStep > 0 &&
              <>
                <div style={{ alignSelf: 'center', marginRight: 8, marginTop: 8 }}>
                  <Button variant="contained" onClick={handleReset}>Reset</Button>
                </div>
                <div style={{ display: 'flex', minWidth: 200, maxWidth: 290, width: '100%' }}>
                  <TextField id="supplier" label="Supplier"
                    name="supplier"
                    disabled={true}
                    value={selectedSupplier ? selectedSupplier.korona_supplier_business.name : ''}
                  />
                </div>
              </>
            }
          </div>
        } />
      <Paper elevation={activeStep === 2 ? 0 : 4}>
        <div className={classes.content}>
          {
            activeStep !== 0 &&
            <Button
              onClick={handleBack}
              className={classes.backButton}>Back</Button>
          }
          <Stepper
            style={{ padding: 0, paddingTop: 24, flex: 1 }}
            activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {
            activeStep === 2 &&
            <TextField
              id="description-textarea"
              label="Description"
              placeholder="Description"
              style={{ marginRight: 16 }}
              multiline
              margin="normal"
              value={stockOrderFormValues.description}
              onChange={(event) => handleStockOrderFormChange(event, 'description')}
            />
          }
          {selectedFile &&
            <Button variant="contained" color="primary" onClick={handleNext}>
              {activeStep === steps.length - 2 ? 'Create Stock Order' : 'Next'}
            </Button>
          }
        </div>
        <div>
          <div style={{ marginTop: 0 }}>{getStepContent(activeStep)}</div>
        </div>
      </Paper>
    </div>
  );

};

const mapStateToProps = (state: any) => {
  return {
    stockOrderProductList: state.stockOrderModuleData.stockOrderProductList,
    createStockOrderData: state.stockOrderModuleData.createStockOrderData,
    userLocalData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getStockOrderProductList: (payload: MatchInvoicePayload) => dispatch(getMatchedInvoiceItems(payload)),
    clearStockOrderProductList: () => dispatch(clearStockOrderProducts()),
    createStockOrder: (data: StockOrderRequest) => dispatch(createStockOrderAPI(data)),
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StockOrderImportScreen);