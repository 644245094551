import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Theme, createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';

import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { Product, CurrentOrderSummaryItem, PastOrderSummaryItem } from '../../models';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '../../../shared/components';
import { Location, LocationBusiness } from '../../../shared/models';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { handleError, checkStatus, parseJSON } from '../../../shared/api/core';
import { ErrorStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_MSG, ERROR_500, NO_PERMISSIONS_ERROR_MSG } from '../../../shared/lib/Localization';

import { BusinessRouterPaths } from '../../../page/Routes/RouterPaths';
import { PageToolbar } from '../../../page/components';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { getProduct, getCurrentOrderSummary, getPastOrderSummary } from '../../api/Api';
import { setPageLoading } from '../../../../store/actions';
import _groupBy from 'lodash/groupBy';
import { renderCard } from '../../../dashboard/components';
import { renderCardItem } from '../../../dashboard/components/Common';
import { setSelectedBusiness } from '../../../../store/actions/userLocation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
      // marginBottom: theme.spacing(2),
    },
    title: {
      color: theme.palette.primary.main,
      alignSelf: 'center'
    },
    row: {
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: 6,
      width: '100%',
      fontSize: '0.875rem'
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    card: {
      padding: theme.spacing(1),
    },
    panelRow: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '16px'
    },
    panelCol: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    fullWidth: {
      width: '100%'
    },
    flex1: {
      flex: 1
    },
    flex2: {
      flex: 2
    },
    panelRowHeader: {
      backgroundColor: '#f5f5f5',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '16px'
    },
    panelHeaderText: {
      fontSize: '0.95rem'
    },
    noDataRow: {
      width: '100%',
      padding: 16
    },
    tag: {
      marginRight: 8,
      marginBottom: 8
    }
  }),
);

const ProductDetailScreen = (props: any) => {
  console.log(props);
  const classes = useStyles({});
  const [productData, setProductData] = useState({ ...props.location.state } as Product);
  const [currentOrderSummary, setCurrentOrderSummary] = useState({
    current_orders: {},
    current_orders_count: 0,
  });
  const [pastOrderSummary, setPastOrderSummary] = useState({
    past_orders: {},
    past_orders_count: 0,
  });
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  const [expanded, setExpanded] = React.useState({
    tags: false,
    prices: false,
    article_codes: false,
    product_stocks: false,
    suppliers: false,
    article_texts: false,
    current_orders: false,
    past_orders: false
  });

  useEffect(() => {
    props.setPageLoader(true);

    const { uuid } = props.location.state;
    if (uuid) {
      getProduct(uuid)
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then(parseJSON)
        .then((data: Product) => {
          props.setPageLoader(false);
          getOnOrderData(data.business, data.uuid);
          getPastOrderData(data.business, data.uuid);
          const { article_codes, article_texts } = data;
          const keys = ['article_codes', 'article_texts', 'prices', 'product_stocks', 'suppliers', 'tags'];
          const expandedTiles = { ...expanded };
          keys.forEach(k => {
            expandedTiles[k] = false;
            if (data[k] && data[k].length > 0) {
              expandedTiles[k] = true;
            }
          });
          if (isErrorStatusVisible) {
            setErrorStatusVisible(false);
          }
          setProductData(data);
          setExpanded(expandedTiles);
        })
        .catch((error: any) => {
          props.setPageLoader(false);
        });
    }
  }, []);

  const getOnOrderData = (businessId, productId) => {
    getCurrentOrderSummary(businessId, productId)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: CurrentOrderSummaryItem[]) => {
        const groupedCurrentOrders = _groupBy(data, (i) => i.supplier.uuid);
        console.log('groupedCurrentOrders', groupedCurrentOrders);
        const summary = {
          current_orders: groupedCurrentOrders,
          current_orders_count: data.length,
        };
        setCurrentOrderSummary(summary);
        if (data.length > 0) {
          const expandedTiles = { ...expanded };
          expandedTiles.current_orders = true;
          setExpanded(expandedTiles);
        }
      })
      .catch((error: any) => {

      });
  };

  const getPastOrderData = (businessId, productId) => {
    getPastOrderSummary(businessId, productId)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: PastOrderSummaryItem[]) => {
        const groupedPastOrders = _groupBy(data, (i) => i.supplier.uuid);
        console.log('groupedPastOrders', groupedPastOrders);
        const summary = {
          past_orders: groupedPastOrders,
          past_orders_count: data.length
        };
        setPastOrderSummary(summary);
        if (data.length > 0) {
          const expandedTiles = { ...expanded };
          expandedTiles.past_orders = true;
          setExpanded(expandedTiles);
        }
      })
      .catch((error: any) => {

      });
  };

  const getLocationName = (warehouseId) => {
    if(warehouseId){
      const { selectedLocations } = props.userData;
      if (selectedLocations) {
        if (Object.keys(selectedLocations).length > 0) {
          const locations: Location[] = Object.values(selectedLocations);
          const location = locations.find((l: Location) => l.korona_org_unit === warehouseId);
          console.log(location);
          if (location) {
            return location.name;
          }
        }
      }
   } 
    return '';
  };

  const renderPrice =(price) =>{
    const location = getLocationName(price.organizationalUnit);
    if(price.validFrom || price.articleCode || location){
      return(
        <div key={String(price.id)}>
          <Divider />
          <div className={classes.panelRow}>
            {
              price.validFrom &&
              <Typography style={{ flex: 2 }}>
                {renderDate(price.validFrom)}
              </Typography>
            }
            {
              !price.validFrom && price.articleCode &&
              <Typography style={{ flex: 2 }}>
                {price.articleCode}
              </Typography>
            }
            { location && 
              <Typography style={{ flex: 2 }}>
                {location}
              </Typography>
            }
            <Typography style={{ flex: 2 }}>{price.priceList ? price.priceList.name : ''}</Typography>
            
            <Typography style={{ flex: 1 }} align='right'>{price.value}</Typography>
            
          </div>
        </div>)
    } 
      return null;
  };

  
  const handlePanelExpansion = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    const config = { ...expanded };
    config[panel] = !config[panel];
    setExpanded(config);
  };

  const renderDate = (date) => {
    if (date) {
      return moment(date).format('ll');
    }
    return '----';
  };

  const getArticleTextType = (type) => {
    switch (type) {
      case 'ARTICLETEXT_ORDER_TEXT': return 'Order Text';
      case 'ARTICLETEXT_RECEIPT_TEXT': return 'Receipt Text';
      case 'ARTICLETEXT_LABEL_TEXT': return 'Label Text';
      default: return type;
    }
  };

  const navigateBack = () => {
    props.history.push(BusinessRouterPaths.productList);
  };

  return (
    <Container maxWidth="lg">
      <Paper elevation={0} style={{ padding: 24 }}>
        <PageToolbar
          title={'Product Detail'}
          showBackButton={true}
          history={props.history} />
        <ErrorStatusBar isVisible={isErrorStatusVisible} />
        <Grid container spacing={2} style={{ paddingTop: 8 }} direction="row">
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Card className={classes.card} variant="outlined">
              <CardContent>
                <Grid container className={classes.row}>
                  <TextField
                    id="number"
                    className={classes.textField}
                    label="Number"
                    margin="dense"
                    variant="outlined"
                    value={productData ? productData.number ? productData.number : '' : ''}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid container className={classes.row}>
                  <TextField
                    id="name"
                    className={classes.textField}
                    margin="dense"
                    label="Name"
                    variant="outlined"
                    value={productData ? productData.name ? productData.name : '' : ''}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid container className={classes.row}>
                  <TextField
                    id="commodityGroup"
                    className={classes.textField}
                    margin="dense"
                    variant="outlined"
                    label="Commodity Group"
                    value={productData.commodityGroup ?
                      productData.commodityGroup.name : ''}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid container className={classes.row}>
                  <TextField
                    id="assortment"
                    className={classes.textField}
                    margin="dense"
                    label="Assortment"
                    variant="outlined"
                    value={productData.assortment ?
                      productData.assortment.name : ''}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid container className={classes.row}>
                  <TextField
                    id="sector"
                    className={classes.textField}
                    margin="dense"
                    label="Sector"
                    variant="outlined"
                    value={productData.sector ?
                      productData.sector.name : ''}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Card className={classes.card} variant="outlined">
              <CardContent>
                <Grid container className={classes.row}>
                  <Grid item>
                    <Typography variant='body1'
                      style={{
                        paddingTop: 4,
                        paddingBottom: 4,
                        color: GlobalStyles.primaryColor
                      }}>Additional Information</Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container className={classes.row}>
                  <List className={classes.fullWidth}>
                    <ListItem role={undefined} dense>
                      <ListItemText id={'1'}
                        primary={'Is Discountable?'} />
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge="end"
                          disabled
                          checked={productData.discountable ? productData.discountable : false}
                          inputProps={{ 'aria-labelledby': '1' }}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem role={undefined} dense>
                      <ListItemText id={'2'}
                        primary={'Track Inventory'} />
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge="end"
                          disabled
                          checked={productData.trackInventory ? productData.trackInventory : false}
                          inputProps={{ 'aria-labelledby': '2' }}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem role={undefined} dense>
                      <ListItemText id={'3'}
                        primary={'Deactivate'} />
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge="end"
                          disabled
                          checked={productData.deactivated ? productData.deactivated : false}
                          inputProps={{ 'aria-labelledby': '3' }}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <ExpansionPanel
              expanded={expanded.tags}
              onChange={handlePanelExpansion('tags')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>Tags</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div style={{ display: 'flex', padding: 16, flexWrap: 'wrap' }}>
                  {
                    productData.tags && productData.tags.length > 0 &&
                    productData.tags.map(t => <Chip label={t.name} key={t.uuid} className={classes.tag} />)
                  }
                </div>
                {
                  productData.tags &&
                  productData.tags.length === 0 &&
                  <div className={classes.noDataRow}>
                    <Typography align='center'>No Tags</Typography>
                  </div>
                }
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <ExpansionPanel
              expanded={expanded.prices}
              onChange={handlePanelExpansion('prices')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>Prices</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className={classes.panelCol}>
                  <div className={classes.panelRowHeader}>
                    <Typography className={classes.panelHeaderText}
                      style={{ flex: 2 }}>Valid/Code</Typography>
                    <Typography className={classes.panelHeaderText}
                      style={{ flex: 2 }}>Price Group</Typography>
                    <Typography className={classes.panelHeaderText}
                      style={{ flex: 1 }} align='right'>Value</Typography>
                  </div>
                  <div className={classes.panelCol}>
                    {
                      productData.prices && productData.prices.length > 0 ?
                        productData.prices.map( renderPrice )              
                         : <div className={classes.noDataRow}>
                          <Typography align='center'>No Prices</Typography>
                        </div>
                    }
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <ExpansionPanel
              expanded={expanded.article_codes}
              onChange={handlePanelExpansion('article_codes')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>Product Codes</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className={classes.panelCol}>
                  <div className={classes.panelRowHeader}>
                    <Typography className={classes.panelHeaderText}>Code</Typography>
                    <Typography className={classes.panelHeaderText}
                      align='right'>Quantity</Typography>
                  </div>
                  <div className={classes.panelCol}>
                    {
                      productData.article_codes && productData.article_codes.length > 0 ?
                        productData.article_codes.map(pCode =>
                          <div key={String(pCode.id)}>
                            <Divider /> 
                            <div className={classes.panelRow}>
                              <Typography>{pCode.code}</Typography>
                              <Typography align='right'>{pCode.quantity}</Typography>
                            </div>
                          </div>
                        ) : <div className={classes.noDataRow}>
                          <Typography align='center'>No Product Codes</Typography>
                        </div>
                    }
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <ExpansionPanel
              expanded={expanded.product_stocks}
              onChange={handlePanelExpansion('product_stocks')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>Product Stocks</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className={classes.panelCol}>
                  <div className={classes.panelRowHeader}>
                    <Typography className={classes.panelHeaderText}>Location</Typography>
                    <Typography className={classes.panelHeaderText}
                      align='right'>Qty</Typography>
                  </div>
                  <div className={classes.panelCol}>
                    {
                      productData.product_stocks && productData.product_stocks.length > 0 ?
                        productData.product_stocks.map(ps => {
                          const location = getLocationName(ps.warehouse);
                          if (location) {
                            return (<div key={ps.uuid}>
                              <Divider />
                              <div className={classes.panelRow}>
                                <Typography>{location}</Typography>
                                <Typography align='right'>{ps.goods}</Typography>
                              </div>
                            </div>
                            );
                          }
                        }) : <div className={classes.noDataRow}>
                          <Typography align='center'>No Product Stocks</Typography>
                        </div>
                    }
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ExpansionPanel
              expanded={expanded.suppliers}
              onChange={handlePanelExpansion('suppliers')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>Suppliers</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className={classes.panelCol}>
                  <div className={classes.panelRowHeader}>
                    <Typography className={classes.panelHeaderText}
                      style={{ flex: 2 }}>Supplier</Typography>
                    <Typography className={classes.panelHeaderText}
                      style={{ flex: 1 }}>Order Code</Typography>
                    <Typography className={classes.panelHeaderText}
                      style={{ flex: 2 }}>Description</Typography>
                    <Typography className={classes.panelHeaderText}
                      style={{ flex: 1 }}>Container Amount</Typography>
                    <Typography className={classes.panelHeaderText}
                      style={{ flex: 1 }}>Item Price</Typography>
                    <Typography className={classes.panelHeaderText}
                      style={{ flex: 1 }}>Container Price</Typography>
                  </div>
                  <div className={classes.panelCol}>
                    {
                      productData.suppliers && productData.suppliers.length > 0 ?
                        productData.suppliers.map(supplierData =>
                          <div key={String(supplierData.supplier.uuid)}>
                            <Divider />
                            <div className={classes.panelRow}>
                              <Typography style={{ flex: 2 }}>{supplierData.supplier.name}</Typography>
                              <Typography style={{ flex: 1 }}>{supplierData.orderNumber}</Typography>
                              <Typography style={{ flex: 2 }}>{supplierData.boxDescription}</Typography>
                              <Typography style={{ flex: 1 }}>{supplierData.boxSize}</Typography>
                              <Typography style={{ flex: 1 }}>{supplierData.price}</Typography>
                              <Typography style={{ flex: 1 }}>{
                                (Number(supplierData.price) * Number(supplierData.boxSize)).toFixed(4)}
                              </Typography>
                            </div>
                          </div>
                        ) : <div className={classes.noDataRow}>
                          <Typography align='center'>No Suppliers</Typography>
                        </div>
                    }
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <ExpansionPanel
              expanded={expanded.current_orders}
              onChange={handlePanelExpansion('current_orders')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>On Order</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className={classes.panelCol}>
                  {
                    currentOrderSummary.current_orders &&
                      Object.keys(currentOrderSummary.current_orders).length > 0 ?
                      Object.keys(currentOrderSummary.current_orders).map((key, index) => {
                        if (currentOrderSummary.current_orders[key].length > 0) {
                          const orders = currentOrderSummary.current_orders[key];
                          return (
                            <div key={key}>
                              <Divider />
                              <div className={classes.panelRowHeader}>{`Supplier: ${orders[0].supplier.name}`}</div>
                              <div className={classes.panelRowHeader}>
                                <Typography className={classes.panelHeaderText}
                                  style={{ flex: 2 }}>Ordered On</Typography>
                                <Typography className={classes.panelHeaderText}
                                  style={{ flex: 1, textAlign: 'center' }}>Price</Typography>
                                <Typography className={classes.panelHeaderText}
                                  style={{ flex: 1, textAlign: 'center' }}>Qty</Typography>
                              </div>
                              <div className={classes.panelCol}>
                                {
                                  // .sort((a, b) => new Date(b.bookingTime) - new Date(a.bookingTime))
                                  orders.map((o: CurrentOrderSummaryItem, index: number) => (
                                    <div className={classes.panelRow} key={`c${index}`}>
                                      <Typography style={{ flex: 2 }}>{renderDate(o.bookingTime)}</Typography>
                                      <Typography style={{ flex: 1, textAlign: 'end' }}>{o.purchasePrice}</Typography>
                                      <Typography style={{ flex: 1, textAlign: 'end' }}>{o.quantity}</Typography>
                                    </div>
                                  ))
                                }
                              </div>
                            </div>
                          );
                        }
                        return null;
                      }) : <div className={classes.noDataRow}>
                        <Typography align='center'>No Current Orders</Typography>
                      </div>
                  }
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <ExpansionPanel
              expanded={expanded.past_orders}
              onChange={handlePanelExpansion('past_orders')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>Past Order</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className={classes.panelCol}>
                  {
                    pastOrderSummary.past_orders &&
                      Object.keys(pastOrderSummary.past_orders).length > 0 ?
                      Object.keys(pastOrderSummary.past_orders).map((key, index) => {
                        if (pastOrderSummary.past_orders[key].length > 0) {
                          const orders = pastOrderSummary.past_orders[key];
                          return (
                            <div key={key}>
                              <Divider />
                              <div className={classes.panelRowHeader}>{`Supplier: ${orders[0].supplier.name}`}</div>
                              <div className={classes.panelRowHeader}>
                                <Typography className={classes.panelHeaderText}
                                  style={{ flex: 2 }}>Booked On</Typography>
                                <Typography className={classes.panelHeaderText}
                                  style={{ flex: 2 }}>Received On</Typography>
                                <Typography className={classes.panelHeaderText}
                                  style={{ flex: 1, textAlign: 'center' }}>Price</Typography>
                                <Typography className={classes.panelHeaderText}
                                  style={{ flex: 1, textAlign: 'center' }}>Qty</Typography>
                              </div>
                              <div className={classes.panelCol}>
                                {

                                  // sort((a, b) => new Date(b.bookingTime) - new Date(a.bookingTime))
                                  orders.map((o: PastOrderSummaryItem, index: number) => (
                                    <div className={classes.panelRow} key={`c${index}`}>
                                      <Typography style={{ flex: 2 }}>{renderDate(o.bookingTime)}</Typography>
                                      <Typography style={{ flex: 2 }}>{renderDate(o.arrival)}</Typography>
                                      <Typography style={{ flex: 1, textAlign: 'end' }}>{o.itemPrice}</Typography>
                                      <Typography style={{ flex: 1, textAlign: 'end' }}>{o.receivedGoods}</Typography>
                                    </div>
                                  ))
                                }
                              </div>
                            </div>
                          );
                        }
                        return null;
                      }) : <div className={classes.noDataRow}>
                        <Typography align='center'>No Past Orders</Typography>
                      </div>
                  }
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <ExpansionPanel
              expanded={expanded.article_texts}
              onChange={handlePanelExpansion('article_texts')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>Product Descriptions</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className={classes.panelCol}>
                  {
                    productData.article_texts &&
                    productData.article_texts.length > 0 &&
                    <div className={classes.panelRowHeader}>
                      <Typography className={classes.panelHeaderText}
                        style={{ flex: 1 }}>Type</Typography>
                      <Typography className={classes.panelHeaderText}
                        style={{ flex: 2 }}>Text</Typography>
                    </div>
                  }
                  <div className={classes.panelCol}>
                    {
                      productData.article_texts &&
                        productData.article_texts.length > 0 ?
                        productData.article_texts.map(i =>
                          <div key={i.id}>
                            <Divider />
                            <div className={classes.panelRow}>
                              <Typography style={{ flex: 1 }}>{getArticleTextType(i.type)}</Typography>
                              <Typography style={{ flex: 2 }}>{i.text}</Typography>
                            </div>
                          </div>
                        ) : <div className={classes.noDataRow}>
                          <Typography align='center'>No Descriptions</Typography>
                        </div>
                    }
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
    setPageLoader: (isLoading: boolean) => dispatch(setPageLoading(isLoading))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailScreen);