import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import MaterialTable, { MaterialTableProps } from 'material-table';
import {
    Button, Input, Link, Typography, Paper, FormControl,
    Select, InputLabel, CircularProgress
} from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { MasterProduct, MasterProductLinkedRecord } from '../../models';
import { PaginatedResponse } from '../../../shared/models';
import { getBusinessListFromAPI } from '../../../shared/data/actions';

import { getUnMappedBusinessProductsCount, deleteMasterProductLinkRecord, getLinkedMasterProductsByPage } from '../../api';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { LinkProductModal } from '../../components/';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { CustomTableTitle, ErrorStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_MSG, ERROR_500, NO_PERMISSIONS_ERROR_MSG } from '../../../shared/lib/Localization';
import { hasAddPermission, hasUpdatePermission, hasDeletePermission } from '../../../shared/lib/PermissionManager';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import { BusinessRouterPaths, MasterDataRouterPaths } from '../../../page/Routes/RouterPaths';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        autoSuggestContainer: {
            position: 'relative',
        },
        suggestionsContainerOpen: {
            position: 'absolute',
            zIndex: 1,
            marginTop: theme.spacing(1),
            left: 0,
            right: 0,
        },
        suggestion: {
            display: 'block',
        },
        suggestionsList: {
            margin: 0,
            padding: 0,
            listStyleType: 'none',
        },
        paper: {
            padding: theme.spacing(3),
            marginBottom: theme.spacing(2),
        },
        formControl: {
            // margin: theme.spacing(1),
            minWidth: 120,
            width: '100%',
            maxWidth: '16rem'
        },
        progress: {
            margin: theme.spacing(2),
        }
    }),
);
const permission_model = 'linkmasterbusinessproduct';

const initalDialogData = {
    open: false,
    business: null as any,
    onClose: null as any,
    mode: 'add',
    linkedRecord: null as any
};

interface Business {
    name: string;
    id: number;
}

const MasterProductLinkScreen = (props: any) => {
    const classes = useStyles({});
    const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
    const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
    const [selectedMasterSupplier, setSelectedMasterSupplier] = useState(null);
    // const [selectedBusiness, setSelectedBusiness] = useState({name : '', id: -1} as Business);
    const [autoSuggestState, setAutoSuggestState] = useState({ single: '' });
    const [stateSuggestions, setSuggestions] = useState<MasterProduct[]>([]);
    const [productDialogData, setProductDialogData] = useState(initalDialogData);
    const [unMappedProductCountData, setUnMappedProductCountData] = useState({ unmapped_product_count: 0 });
    const tableRef = useRef<{ onQueryChange: any; }>();
    const [tableEditableProp, setTableEditableProp] = useState({});
    const [disableEditAction, setDisableEditAction] = useState(false);
    const [disableAddAction, setDisableAddAction] = useState(false);
    let locationQuery = useQuery(useLocation);
    const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
    // useEffect(() => {
    //     props.getBusinessList();
    // }, []);

    // const handleBusinessChange = (event: React.ChangeEvent<{ name?: string; value: number }>) => {
    //     setSelectedBusiness({ name: event.target.name,  id: event.target.value });
    // };

    useEffect(() => {
        if (tableRef && tableRef.current) {
            tableRef.current.onQueryChange();
        }
        if (props.userData && props.userData.selectedBusiness) {
            const sb = props.userData.selectedBusiness;
            getUnMappedBusinessProductsCount(sb.business_id)
                .catch(handleError) // handle network issues
                .then(checkStatus)
                .then(parseJSON)
                .then(jsonData => {
                    setUnMappedProductCountData(jsonData);
                })
                .catch((error: any) => {
                    // console.log('error fetching unmapped product count', error);
                });
        }

    }, [props.userData.selectedBusiness]);

    const openModalAddMode = () => {
        if (props.userData && props.userData.selectedBusiness) {
            const sb = props.userData.selectedBusiness;

            setProductDialogData({
                open: true,
                business: sb,
                onClose: (canceled: boolean, success: boolean, errorData?: any) => {
                    setProductDialogData(initalDialogData);
                    tableRef.current && tableRef.current.onQueryChange();
                    if (!canceled) {
                        if (success) {
                            props.showToast({
                                message: 'Record added successfully!',
                                options: ToastSuccess
                            });
                        } else {
                            let msg = 'Failed to add record.';
                            if (errorData && errorData.status === 403) {
                                msg = NO_PERMISSIONS_ERROR_MSG;
                            } else if (errorData && errorData.status === 500) {
                                msg = ERROR_500;
                            }
                            props.showToast({ message: msg, options: ToastError });
                        }
                    }
                },
                mode: 'add',
                linkedRecord: null
            });
        }
    };
    const openModalEditMode = (event, rowData) => {
        const { userData } = props.userData.userData;
        const { selectedBusiness } = props.userData;
        if (hasUpdatePermission(userData.is_superuser,
            userData.user_permissions, permission_model)) {
            setErrorStatusVisible(false);
            if (selectedBusiness && selectedBusiness.business_id) {
                setProductDialogData({
                    open: true,
                    business: selectedBusiness,
                    onClose: (canceled: boolean, success: boolean, errorData?: any) => {
                        setProductDialogData(initalDialogData);
                        tableRef.current && tableRef.current.onQueryChange();
                        if (!canceled) {
                            if (success) {
                                props.showToast({
                                    message: 'Record updated successfully!',
                                    options: ToastSuccess
                                });
                            } else {
                                let msg = 'Failed to upate record.';
                                if (errorData && errorData.status === 403) {
                                    msg = NO_PERMISSIONS_ERROR_MSG;
                                } else if (errorData && errorData.status === 500) {
                                    msg = ERROR_500;
                                }
                                props.showToast({ message: msg, options: ToastError });
                            }
                        }
                    },
                    mode: 'edit',
                    linkedRecord: rowData
                });
            }
        } else {
            // setErrorStatusVisible(true);
            props.showToast({ message: NO_PERMISSIONS_ERROR_MSG, options: ToastError });
        }
    };

    const getDeleteFn = () => {
        return oldData => new Promise((resolve, reject) => {
            setTimeout(() => {
                {
                    deleteMasterProductLinkRecord(oldData)
                        .catch(handleError) // handle network issues
                        .then(checkStatus)
                        .then(parseJSON)
                        .then(jsonData => {
                            props.showToast({
                                message: 'Record deleted successfully!',
                                options: ToastSuccess
                            });
                        })
                        .catch((error: any) => {
                            let msg = 'Failed to delete record.';
                            if (error) {
                                if ((error.status === 403 || error.status === 401)) {
                                    msg = NO_PERMISSIONS_ERROR_MSG;
                                } else if (error.status === 500) {
                                    msg = ERROR_500;
                                }
                            }
                            props.showToast({ message: msg, options: ToastError });
                        });
                }
                resolve();
            }, 1000);
        });

    };

    const navigateToProductDetail = (product) => {
        props.history.push(`${BusinessRouterPaths.productList}/${product.uuid}`, product);
    };
    const navigateToMasterProductDetail = (mp) => {
        props.history.push(`${MasterDataRouterPaths.masterProducts}/${mp.id}`, mp);
    };

    useEffect(() => {
        const { userData } = props.userData.userData;
        const editable = {};
        if (userData) {
            if (hasDeletePermission(userData.is_superuser,
                userData.user_permissions, permission_model)) {
                editable['onRowDelete'] = getDeleteFn();
            }
            if (hasUpdatePermission(userData.is_superuser,
                userData.user_permissions, permission_model)) {
                setDisableEditAction(false);
            } else {
                setDisableEditAction(true);
            }
            if (hasAddPermission(userData.is_superuser,
                userData.user_permissions, permission_model)) {
                setDisableAddAction(false);
            } else {
                setDisableAddAction(true);
            }
            setTableEditableProp(editable);
        }
    }, [props.userData]);


    return (
        <div>
            <ErrorStatusBar isVisible={isErrorStatusVisible} />
            <Paper className={classes.paper}>
                <div style={{ display: 'flex', }}>
                    {/* <FormControl className={classes.formControl}>
                        <InputLabel id="select-business-label">Business</InputLabel>
                        { props.businessList && props.businessList.data &&
                            <Select
                                value={selectedBusiness.id === -1 ? '' : selectedBusiness.id}
                                onChange={handleBusinessChange}
                                labelId='select-business-label'
                                inputProps={{
                                    name: 'business',
                                    id: 'select-business',
                                }}
                            >
                            {
                                props.businessList &&
                                props.businessList.data &&
                                props.businessList.data.map( b => 
                                <MenuItem value={b.id} key={String(b.id)}>{b.name}</MenuItem>)
                            }
                            </Select>
                        }
                        {
                            props.businessList && props.businessList.isFetching &&
                            <CircularProgress className={classes.progress} />
                        }
                    </FormControl> */}
                    <div style={{ width: '100%' }}>
                        {
                            props.userData.selectedBusiness &&
                            props.userData.selectedBusiness.business_id &&
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', flexWrap: 'wrap', paddingTop: 12 }}>
                                <Typography variant='h6'
                                    style={{ alignSelf: 'center', color: GlobalStyles.primaryColor, padding: '0 16px' }}
                                >Business Products not mapped:&nbsp;</Typography>
                                <Typography variant='h6'
                                    style={{ alignSelf: 'center', paddingRight: 24 }}>
                                    {unMappedProductCountData.unmapped_product_count}
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={disableAddAction}
                                    onClick={() => openModalAddMode()}>
                                    Map Now
                            </Button>
                            </div>
                        }

                    </div>
                </div>
            </Paper>
            <LinkProductModal
                isOpen={productDialogData.open}
                mode={productDialogData.mode}
                business={productDialogData.business}
                onClose={productDialogData.onClose}
                linkedRecord={productDialogData.linkedRecord}
            />
            <div style={{ maxWidth: '100%' }}>
                <div className="table-wrapper">
                    <MaterialTable
                        options={{ ...tableOptions(tableQuery) }}
                        tableRef={tableRef}
                        localization={{
                            body: {
                                emptyDataSourceMessage: emptyDataSourceMessage
                            }
                        }}
                        columns={[
                            {
                                title: 'Business Product',
                                field: 'business_product',
                                render: rowData => {
                                    if (rowData && rowData.business_product &&
                                        rowData.business_product.name) {
                                        return (
                                            <Link variant="body1"
                                                style={{ color: "rgba(0, 0, 0, 0.87)" }}
                                                component="button"
                                                onClick={() => navigateToProductDetail(rowData.business_product)} >
                                                {rowData.business_product.name}
                                            </Link>
                                        );
                                    }
                                    return <div></div>;
                                },
                            },
                            {
                                title: 'Master Product ID',
                                field: 'master_product',
                                type: 'numeric',
                                render: rowData => {
                                    if (rowData && rowData.master_product &&
                                        rowData.master_product.id) {
                                        return (
                                            <Typography variant="body1">
                                                {rowData.master_product.id}
                                            </Typography>
                                        );
                                    }
                                    return <div></div>;
                                },
                                editComponent: props => {
                                    console.log('editComponent', props);
                                    return (
                                        <Input
                                            type="number"
                                            value={props.value ? props.value.id : ''}
                                            inputProps={{
                                                'aria-label': 'master_product',
                                            }}
                                            onChange={e => props.onChange(e.target.value)}
                                        />
                                    );
                                }
                            },
                            {
                                title: 'Master Product Name [Links]',
                                field: 'master_product',
                                editable: 'never',
                                render: rowData => {
                                    if (rowData && rowData.master_product && rowData.master_product.name) {
                                        return (
                                            <Link variant="body1"
                                                style={{ color: "rgba(0, 0, 0, 0.87)" }}
                                                component="button"
                                                onClick={() => navigateToMasterProductDetail(rowData.master_product)}>
                                                {rowData.master_product.name}
                                            </Link>
                                        );
                                    }
                                    return <div></div>;
                                },
                            },
                        ]}
                        data={query =>
                            new Promise((resolve, reject) => {
                                let pageData = {
                                    data: [],
                                    totalCount: 0,
                                    page: query.page
                                };
                                replaceHistory(query, props.history);
                                if (props.userData && props.userData.selectedBusiness) {
                                    const sb = props.userData.selectedBusiness;
                                    getLinkedMasterProductsByPage(sb.business_id,
                                        query.page + 1, query.pageSize, query.search)
                                        .catch(handleError) // handle network issues
                                        .then(checkStatus)
                                        .then(parseJSON)
                                        .then((data: PaginatedResponse<MasterProductLinkedRecord>) => {
                                            setErrorStatusVisible(false);
                                            setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                                            pageData = {
                                                data: data.results,
                                                totalCount: data.count,
                                                page: query.page
                                            };
                                            setTableQuery({
                                                ...tableQuery,
                                                totalCount: data.count,
                                                page: query.page,
                                                pageSize: query.pageSize,
                                            });
                                            resolve(pageData);
                                        })
                                        .catch((error: any) => {
                                            if (error.status && error.status === 500) {
                                                setEmptyDataSourceMessage(ERROR_500);
                                                setErrorStatusVisible(false);
                                            } else if ((error.status === 403 || error.status === 401)) {
                                                setEmptyDataSourceMessage(ERROR_MSG);
                                                setErrorStatusVisible(true);
                                            } else {
                                                setEmptyDataSourceMessage(ERROR_MSG);
                                                setErrorStatusVisible(false);
                                            }
                                            setTableQuery({
                                                ...tableQuery,
                                                totalCount: 0,
                                                page: query.page,
                                                pageSize: query.pageSize,
                                            });
                                            resolve(pageData);
                                        });
                                }
                            })
                        }
                        title={
                            <CustomTableTitle
                                history={props.history}
                                title={'Link Products'}
                                icon={SVG_ICONS.linkPlus} />
                        }
                        actions={[
                            {
                                icon: 'refresh',
                                tooltip: 'Refresh Data',
                                isFreeAction: true,
                                onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                            },
                            {
                                icon: 'edit',
                                tooltip: 'Edit',
                                disabled: disableEditAction,
                                isFreeAction: false,
                                onClick: (event, rowData) => openModalEditMode(event, rowData)
                            }
                        ]}
                        editable={tableEditableProp}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        businessList: state.sharedModuleData.businessList,
        userData: state.userLocalData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getBusinessList: () => dispatch(getBusinessListFromAPI()),
        showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterProductLinkScreen);