import { ERROR_CODES } from '../models';
import moment from 'moment';

export const ERROR_MSG = 'Oops, an error occured! Please try again later.';
export const EMPTY_ROW_MSG = 'No records to display';
export const UNKNOWN_IDENTIFIER_EMPTY_ROW_MSG = 'Currently, there is no data. Please check the following settings on Korona.';
export const ERROR_404 = 'Data not Found!';
export const ERROR_500 = 'Server Error! Please try again later.';
export const NO_PERMISSIONS_ERROR_MSG = `Permission Denied!\nThis action could not be performed.\nError Code: ${ERROR_CODES.NO_PERMISSIONS}`;
export const getCustomPermissionErrorMsg = (actionText: string) =>
  `Permission Denied!\n${actionText}\nError Code: ${ERROR_CODES.NO_PERMISSIONS}`;

export const API_RESPONSE = {
  ADD_INVENTORY_COUNT_SUCCESS: 'Inventory Count created successfully!',
  ADD_INVENTORY_COUNT_FAILURE: 'Failed to create Inventory Count.',
  ADD_INVENTORY_COUNT_LIST_SUCCESS: 'Inventory Count List created successfully!',
  ADD_INVENTORY_COUNT_LIST_FAILURE: 'Failed to create Inventory Count List.',
  UPDATE_INVENTORY_COUNT_LIST_SUCCESS: 'Inventory Count List updated successfully!',
  UPDATE_INVENTORY_COUNT_LIST_FAILURE: 'Failed to update Inventory Count List.',
  DELETE_INVENTORY_COUNT_ITEM_SUCCESS: 'Item deleted successfully!',
  DELETE_INVENTORY_COUNT_ITEM_FAILURE: 'Failed to delete Inventory Count Item.',
  SAVE_LABELER_SETTINGS_FAILURE: 'Error! Failed to save selected location.',
  SAVE_SELECTED_LOCATION_FAILURE: 'Error! Failed to save labeler settings.',
  SAVE_SELECTED_TEMPLATE_FAILURE: 'Error! Failed to save selected template.',
  UPDATE_MASTER_PRODUCT_SUCCESS: 'Master Product updated successfully!',
  UPDATE_MASTER_PRODUCT_FAILURE: 'Failed to update Master Product.',
  GET_MASTER_PRODUCT_DETAIL_FAILURE: 'Failed to retrieve master product info.',
  SEARCH_PRODUCT_BY_CODE_FAILURE: 'Failed to search Product Info.',
  SEARCH_PRODUCT_NOT_FOUND: 'Product not Found!',
  LABEL_PREVIEW_FAILURE: 'Failed To Load Template Preview.',
  UPDATE_UNKNOWN_IDENTIFIER_SUCCESS: 'Unknown Identifier updated successfully!',
  UPDATE_UNKNOWN_IDENTIFIER_FAILURE: 'Unknown Identifier updated successfully!',
  ADD_STOCK_RECEIPT_SUCCESS: 'Stock Receipt created successfully!',
  ADD_STOCK_RECEIPT_FAILURE: 'Failed to create Stock Receipt.',
  UPDATE_STOCK_RECEIPT_SUCCESS: 'Stock Receipt updated successfully!',
  UPDATE_STOCK_RECEIPT_FAILURE: 'Failed to update Stock Receipt.',
};

export const getErrorMsg = (error: any, defaultMsg: string): string => {
  let msg = defaultMsg;
  if (error && error.status) {
    switch (error.status) {
      case 401:
        msg = NO_PERMISSIONS_ERROR_MSG;
        break;
      case 403:
        msg = NO_PERMISSIONS_ERROR_MSG;
        break;
      case 404:
        msg = ERROR_404;
        break;
      case 500:
        msg = ERROR_500;
        break;
      default:
        msg = defaultMsg;
    }
  }
  return msg;
};

export const formatDate = (date: string): string => {
  if (date) {
    return moment(date).format('LLL');
  }
  return '';
};
