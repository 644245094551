import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { LinkProduct } from '../../../master-data/components';
import { Product } from '../../../products/models';
import { MappingSuggestion, MasterProduct } from '../../../master-data/models';
import { API_URLS } from '../../../master-data/api';
import { Business, LocationBusiness, ToastType } from '../../../shared/models';
import { StockOrderItemDatum } from '../../models';
import { getMatchedInvoiceItemList } from '../../api';
import composeRequest from '../../../shared/api/core';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { EMPTY_ROW_MSG, ERROR_MSG, ERROR_500, NO_PERMISSIONS_ERROR_MSG } from '../../../shared/lib/Localization';
import { MasterSupplierLinkedRecord, BusinessSupplierLinkRecord } from '../../../master-data/models';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface FixInvoiceItemModalProps {
  onClose?: (invoiceItem?: StockOrderItemDatum) => void;
  isOpen: boolean;
  onOk?: () => void;
  invoiceItem: StockOrderItemDatum;
  selectedSupplier: BusinessSupplierLinkRecord;
  userData?: any;
  business: LocationBusiness | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#F3F5F7'
    },
    heading: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightRegular,
    },
    panelRow: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '12px 16px',
      alignItems: 'center'
    },
    panelCol: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    panelRowHeader: {
      backgroundColor: '#f5f5f5',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '16px'
    },
    panelHeaderText: {
      fontSize: '0.95rem'
    },
  }),
);

const initalDialogData = {
  open: false,
  business: null as null | { business_id: number, business_name: string; },
  onClose: null as any,
  mode: 'add',
  linkedRecord: null as any,
  suggestions: [] as MappingSuggestion[] | undefined,
  invoiceItemDescription: null as string | null,
  supplierProduct: null as | Product | undefined | null,
};

const defaultFinishAction = {
  label: 'Next',
  action: (selectedBusinessProduct: any, selectedMasterProduct: any, invoiceItem: any) => { }
};

const FixInvoiceItemModal = (props: FixInvoiceItemModalProps) => {
  console.log(props);
  const classes = useStyles({});
  const [open, setOpen] = useState(props.isOpen);
  const [invoiceItem, setInvoiceItem] = useState<StockOrderItemDatum | null>(null);
  const [selectedBusinessProduct, setSelectedBusinessProduct] = useState<Product | null>(null);
  const [selectedMasterProduct, setSelectedMasterProduct] = useState<MasterProduct | null>(null);
  const [selectedNewMasterProduct, setNewSelectedMasterSupplier] = useState<MasterProduct | null>(null);
  const [linkProductData, setLinkProductData] = useState(initalDialogData);
  const [allIssuesResolved, setAllIssuesResolved] = useState(false);
  const [dialogFinishAction, setDialogFinishAction] = useState(defaultFinishAction);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({ open: false, message: '', variant: 'success' } as ToastType);
  const theme = useTheme();
  useEffect(() => {
    if (props.invoiceItem) {
      setInvoiceItem(props.invoiceItem);
      setModalContent(props.invoiceItem);
    }

  }, [props.invoiceItem]);

  const createBusinessProductLinkRecord = (selectedBusinessProduct: Product, selectedMasterProduct: MasterProduct, invoiceItem: StockOrderItemDatum) => {
    console.log('createBusinessProductLinkRecord called');
    if (selectedBusinessProduct && selectedMasterProduct) {
      const URL = API_URLS.masterProductLinkUrl;
      const data = {
        business: selectedBusinessProduct.business,
        master_product: selectedMasterProduct.id,
        business_product: selectedBusinessProduct.uuid
      };
      setLoading(true);
      composeRequest(URL, 'POST', JSON.stringify(data))
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then(parseJSON)
        .then(jsonData => {
          setLoading(false);
          setLinkProductData(initalDialogData);
          setToast({
            open: true,
            message: 'Product Linked successfully!',
            variant: 'success'
          });
          matchInvoiceItem(invoiceItem);
        })
        .catch((error: any) => {
          setLoading(false);
          let msg = 'Failed to map product.';
          if (error && error.status === 403) {
            msg = NO_PERMISSIONS_ERROR_MSG;
          } else if (error && error.status === 500) {
            msg = ERROR_500;
          }
          setToast({
            open: true,
            message: msg,
            variant: 'error'
          });
        });
    }
  };

  const setModalContent = (invoiceItem: StockOrderItemDatum) => {
    if (invoiceItem) {
      console.log('setModalContent', invoiceItem);
      let dialogData = { ...initalDialogData };
      let { id, name } = props.selectedSupplier.korona_supplier_business;
      if (!invoiceItem.error_type && !invoiceItem.error) {
        setAllIssuesResolved(true);
        setDialogFinishAction({
          label: 'Finish',
          action: () => {
            props.onClose && props.onClose({ ...invoiceItem });
            setAllIssuesResolved(false);
            setDialogFinishAction(defaultFinishAction);
          }
        });
      }
      switch (invoiceItem.error_type) {
        case 'link_business_product':
          dialogData.suggestions = invoiceItem.suggestions;
          dialogData.open = true;
          const sb = props.userData.selectedBusiness;
          dialogData.business = sb;
          dialogData.invoiceItemDescription = invoiceItem.supplierProductName;
          dialogData.mode = 'link_business_product';
          dialogData.linkedRecord = {
            business: id,
            master_product: invoiceItem.supplier_master_product,
            business_product: null
          };
          dialogData.supplierProduct = invoiceItem.supplier_product;
          setLinkProductData(dialogData);
          setDialogFinishAction({
            ...dialogFinishAction,
            action: (selectedBusinessProduct, selectedMasterProduct, invoiceItem) => createBusinessProductLinkRecord(selectedBusinessProduct, selectedMasterProduct, invoiceItem)
          });
          break;
        case 'supplier_product':
          setLinkProductData(dialogData);
          setDialogFinishAction({
            label: 'Ok',
            action: (selectedBusinessProduct, selectedMasterProduct, invoiceItem) => {
              props.onClose && props.onClose();
              setAllIssuesResolved(false);
            }
          });
          break;
        case 'link_supplier_product':
          dialogData.open = true;
          dialogData.business = { business_id: id, business_name: name };
          dialogData.invoiceItemDescription = invoiceItem.supplierProductName;
          dialogData.mode = 'link_supplier_product';
          dialogData.linkedRecord = {
            business: id,
            master_product: null,
            business_product: invoiceItem.supplier_product
          };
          dialogData.suggestions = invoiceItem.suggestions;
          dialogData.supplierProduct = null;
          setLinkProductData(dialogData);
          setDialogFinishAction({
            label: 'Next',
            action: (selectedBusinessProduct, selectedMasterProduct, invoiceItem) => createBusinessProductLinkRecord(selectedBusinessProduct, selectedMasterProduct, invoiceItem)
          });
          break;
      }
    }
    return null;
  };

  const matchInvoiceItem = (invoiceItem: StockOrderItemDatum) => {
    const item = { ...invoiceItem };
    ////    delete these keys for a new response from api;
    delete item.error_type;
    delete item.error;
    delete item.suggestions;
    console.log('matchInvoiceItem', invoiceItem);
    const payload = {
      stockOrderItems: [item],
      business_id: props.selectedSupplier.business.id,
      korona_supplier_business_id: props.selectedSupplier.korona_supplier_business.id
    };
    setLoading(true);
    getMatchedInvoiceItemList(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: StockOrderItemDatum[]) => {
        console.log('matchInvoiceItem', data[0]);
        setInvoiceItem(data[0]);
        setModalContent(data[0]);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const addLinkRecord = (suggestion: MappingSuggestion, business_product: Product) => {
    setSelectedBusinessProduct(business_product);
    if (suggestion.master_product) {
      setSelectedMasterProduct(suggestion.master_product);
    }
  };

  const handleMPSelectionChange = (rows: any) => {
    if (rows.length > 0) {
      let masterSupplier = { ...rows[0] };
      delete masterSupplier['tableData'];
      setSelectedMasterProduct(masterSupplier);
    } else {
      setSelectedMasterProduct(null);
    }
  };

  const handleBPSelectionChange = (rows: any) => {
    if (rows.length > 0) {
      let product = { ...rows[0] };
      delete product['tableData'];
      setSelectedBusinessProduct(product);
    } else {
      setSelectedBusinessProduct(null);
    }
  };

  const handleCloseToast = (event?: React.SyntheticEvent, reason?: string) => {
    // if (reason === 'clickaway') {
    //   return;
    // }
    setToast({ open: false, message: '', variant: 'success' });
  };

  const getIssueMesage = () => {
    if (invoiceItem) {
      switch (invoiceItem.error_type) {
        case 'link_business_product':
          return ' Please create a mapping for the Customer Product.';
        case 'supplier_product':
          const hasReorderCode = invoiceItem.reorderCode ? true : false;
          if (!hasReorderCode) {
            return ' The Supplier Product could not be found by Reorder Code nor by Invoice Item Text. Please verify this invoice item matches the supplier item.';
          }
          return ' The Supplier Product could not be found by Invoice Item Text. Please verify this invoice item matches the supplier item.';
        case 'link_supplier_product':
          return ' Please create a mapping for the supplier product.';
      }
    }
    return '';
  };

  const isModalFullWidth = () => {
    if (linkProductData && linkProductData.mode) {
      const { mode } = linkProductData;
      if (mode === 'add' || mode === 'link_business_product') {
        return true;
      }
    }
    return false;
  };
  const getModalWidth = () => {
    if (linkProductData && linkProductData.mode) {
      const { mode } = linkProductData;
      if (mode === 'add') {
        return 'md';
      } else if (mode === 'link_business_product') {
        return 'lg';
      }
    }
    return 'sm';
  };

  return (
    <div>
      <Dialog
        fullWidth={isModalFullWidth()}
        maxWidth={getModalWidth()}
        open={props.isOpen}
        onClose={() => props.onClose && props.onClose()} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {
            `Fix Issues for ${invoiceItem && invoiceItem.supplierProductName ? invoiceItem.supplierProductName : ''}`
          }
        </DialogTitle>
        <DialogContent>
          {
            loading &&
            <div style={{ textAlign: 'center' }}>
              <CircularProgress />
            </div>
          }
          {
            !allIssuesResolved &&
            <>
              <div style={{ paddingBottom: 16 }}>
                <div style={{
                  border: '1px solid #cdcdcd',
                  padding: '8px 16px',
                  borderRadius: 4
                }}>
                  <Typography variant="body1">{`Current Issue: `}
                    <span style={{ color: theme.palette.error.main }}>{invoiceItem && invoiceItem.error ? invoiceItem.error : ''}</span></Typography>
                </div>
              </div>
              <div style={{ paddingBottom: 16 }}>
                <div style={{
                  border: '1px solid #cdcdcd',
                  padding: '8px 16px',
                  borderRadius: 4
                }}>
                  <Typography variant="body1">
                    <span>Action: </span>{getIssueMesage()}</Typography>
                </div>
              </div>
            </>
          }
          {
            !allIssuesResolved &&
            linkProductData.open &&
            <LinkProduct
              mode={linkProductData.mode}
              business={linkProductData.business}
              linkedRecord={linkProductData.linkedRecord}
              suggestions={linkProductData.suggestions}
              handleMPSelectionChange={handleMPSelectionChange}
              handleBPSelectionChange={handleBPSelectionChange}
              selectedBusinessProduct={selectedBusinessProduct}
              setSelectedBusinessProduct={setSelectedBusinessProduct}
              selectedMasterProduct={selectedMasterProduct}
              setSelectedMasterProduct={setSelectedMasterProduct}
              selectedNewMasterProduct={selectedNewMasterProduct}
              setNewSelectedMasterSupplier={setNewSelectedMasterSupplier}
              addLinkRecord={addLinkRecord}
              supplierProduct={linkProductData.supplierProduct}
            />
          }
          {
            allIssuesResolved &&
            <div style={{ padding: '0 16px', border: '2px solid #cdcdcd', borderRadius: 4 }}>
              <div style={{ display: 'flex', paddingTop: 8, paddingBottom: 8, alignItems: 'center' }}>
                <div style={{ minWidth: 56, display: 'inline-flex' }}>
                  <CheckCircleOutlineIcon
                    style={{
                      color: theme.palette.success.main,
                      fontSize: '2rem'
                    }} />
                </div>
                <Typography style={{ fontSize: '1.4rem' }}>All Issues have been resolved for this item!</Typography>
              </div>
            </div>
          }
          <Snackbar open={toast.open} autoHideDuration={6000} onClose={handleCloseToast}>
            <Alert onClose={handleCloseToast} severity={toast.variant}>
              {toast.message}
            </Alert>
          </Snackbar>
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px' }}>
          {
            !allIssuesResolved &&
            <Button onClick={() => {
              setSelectedBusinessProduct(null);
              setSelectedMasterProduct(null);
              props.onClose && props.onClose();
            }}>Cancel</Button>
          }
          <Button onClick={(e) => dialogFinishAction.action(selectedBusinessProduct, selectedMasterProduct, invoiceItem)}
            color="primary"
            variant="contained">{dialogFinishAction.label}</Button>
        </DialogActions>
      </Dialog>
    </div >
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

export default connect(mapStateToProps, null)(FixInvoiceItemModal);
