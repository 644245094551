import { API_CONFIG } from '../../shared/api/config';
import composeRequest, { getPaginatedResponse } from '../../shared/api/core';
import { ProcessUnknownIdentifierData, UnknownIdentifier } from '../models';

const BASE_URL = API_CONFIG.API_BASE_PATH;

export const API_URLS = {
    productsNoBarcodesUrl: BASE_URL + 'korona/reports/products/no-barcode/',
    productsDuplicateNameUrl: BASE_URL + 'korona/reports/products/duplicate-name/',
    unMappedBusinessProductsUrl: BASE_URL + 'korona/unmapped-products/',
    masterSupplierProductsWithoutCaseCodeUrl:
        BASE_URL + 'korona/master/supplier-products-without-case-code/',
    masterSupplierProductsWithoutCaseQtyUrl:
        BASE_URL + 'korona/master/supplier-products-without-case-qty/',
    masterSupplierProductsWithoutIdentifiersUrl:
        BASE_URL + 'korona/master/supplier-products-without-identifiers/',
    masterSupplierProductsWithoutUnitMeasureUrl:
        BASE_URL + 'korona/master/supplier-products-without-uom/',
    masterProductsDuplicatesUrl: BASE_URL + 'korona/master/product-duplicates/',
    masterIdentifierDuplicatesURL:BASE_URL + 'korona/master/product-duplicates-identifier/',//identifier duplicates url
    masterProductsWithoutIdentifiersUrl: BASE_URL + 'korona/master/products-without-identifiers/',
    masterProductsWithoutQtyValuesUrl: BASE_URL + 'korona/master/products-without-qty-values/',
    masterProductsWithoutUnitMeasureUrl: BASE_URL + 'korona/master/products-without-uom/',
    masterProductsNameUnitMismatchUrl: BASE_URL + 'korona/master/name-unit-mismatch/',
    masterSupplierProductIdentifiersUrl: BASE_URL + 'korona/master/supplier-product-identifiers/',
    rmsHQItemsReport: BASE_URL + 'korona/reports/rms/items-mismatch',
    rmsCostPriceReport: BASE_URL + 'korona/reports/rms/cost-price',
    unknownIdentifierUrl: BASE_URL + 'korona/reports/unknown-identifier-history/',
    // unknownIdentifierReportUrl: BASE_URL + 'korona/reports/unknown-identifier-history/summary/',
    processUnknownIdentifierUrl: BASE_URL + 'korona/reports/unknown-identifier/toggle-processed',
    expectedStockByWeekBySupplierUrl:
        BASE_URL + 'korona/reports/expected-stock-by-week-by-supplier/',
    expectedStockByWeekByCgroupUrl:
        BASE_URL + 'korona/reports/expected-stock-by-week-by-commodity-group/',
    productsWithStorePriceUrl: BASE_URL + 'korona/reports/products/store-price/',
    // productPriceDetailByLastSold : BASE_URL + 'korona/reports/price-detail/'
    productPriceDetailByLastSold : BASE_URL + 'korona/reports/products/price-last-sold/',
    produceCodesUrl: BASE_URL + 'korona/reports/produce-codes/',
    supplierProductListUrl : BASE_URL + 'korona/reports/supplier-product-list/',
    retailterCodeNotLinkedMasterProductIdentifierUrl : BASE_URL + 'korona/reports/retailer-code-not-in-linked-master-product-identifier/'

};

export const getUnknownIdentifiersReport = (
    business_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    orderBy?: string | number ,
    filters?: string | null,
    shouldExport?: boolean
) => {
    let URL;
    if (shouldExport) {
        URL = `${API_URLS.unknownIdentifierUrl}export/?business_ids=${business_id}`;
    } else {
        URL = `${
            API_URLS.unknownIdentifierUrl
        }?business_ids=${business_id}&page=${page}&page_size=${pageSize}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getExpectedStockByWeek = (
    reportType: 'by_supplier' | 'by_cgroup',
    business_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    orderBy?: string | number ,
    filters?: string | null,
    shouldExport?: boolean
) => {
    let URL = API_URLS.expectedStockByWeekByCgroupUrl;
    if (reportType === 'by_supplier') {
        URL = API_URLS.expectedStockByWeekBySupplierUrl;
    }
    if (shouldExport) {
        URL = `${URL}export/?business_ids=${business_id}`;
    } else {
        URL = `${URL}?business_ids=${business_id}&page=${page}&page_size=${pageSize}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getproductPriceDetailByLastSold = (
    business_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    orderBy?: string | number ,
    filters?: string | null,
    shouldExport?: boolean
) => {
    let URL = API_URLS.productPriceDetailByLastSold;
    if (shouldExport) {
        URL = `${URL}export/?business_ids=${business_id}`;
    } else {
        URL = `${URL}?business_ids=${business_id}&page=${page}&page_size=${pageSize}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getProductsWithStorePrice = (
    business_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    orderBy?: string | number ,
    filters?: string | null,
    shouldExport?: boolean
) => {
    let URL = API_URLS.productsWithStorePriceUrl;
    if (shouldExport) {
        URL = `${URL}export/?business_ids=${business_id}`;
    } else {
        URL = `${URL}?business_ids=${business_id}&page=${page}&page_size=${pageSize}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getProduceCodes = (
    business_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    orderBy?: string | number ,
    filters?: string | null,
    shouldExport?: boolean
) => {
    let URL = API_URLS.produceCodesUrl;
    if (shouldExport) {
        URL = `${URL}export/?business_ids=${business_id}`;
    } else {
        URL = `${URL}?business_ids=${business_id}&page=${page}&page_size=${pageSize}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getRetailterCodeNotLinkedMasterProductIdentifier = (
    supplier_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    orderBy?: string | number ,
    filters?: string | null,
    shouldExport?: boolean
) => {
    let URL = API_URLS.retailterCodeNotLinkedMasterProductIdentifierUrl;
    if (shouldExport) {
        URL = `${URL}export/?suppl_id=${supplier_id}`;
    } else {
        URL = `${URL}?suppl_id=${supplier_id}&page=${page}&page_size=${pageSize}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};


export const getSupplierProductList = (
    business_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    orderBy?: string | number ,
    filters?: string | null,
    shouldExport?: boolean
) => {
    let URL = API_URLS.supplierProductListUrl;
    if (shouldExport) {
        URL = `${URL}export/?business_ids=${business_id}`;
    } else {
        URL = `${URL}?business_ids=${business_id}&page=${page}&page_size=${pageSize}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};


export const processUnknownIdentifier = (
    data: ProcessUnknownIdentifierData | UnknownIdentifier
) => {
    let URL = `${API_URLS.processUnknownIdentifierUrl}`;
    return composeRequest(URL, 'PUT', JSON.stringify(data));
};

// To check if these functions are called
export const getMasterProductDuplicates = (
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    return getPaginatedResponse(API_URLS.masterProductsDuplicatesUrl, page, pageSize, searchTerm);
};
export const getMasterProductsUnitMismatch = (
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    return getPaginatedResponse(
        API_URLS.masterProductsNameUnitMismatchUrl,
        page,
        pageSize,
        searchTerm
    );
};
export const getMPWithoutIdentifiers = (
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    return getPaginatedResponse(
        API_URLS.masterProductsWithoutIdentifiersUrl,
        page,
        pageSize,
        searchTerm
    );
};
export const getMPWithoutQtyValues = (
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    return getPaginatedResponse(
        API_URLS.masterProductsWithoutQtyValuesUrl,
        page,
        pageSize,
        searchTerm
    );
};

export const getMasterIdentifierDuplicates = (
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    return getPaginatedResponse(API_URLS.masterIdentifierDuplicatesURL, page, pageSize, searchTerm);
};