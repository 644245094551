import React, { useEffect, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import AsyncSelect from '../AsyncSelect';
import { Filter, FilterType } from '../../../shared/models/Filter';

const useFilterStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      paddingTop: 4.5,
      paddingBottom: 4.5,
    },
    filterContainer: {
      minHeight: 288,
      border: '1px solid rgba(0,0,0,0.12)',
      borderRadius: 4,
    },
    header: {
      padding: '8px 16px',
      backgroundColor: '#eee',
      borderBottom: '1px solid rgba(0,0,0,0.12)',
    },
    selectOutline: {
      padding: '10.5px 14px',
    },
    textField: {
      width: '100%',
    },
  })
);

export const AvailableFilters = (props) => {
  const { filters, addFilter } = props;
  return filters.map((f, i) => {
    return (
      <React.Fragment key={`filter-label-${f.id}`}>
        <ListItem button onClick={() => addFilter(f)}>
          <ListItemText primary={f.label} />
          <ListItemSecondaryAction>
            <AddIcon />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
      </React.Fragment>
    );
  });
};

export const SelectedFilters = (props) => {
  const classes = useFilterStyles({});
  const { selectedFilters, removeFilter, updateFilter, businessId } = props;
  const [filterTextInputMap, setFilterTextInputMap] = useState({});

  useEffect(() => {
    const textFilters = selectedFilters.filter((f) => !f.url && !Array.isArray(f.value));
    if (textFilters.length > 0) {
      const map = { ...filterTextInputMap };
      textFilters.forEach((f) => (map[f.id] = f.value ? f.value : ''));
      setFilterTextInputMap(map);
    }
  }, [selectedFilters]);

  const handleFilterTypeChange = (e, f: Filter, index: number) => {
    console.log(f.selectedType);
    const filter = { ...f };
    filter.selectedType = e.target.value;
    if (!f.url && !Array.isArray(f.value)) {
      filter.value = filterTextInputMap[f.id];
    }
    updateFilter(filter, index);
  };

  const handleValueChange = (values: string | any[], f: Filter, index: number) => {
    const filter = { ...f };
    filter.value = values;
    updateFilter(filter, index);
  };

  const handleTextChange = (e, f: Filter) => {
    const val = e.target ? (e.target.value ? e.target.value.trim() : '') : '';
    const map = { ...filterTextInputMap };
    map[f.id] = val;
    setFilterTextInputMap(map);
  };

  const onKeyDown = (e, f, i) => {
    if (e.keyCode === 13) {
      const val = filterTextInputMap[f.id] ? filterTextInputMap[f.id] : '';
      handleValueChange(val, f, i);
    }
  };

  const renderFilterOptions = (options: FilterType[]) => {
    return options.map((o, i) => {
      return (
        <MenuItem key={`opt-${i}`} value={o.value}>
          {o.label}
        </MenuItem>
      );
    });
  };

  const renderFilter = (f: Filter, i: number) => {
    return (
      <React.Fragment key={`selected-filter-${i}`}>
        <div className={classes.row}>
          <div style={{ width: '25%', paddingLeft: 16, paddingRight: 16 }}>
            <Typography variant="body1">{f.label}</Typography>
          </div>
          <div style={{ width: '20%' }}>
            <Select
              id={`select-${f.id}`}
              value={f.selectedType}
              onChange={(e) => handleFilterTypeChange(e, f, i)}
              variant="outlined"
              style={{ width: '100%' }}
              classes={{ outlined: classes.selectOutline }}
            >
              {renderFilterOptions(f.types)}
            </Select>
          </div>
          <div style={{ width: '55%', paddingLeft: 8, paddingRight: 8 }}>
            {f.url && Array.isArray(f.value) && (
              <AsyncSelect
                url={f.url}
                businessId={businessId}
                labelField={f.labelField}
                onSelectChange={(values) => handleValueChange(values, f, i)}
                selected={f.value}
                bindValue={f.bindValue}
                isResponsePaginated={f.isResponsePaginated}
              />
            )}
            {!f.url && !Array.isArray(f.value) && (
              <TextField
                id={`outlined-field-${f.id}`}
                margin="none"
                variant="outlined"
                className={classes.textField}
                onKeyDown={(e) => onKeyDown(e, f, i)}
                onChange={(e) => handleTextChange(e, f)}
                size="small"
                value={filterTextInputMap[f.id] ? filterTextInputMap[f.id] : ''}
              />
            )}
          </div>
          <div style={{ paddingRight: 8 }}>
            <IconButton
              style={{ padding: 8 }}
              aria-label="delete"
              onClick={() => removeFilter(i)}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
        <Divider />
      </React.Fragment>
    );
  };

  return (
    <>
      {selectedFilters && selectedFilters.length === 0 && (
        <div className={classes.row}>
          <div style={{ width: '100%', padding: 16 }}>
            <Typography variant="body1" align="center">
              No Active Filters
                        </Typography>
          </div>
        </div>
      )}
      {selectedFilters &&
        selectedFilters.length > 0 &&
        selectedFilters.map((f: Filter, i) => renderFilter(f, i))}
    </>
  );
};

export const FilterRow = (props) => {
  // console.log(props);
  const classes = useFilterStyles({});
  const { renderAvailableFilters, renderSelectedFilters } = props;
  return (
    <div
      style={{
        padding: 16,
        width: '100%',
        borderTop: '1px solid rgba(0,0,0,0.12)',
        borderBottom: '1px solid rgba(0,0,0,0.12)',
        display: 'flex',
      }}
    >
      <div style={{ width: '30%' }}>
        <div className={classes.filterContainer} style={{ marginRight: 8 }}>
          <div>
            <div className={classes.header}>
              <Typography variant="body1">Available Filters</Typography>
            </div>
            <List disablePadding>
              {renderAvailableFilters ? renderAvailableFilters : null}
            </List>
          </div>
        </div>
      </div>
      <div style={{ width: '70%' }}>
        <div className={classes.filterContainer}>
          <div>
            <div className={classes.header}>
              <Typography variant="body1">Active Filters</Typography>
            </div>
            {renderSelectedFilters ? renderSelectedFilters : null}
          </div>
        </div>
      </div>
    </div>
  );
};