import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MaterialTable, { MaterialTableProps } from 'material-table';
import { Business, LocationBusiness } from '../../../shared/models';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { MasterProduct, MasterProductLinkedRecord, MappingSuggestion } from '../../models';
import { Product } from '../../../products/models';
import { PaginatedResponse } from '../../../shared/models';

import { API_URLS, getMasterProductsByPage, getUnMappedProductsByPage } from '../../api';
import composeRequest from '../../../shared/api/core';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '../../../shared/components';
import LinkProduct from './LinkProduct';

interface LinkProductModalProps {
	onClose?: (canceled: boolean, success: boolean, errorData?: any) => any,
	isOpen: boolean,
	mode: string,
	onSave?: () => any,
	onUpdate?: () => any,
	business: LocationBusiness,
	linkedRecord?: MasterProductLinkedRecord;
	suggestions?: MappingSuggestion[];
	invoiceItemDescription?: string;
	// supplierProduct?: Product;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: '#F3F5F7'
		},
		heading: {
			fontSize: theme.typography.pxToRem(18),
			fontWeight: theme.typography.fontWeightRegular,
		},
		panelRow: {
			display: 'flex',
			justifyContent: 'space-between',
			width: '100%',
			padding: '12px 16px',
			alignItems: 'center'
		},
		panelCol: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%'
		},
		panelRowHeader: {
			backgroundColor: '#f5f5f5',
			display: 'flex',
			justifyContent: 'space-between',
			width: '100%',
			padding: '16px'
		},
		panelHeaderText: {
			fontSize: '0.95rem'
		},
	}),
);


//@ts-nocheck
export default function LinkProductModal(props: LinkProductModalProps) {
	console.log(props);
	const classes = useStyles({});
	const [open, setOpen] = useState(props.isOpen);
	const [selectedBusinessProduct, setSelectedBusinessProduct] = useState(null);
	const [selectedMasterProduct, setSelectedMasterProduct] = useState(null);
	const [selectedNewMasterProduct, setNewSelectedMasterSupplier] = useState(null);
	// const masterProductTableRef = useRef<{ onQueryChange: any; }>();
	// const unmappedProductTableRef = useRef<{ onQueryChange: any; }>();
	// const [expandSuggestionPanel, setExpandSuggestionPanel] = useState(false);

	useEffect(() => {
		if (props.linkedRecord) {
			setSelectedBusinessProduct({ ...props.linkedRecord.business_product });
			setSelectedMasterProduct({ ...props.linkedRecord.master_product });
		}
	}, [props.linkedRecord]);


	const addLinkRecord = (suggestion: MappingSuggestion, business_product: Product) => {
		setSelectedBusinessProduct(business_product);
		if (suggestion.master_product) {
			setSelectedMasterProduct(suggestion.master_product);
		}
	};

	const addRecord = () => {
		if (selectedBusinessProduct && selectedMasterProduct) {
			const URL = API_URLS.masterProductLinkUrl;
			const data = {
				business: props.business.business_id,
				master_product: selectedMasterProduct.id,
				business_product: selectedBusinessProduct.uuid
			};
			composeRequest(URL, 'POST', JSON.stringify(data))
				.catch(handleError) // handle network issues
				.then(checkStatus)
				.then(parseJSON)
				.then(jsonData => {
					console.log('parseJSON', jsonData);
					props.onClose && props.onClose(false, true);
				})
				.catch((error: any) => {
					console.log('error', error);
					props.onClose && props.onClose(false, false, error);
				});
		}
	};

	const updateRecord = () => {
		if (selectedNewMasterProduct) {
			const URL = API_URLS.masterProductLinkUrl + props.linkedRecord.id + '/';
			const data = {
				id: props.linkedRecord.id,
				business: props.linkedRecord.business,
				business_product: props.linkedRecord.business_product.uuid,
				master_product: selectedNewMasterProduct.id
			};
			composeRequest(URL, 'PUT', JSON.stringify(data))
				.catch(handleError) // handle network issues
				.then(checkStatus)
				.then(parseJSON)
				.then(jsonData => {
					console.log('parseJSON', jsonData);
					props.onClose && props.onClose(false, true);
				})
				.catch((error: any) => {
					console.log('error', error);
					props.onClose && props.onClose(false, false, error);
				});
		}
	};

	// const handleMPRowClick = (e, row) => {
	// 	console.log('handleRowClick', e);
	// 	console.log('rowData', row);
	// 	let masterSupplier = { ...row };
	// 	delete masterSupplier['tableData'];
	// 	if (props.mode === 'add') {
	// 		setSelectedMasterProduct(masterSupplier);
	// 	} else {
	// 		setNewSelectedMasterSupplier(masterSupplier);
	// 	}
	// };

	const handleMPSelectionChange = (rows) => {
		if (rows.length > 0) {
			let masterSupplier = { ...rows[0] };
			delete masterSupplier['tableData'];
			if (props.mode === 'add' || props.mode === 'link_supplier_product') {
				setSelectedMasterProduct(masterSupplier);
			} else {
				setNewSelectedMasterSupplier(masterSupplier);
			}
		} else {
			if (props.mode === 'add' || props.mode === 'link_supplier_product') {
				setSelectedMasterProduct(null);
			} else {
				setNewSelectedMasterSupplier(null);
			}
		}
	};

	const handleBPSelectionChange = (rows: any) => {
		if (rows.length > 0) {
			let product = { ...rows[0] } as Product;
			delete product['tableData'];
			setSelectedBusinessProduct(product);
		} else {
			setSelectedBusinessProduct(null);
		}
	};

	return (
		<div>
			<Dialog
				fullWidth={props.mode === 'add' ? true : false}
				// maxWidth={props.suggestions ? 'lg' : props.mode === 'add' ? 'md' : 'sm'}
				maxWidth={props.mode === 'add' ? 'md' : 'sm'}
				open={props.isOpen}
				onClose={() => props.onClose && props.onClose(true, false)} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">
					{
						props.mode === 'add' ? 'New Master Product Link Record' : 'Edit Master Product Link Record'
					}
				</DialogTitle>
				<DialogContent>
					<LinkProduct
						mode={props.mode}
						business={props.business}
						linkedRecord={props.linkedRecord}
						suggestions={props.suggestions}
						invoiceItemDescription={props.invoiceItemDescription}
						handleMPSelectionChange={handleMPSelectionChange}
						handleBPSelectionChange={handleBPSelectionChange}
						selectedBusinessProduct={selectedBusinessProduct}
						setSelectedBusinessProduct={setSelectedBusinessProduct}
						selectedMasterProduct={selectedMasterProduct}
						setSelectedMasterProduct={setSelectedMasterProduct}
						selectedNewMasterProduct={selectedNewMasterProduct}
						setNewSelectedMasterSupplier={setNewSelectedMasterSupplier}
						addLinkRecord={addLinkRecord}
					/>
				</DialogContent>
				<DialogActions >
					<Button onClick={() => props.onClose && props.onClose(true, false)}>Cancel</Button>
					{
						(props.mode === 'add' || props.mode === 'link_supplier_product') &&
						<Button onClick={addRecord} color="primary" variant="contained">Save</Button>
					}
					{
						props.mode === 'edit' &&
						<Button onClick={updateRecord} color="primary" variant="contained">Update</Button>
					}
				</DialogActions>
			</Dialog>
		</div >
	);
}

const styles = {
	selectColumn: {
		display: 'flex',
		justifyContent: 'space-around',
		flexDirection: 'column',
		maxWidth: 300,
		width: '100%'
		// margin: 'auto'

	},
	selectRow: {
		display: 'flex',
		justifyContent: 'space-around'
	}
};