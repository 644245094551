import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { MasterProduct } from '../../../master-data/models';
import { PaginatedResponse } from '../../../shared/models';

import { API_URLS } from '../../api';
import composeRequest from '../../../shared/api/core';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { MasterDataRouterPaths } from '../../../page/Routes/RouterPaths';
import { ErrorStatusBar, CustomTableTitle, ExportMenu, ExportStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, useQuery, tableOptions } from '../../../shared/lib/TableQueryParams';
import download from 'downloadjs';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        autoSuggestContainer: {
            position: 'relative',
        },
        suggestionsContainerOpen: {
            position: 'absolute',
            zIndex: 1,
            marginTop: theme.spacing(1),
            left: 0,
            right: 0,
        },
        suggestion: {
            display: 'block',
        },
        suggestionsList: {
            margin: 0,
            padding: 0,
            listStyleType: 'none',
        },
        paper: {
            padding: theme.spacing(3),
            marginBottom: theme.spacing(2),
        },
    }),
);

const getMasterProductsByPage = (
    page: number,
    pageSize: number,
    searchTerm: string,
    orderBy: string | number,
    shouldExport?: boolean
) => {
    let URL = API_URLS.masterProductsWithoutIdentifiersUrl + '?page=' + page + '&page_size=' + pageSize;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = API_URLS.masterProductsWithoutIdentifiersUrl + '?search=' + searchTerm + '&page=' + page + '&page_size=' + pageSize;
    }
    if (shouldExport) {
        URL = `${API_URLS.masterProductsWithoutIdentifiersUrl}export/`;
    }
    if (orderBy) {
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

const MasterProductsWithoutBarcodeScreen = (props: any) => {
    const classes = useStyles({});
    const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
    const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
    const tableRef = useRef<{ onQueryChange: any; }>();
    let locationQuery = useQuery(useLocation);
    const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
    const [isExporting, setExporting] = useState(false);
    const [exportBtnAnchorEl, setExportBtnAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isLoading, setLoading] = useState(false);


    const exportCSV = () => {
        if (props.userData && props.userData.selectedBusiness) {
            setExporting(true);
            const sb = props.userData.selectedBusiness;
            const shouldExport = true;
            getMasterProductsByPage(
                null,
                null,
                tableQuery.search,
                sb.orderBy,
                shouldExport
            )
                .catch(handleError) // handle network issues
                .then(checkStatus)
                .then((res) => res.blob())
                .then((data) => {
                    download(data, 'Master_Products_Without_Barcode.csv', 'text/csv');
                    setExporting(false);
                })
                .catch((error: any) => {
                    setExporting(false);
                    if (error) {
                        if (error.status && error.status === 500) {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(false);
                        } else if (error.status === 403 || error.status === 401) {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(true);
                        } else {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(false);
                        }
                    }
                });
        }
    };



    const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setExportBtnAnchorEl(event.currentTarget);
    };

    const handleExportMenuClose = (shouldExport: boolean) => {
        if (shouldExport) {
            exportCSV();
        }
        setExportBtnAnchorEl(null);
    };




    const navigateToMasterProductDetailScreen = (rowData: any) => {
        const masterProductDetailUrl = MasterDataRouterPaths.masterProducts + '/' + rowData.id;
        props.history.push(masterProductDetailUrl, rowData);
    };

    return (
        <Container maxWidth="md">
            <ErrorStatusBar isVisible={isErrorStatusVisible} />
            <ExportStatusBar isVisible={isExporting} />
            <div style={{ maxWidth: '100%' }}>
                <div className="table-wrapper">
                    <MaterialTable
                        options={{
                            ...tableOptions(tableQuery),
                            thirdSortClick: false,
                            draggable: false,
                            sorting: true
                        }}
                        tableRef={tableRef}
                        localization={{
                            body: {
                                emptyDataSourceMessage: emptyDataSourceMessage
                            }
                        }}
                        columns={[
                            { title: 'ID', field: 'id', editable: 'never' },
                            { title: 'Product', field: 'name' },
                        ]}
                        onRowClick={(event, rowData) => navigateToMasterProductDetailScreen(rowData)}
                        data={query =>
                            new Promise((resolve, reject) => {
                                replaceHistory(query, props.history);
                                let pageData = {
                                    data: [] as MasterProduct[],
                                    totalCount: 0,
                                    page: query.page
                                };
                                let orderBy = '';
                                if (query.orderBy) {
                                    orderBy = query.orderBy.field as string;
                                    orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                                }
                                getMasterProductsByPage(
                                    query.page + 1,
                                    query.pageSize,
                                    query.search,
                                    orderBy,
                                    false
                                )
                                    .catch(handleError) // handle network issues
                                    .then(checkStatus)
                                    .then(parseJSON)
                                    .then((data: PaginatedResponse<MasterProduct>) => {
                                        setErrorStatusVisible(false);
                                        setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                                        pageData = {
                                            data: data.results,
                                            totalCount: data.count,
                                            page: query.page
                                        };
                                        setTableQuery({
                                            ...tableQuery,
                                            orderBy,
                                            totalCount: data.count,
                                            page: query.page,
                                            pageSize: query.pageSize
                                        });
                                        resolve(pageData);
                                    })
                                    .catch((error: any) => {
                                        if (error) {
                                            if (error.status && error.status === 500) {
                                                setEmptyDataSourceMessage(ERROR_500);
                                                setErrorStatusVisible(false);
                                            } else if ((error.status === 403 || error.status === 401)
                                            ) {
                                                setEmptyDataSourceMessage(ERROR_MSG);
                                                setErrorStatusVisible(true);
                                            } else {
                                                setEmptyDataSourceMessage(ERROR_MSG);
                                                setErrorStatusVisible(false);
                                            }
                                        }
                                        setTableQuery({
                                            ...tableQuery,
                                            totalCount: 0,
                                            page: query.page,
                                            pageSize: query.pageSize
                                        });
                                        resolve(pageData);
                                    });
                            })
                        }
                        title={
                            <CustomTableTitle
                                history={props.history}
                                title={'Master Products Without Barcode'}
                                icon={SVG_ICONS.product} />
                        }
                        actions={[
                            {
                                icon: 'save_alt',
                                tooltip: 'Export',
                                isFreeAction: true,
                                onClick: handleExportClick,
                                disabled: isExporting,
                            },
                            {
                                icon: 'refresh',
                                tooltip: 'Refresh Data',
                                isFreeAction: true,
                                onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                            },
                        ]}
                    />
                    <ExportMenu anchorEl={exportBtnAnchorEl} handleClose={handleExportMenuClose} />
                </div>
            </div>
        </Container>
    );
};

const mapStateToProps = (state: any) => {
    return {
        userData: state.userLocalData,
    };
};

export default connect(mapStateToProps, null)(MasterProductsWithoutBarcodeScreen);