import {
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  SET_SELECTED_PRODUCT,
  CLEAR_SELECTED_PRODUCT,
  SEARCH_PRODUCTS,
  SEARCH_PRODUCTS_SUCCESS,
  SEARCH_PRODUCTS_FAILURE,
  GET_PRODUCT_COUNT,
  GET_PRODUCT_COUNT_SUCCESS,
  GET_PRODUCT_COUNT_FAILURE,
  GET_PRODUCT_SUMMARY,
  GET_PRODUCT_SUMMARY_SUCCESS,
  GET_PRODUCT_SUMMARY_FAILURE,
  GET_PRODUCT_DETAIL,
  GET_PRODUCT_DETAIL_FAILURE,
  GET_PRODUCT_DETAIL_SUCCESS,
  CLEAR_PRODUCT_LIST,
} from './actionTypes';
import { API_URLS } from '../../api/ApiUrls';
import composeRequest from '../../../shared/api/core';
import { checkStatus, handleError, parseJSON } from '../../../shared/api/core';
import { Product } from '../../models';
import { PaginatedResponse } from '../../../shared/models';
import { Location } from '../../../shared/models';

export function getProductsFromAPI(
  selectedLocation: Location,
  searchTerm: string | null,
  reset?: boolean,
  page?: number,
  pageSize?: number
) {
  return (dispatch: any) => {
    dispatch(getProducts());
    let URL = API_URLS.mobileProductsUrl;
    const pg = pageSize || 20;
    const p = page || 1;
    URL = URL + '?business_id=' + selectedLocation.business_id + '&page=' + p + '&page_size=' + pg;
    if (searchTerm && searchTerm !== '') {
      URL = API_URLS.mobileProductsUrl + '?business_id=' + selectedLocation.business_id;
      URL = URL + '&query=' + searchTerm + '&page=' + p + '&page_size=' + pg;
    }
    composeRequest(URL, 'GET', null)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: PaginatedResponse<Product>) => {
        dispatch(getProductsSuccess(data, Boolean(reset)));
      })
      .catch((err: any) => {
        dispatch(getProductsFailure(err));
      });
  };
}

export function getProductDetailFromAPI(id: string) {
  return (dispatch: any) => {
    dispatch(getProductDetail());
    let URL = API_URLS.productsUrl + `${id}/`;
    composeRequest(URL, 'GET', null)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: PaginatedResponse<Product>) => {
        dispatch(getProductDetailSuccess(data));
      })
      .catch((err: any) => {
        dispatch(getProductDetailFailure(err));
      });
  };
}

export interface ProductCountResponse {
  productCount: number;
}

export function getProductCountAPI() {
  return (dispatch: any) => {
    dispatch(getProductCount());
    const URL = API_URLS.productCountUrl;
    composeRequest(URL, 'GET', null)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: ProductCountResponse) => {
        dispatch(getProductCountSuccess(data));
      })
      .catch((err: any) => {
        dispatch(getProductCountFailure(err));
      });
  };
}

export interface ProductSummaryResponse {
  unMappedCount: number;
  withoutBarcodeCount: number;
  totalCount: number;
}

export function getProductDataSummary(business_id: number | string) {
  return (dispatch: any) => {
    dispatch(getProductSummary());
    const URL = API_URLS.productSummaryDataUrl + '?business_id=' + business_id;
    composeRequest(URL, 'GET', null)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: ProductSummaryResponse) => {
        dispatch(getProductSummarySuccess(data));
      })
      .catch((err: any) => {
        dispatch(getProductSummaryFailure(err));
      });
  };
}

function getProducts() {
  return {
    type: GET_PRODUCTS,
  };
}

function getProductsSuccess(data: any, resetList: boolean) {
  return {
    type: GET_PRODUCTS_SUCCESS,
    resetList: resetList,
    data,
  };
}

function getProductsFailure(err: any) {
  return {
    type: GET_PRODUCTS_FAILURE,
    err,
  };
}

function getProductDetail() {
  return {
    type: GET_PRODUCT_DETAIL,
  };
}

function getProductDetailSuccess(data: any) {
  return {
    type: GET_PRODUCT_DETAIL_SUCCESS,
    data,
  };
}

function getProductDetailFailure(err: any) {
  return {
    type: GET_PRODUCT_DETAIL_FAILURE,
    err,
  };
}

export const saveSelectedProduct = (data: Product) => {
  return {
    type: SET_SELECTED_PRODUCT,
    data,
  };
};

export const clearSelectedProduct = () => {
  return {
    type: CLEAR_SELECTED_PRODUCT,
  };
};

export const clearProductList = () => {
  return {
    type: CLEAR_PRODUCT_LIST,
  };
};

function searchProducts() {
  return {
    type: SEARCH_PRODUCTS,
  };
}

function searchProductsSuccess(data: any, resetList: boolean) {
  return {
    type: SEARCH_PRODUCTS_SUCCESS,
    resetList: resetList,
    data,
  };
}

function searchProductsFailure(err: any) {
  return {
    type: SEARCH_PRODUCTS_FAILURE,
    err,
  };
}

function getProductCount() {
  return {
    type: GET_PRODUCT_COUNT,
  };
}

function getProductCountSuccess(data: ProductCountResponse) {
  return {
    type: GET_PRODUCT_COUNT_SUCCESS,
    data,
  };
}

function getProductCountFailure(err: any) {
  return {
    type: GET_PRODUCT_COUNT_FAILURE,
    err,
  };
}

function getProductSummary() {
  return {
    type: GET_PRODUCT_SUMMARY,
  };
}

function getProductSummarySuccess(data: ProductSummaryResponse) {
  return {
    type: GET_PRODUCT_SUMMARY_SUCCESS,
    data,
  };
}

function getProductSummaryFailure(err: any) {
  return {
    type: GET_PRODUCT_SUMMARY_FAILURE,
    err,
  };
}
