import { combineReducers, Action } from 'redux';
import {
    stockReceiptSummaryDataReducer
} from './stockReceiptReducer';
import { createStockReceiptDataReducer } from './createStockReceiptReducer';
import { addCodeToProductReducer } from './codeActionReducer';

export const stockReceiptModuleReducer = combineReducers({
    stockReceiptSummaryData: stockReceiptSummaryDataReducer,
    createStockReceiptData: createStockReceiptDataReducer,
    addProductCodeActionData: addCodeToProductReducer
});