import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';


const AccountScreen = (props: any) => {
    return (
        <Typography variant='body1'>Account Screen</Typography>
    );
};

export default AccountScreen;