import { API_CONFIG } from '../../shared/api/config';
import composeRequest from '../../shared/api/core';

const BASE_URL = API_CONFIG.API_BASE_PATH;

export const API_URLS = {
    masterProductsUrl: BASE_URL + 'korona/master/products/',
    masterSuppliersUrl: BASE_URL + 'korona/master/suppliers/',
    masterProductLinkUrl: BASE_URL + 'korona/master/products/businesses/link/',
    unMappedBusinessProductsUrl: BASE_URL + 'korona/unmapped-products/',
    unMappedBusinessProductsCountUrl: BASE_URL + 'korona/unmapped-products/count/',
    unMappedBusinessSuppliersUrl: BASE_URL + 'korona/unmapped-suppliers/',
    unMappedBusinessesUrl: BASE_URL + 'korona/unmapped-businesses/',
    unMappedBusinessSuppliersCountUrl: BASE_URL + 'korona/unmapped-suppliers/count/',
    masterSupplierLinkUrl: BASE_URL + 'korona/master/suppliers/business/link/',
    masterKoronaSupplierBusinessLinkUrl: BASE_URL + 'korona/master/link-korona-suppliers/',
    masterSupplierProductsLinkUrl: BASE_URL + 'korona/master/products/suppliers/link/',
    masterProductDetailUrl: BASE_URL + 'korona/master/product-detail/',
    masterProductWithSameCodeDiffIdUrl: BASE_URL + 'korona/master/products/same_code_different_id/',
    masterProductAttributesUrl: BASE_URL + 'korona/master/product-attributes/',
    masterProductWithoutIdentifiersUrl: BASE_URL + 'korona/master/products-missing-identifiers/',
    masterBrandsUrl: BASE_URL + 'korona/master/brands/',
    masterProductMissingIdentifiersUrl: BASE_URL + 'korona/master/products-missing-identifiers/',
    businessListUrl: BASE_URL + 'korona/master/businesses/',
};

export const getBusinessList = (page: number, pageSize: number, searchTerm: string | null) => {
    let URL = API_URLS.businessListUrl + `?page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = API_URLS.businessListUrl + `?search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getMasterProductsByPage = (
    page: number,
    pageSize: number,
    searchTerm: string | null,
    orderBy?: string | number
) => {
    let URL = API_URLS.masterProductsUrl + `?page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL =
            API_URLS.masterProductsUrl + `?search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    if (orderBy) {
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const deleteMasterProduct = (data: any) => {
    const URL = API_URLS.masterProductsUrl + data.id + '/';
    return composeRequest(URL, 'DELETE', null);
};

export const addMasterProduct = (data: any) => {
    const URL = API_URLS.masterProductsUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};

export const updateMasterProduct = (data: any) => {
    const URL = API_URLS.masterProductsUrl + data.id + '/';
    return composeRequest(URL, 'PUT', JSON.stringify(data));
};

export const getMasterProductAttributes = () => {
    const URL = API_URLS.masterProductAttributesUrl;
    return composeRequest(URL, 'GET', null);
};

export const getMasterProductDetail = (id: number) => {
    let URL = API_URLS.masterProductDetailUrl + id + '/';
    return composeRequest(URL, 'GET', null);
};

export const getLinkedMasterProductsByPage = (
    businessId: string | number,
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    let URL =
        API_URLS.masterProductLinkUrl +
        `?business__id=${businessId}&page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL =
            API_URLS.masterProductLinkUrl +
            `?business__id=${businessId}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const deleteMasterProductLinkRecord = (data: any) => {
    const URL = API_URLS.masterProductLinkUrl + `${data.id}/`;
    return composeRequest(URL, 'DELETE', null);
};

export const getUnMappedBusinessProductsCount = (businessId: string | number) => {
    const URL = API_URLS.unMappedBusinessProductsCountUrl + `?business_id=${businessId}`;
    return composeRequest(URL, 'GET', null);
};

export const getMasterSuppliersByPage = (
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    let URL = API_URLS.masterSuppliersUrl + '?page=' + page + '&page_size=' + pageSize;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL =
            API_URLS.masterSuppliersUrl +
            '?search=' +
            searchTerm +
            '&page=' +
            page +
            '&page_size=' +
            pageSize;
    }
    return composeRequest(URL, 'GET', null);
};

export const deleteMasterSupplier = (data: any) => {
    const URL = API_URLS.masterSuppliersUrl + data.id + '/';
    return composeRequest(URL, 'DELETE', null);
};

export const addMasterSupplier = (data: any) => {
    const URL = API_URLS.masterSuppliersUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};

export const updateMasterSupplier = (data: any) => {
    const URL = API_URLS.masterSuppliersUrl + data.id + '/';
    return composeRequest(URL, 'PUT', JSON.stringify(data));
};

export const getLinkedMasterSuppliersByPage = (
    businessId: string | number,
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    // let URL = API_URLS.masterSupplierLinkUrl + '?page=' + page + '&page_size=' + pageSize;
    // let URL = API_URLS.masterSupplierLinkUrl;
    let URL =
        API_URLS.masterSupplierLinkUrl +
        `?business__id=${businessId}&page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL =
            API_URLS.masterSupplierLinkUrl +
            `?business__id=${businessId}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getLinkedKoronaBusinessSuppliers = (
    businessId: string | number,
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    let URL =
        API_URLS.masterKoronaSupplierBusinessLinkUrl +
        `?business__id=${businessId}&page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = URL + `&search=${searchTerm}`;
    }
    return composeRequest(URL, 'GET', null);
};
export const deleteLinkedBusinessSupplierRecord = (data: any) => {
    const URL = API_URLS.masterKoronaSupplierBusinessLinkUrl + `${data.id}/`;
    return composeRequest(URL, 'DELETE', null);
};

export const getMasterBrandsByPage = (
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    let URL = API_URLS.masterBrandsUrl + `?page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = API_URLS.masterBrandsUrl + `?search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const deleteMasterSupplierLinkRecord = (data: any) => {
    const URL = API_URLS.masterSupplierLinkUrl + `${data.id}/`;
    return composeRequest(URL, 'DELETE', null);
};

export const getUnMappedBusinessSuppliersCount = (businessId: string | number) => {
    const URL = API_URLS.unMappedBusinessSuppliersCountUrl + `?business_id=${businessId}`;
    return composeRequest(URL, 'GET', null);
};

export const searchMasterSuppliers = (searchTerm: string | null): Promise<any> => {
    const URL = API_URLS.masterSuppliersUrl + '?search=' + searchTerm;
    return composeRequest(URL, 'GET', null);
};

export const getLinkedMasterSupplierProductByPage = (
    masterSupplierId: string | number,
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    // let URL = API_URLS.masterSuppliersLinkedUrl + '?page=' + page + '&page_size=' + pageSize;
    let URL =
        API_URLS.masterSupplierProductsLinkUrl +
        '?master_supplier__id=' +
        masterSupplierId +
        '&page=' +
        page +
        '&page_size=' +
        pageSize;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL =
            API_URLS.masterSupplierProductsLinkUrl +
            '?master_supplier__id=' +
            masterSupplierId +
            '&search=' +
            searchTerm +
            '&page=' +
            page +
            '&page_size=' +
            pageSize;
    }
    return composeRequest(URL, 'GET', null);
};

export const deleteMasterSupplierProductLinkRecord = (data: any) => {
    const URL = API_URLS.masterSupplierProductsLinkUrl + data.id + '/';
    return composeRequest(URL, 'DELETE', null);
};

export const getUnMappedSuppliers = (
    businessId: string | number,
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    let URL =
        API_URLS.unMappedBusinessSuppliersUrl +
        '?business__id=' +
        businessId +
        '&page=' +
        page +
        '&page_size=' +
        pageSize;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL =
            API_URLS.unMappedBusinessSuppliersUrl +
            `?business__id=${businessId}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getUnMappedBusinesses = (
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    let URL = API_URLS.unMappedBusinessesUrl + '?page=' + page + '&page_size=' + pageSize;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = URL + `&search=${searchTerm}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getUnMappedProductsByPage = (
    businessId: string | number,
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    searchTerm = searchTerm ? searchTerm.trim() : '';
    let URL =
        API_URLS.unMappedBusinessProductsUrl +
        `?business__id=${businessId}&page=${page}&page_size=${pageSize}`;
    if (searchTerm) {
        URL =
            API_URLS.unMappedBusinessProductsUrl +
            `?business__id=${businessId}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    return composeRequest(URL, 'GET', null);
};
