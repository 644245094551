import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Paper, Typography, CircularProgress } from '@material-ui/core';
import MaterialTable from 'material-table';
import { Theme, createStyles, makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { EXPECTED_HEADERS_SHORT_NAME } from '../../../models';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../../shared/lib/TableQueryParams';
import { LinkProductModal } from '../../../../master-data/components';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../../shared/components/Notifier';
import { EMPTY_ROW_MSG, ERROR_MSG, ERROR_500, NO_PERMISSIONS_ERROR_MSG } from '../../../../shared/lib/Localization';
import FixInvoiceItemModal from '../../FixInvoiceItemModal/FixInvoiceItemModal';
import { updateStockOrderProductInList } from '../../../data/actions';
import isEqual from 'lodash/isEqual';
import { LocationBusiness } from '../../../../shared/models';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableWrapper: {
      padding: theme.spacing(2, 2),
      borderRadius: 0
    },
    button: {
      margin: theme.spacing(1, 0, 2, 2),
    },
    summaryWrapper: {
      border: '1px solid #ddd',
      paddingLeft: 16,
      paddingRight: 16,
      marginBottom: 16
    },
    rowSummary: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      // padding: 8
    },
    errorText: {
      color: theme.palette.error.main,
      fontWeight: 400
    },
    successText: {
      color: '#43a047',
      fontSize: '0.875rem',
      fontWeight: 400
    },
    defaultText: {
      fontSize: '0.875rem',
      fontWeight: 400
    }
  }),
);
const initalDialogData = {
  open: false,
  business: null,
  onClose: null,
  mode: 'add',
  linkedRecord: null,
  suggestions: [],
  invoiceItemDescription: null
};

const fixDialogData = {
  open: false,
  onClose: null as any,
  invoiceItem: null as any,
  business: null as null | LocationBusiness
};

const MatchInvoiceProduct = (props: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    stockOrderSummary,
    getStockOrderProducts,
    stockOrderProductList,
    specificHeaders,
    caseQtyDataError,
    isCaseQtyMapped,
    uiLoading,
    windowWidth, windowHeight
  } = props;
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));

  const difference = Number(stockOrderSummary.expectedTotal) - Number(stockOrderSummary.calculatedTotal);
  const differenceString = difference.toFixed(2);
  const [invoiceItemModalData, setInvoiceItemModaldata] = useState(fixDialogData);
  const [invoiceItemList, setInvoiceItemList] = useState({
    data: [],
    isFetching: true,
    success: false,
    error: false,
    errorData: null
  });

  const fixInvoiceItem = (data: any) => {
    let { id, name } = props.selectedSupplier.korona_supplier_business;
    let business = { business_id: id, business_name: name };
    switch (data.error_type) {
      case 'link_business_product':
        const sb = props.userData.selectedBusiness;
        business = sb;
        break;
      case 'supplier_product':
        break;
      case 'link_supplier_product':
        break;
    }
    setInvoiceItemModaldata({
      open: true,
      business: business,
      invoiceItem: data,
      onClose: (invoiceItem: any) => {
        if (invoiceItem) {
          // props.getStockOrderProducts();
          props.updateMatchedItem(invoiceItem);
        }
        setInvoiceItemModaldata(fixDialogData);
      }
    });
  };

  useEffect(() => {
    if (!isEqual(invoiceItemList, props.stockOrderProductList)) {
      setInvoiceItemList(props.stockOrderProductList);
    }
  }, [props.stockOrderProductList]);

  const getTableBodyMaxHeight = () => {
    const style = { maxHeight: 0 };
    if (windowWidth) {
      if (windowWidth >= 2260) {
        style.maxHeight = 900;
      } else if (windowWidth >= 1920) {
        style.maxHeight = 462;
        // style.maxHeight = 552;
      } else if (windowWidth >= 1600) {
        style.maxHeight = 400;
      } else if (windowWidth >= 1440) {
        style.maxHeight = 372;
        if (windowHeight >= 900) {
          style.maxHeight = 460;
        }
      } else if (windowWidth >= 1366) {
        style.maxHeight = 372;
        if (windowHeight >= 900) {
          style.maxHeight = 460;
        }
      } else if (windowWidth >= 1280) {
        style.maxHeight = 372;
        if (windowHeight >= 900) {
          style.maxHeight = 460;
        }
      } else if (windowWidth >= 1080) {
        style.maxHeight = 372;
        if (windowHeight >= 900) {
          style.maxHeight = 460;
        }
      } else if (windowWidth >= 1024) {
        style.maxHeight = 372;
        if (windowHeight >= 900) {
          style.maxHeight = 460;
        }
      } else if (windowWidth >= 800) {
        style.maxHeight = 245;
      }
      return style;
    }
    return style;
  };

  return (
    <div>
      <Paper className={classes.tableWrapper} elevation={0}>
        {
          (uiLoading || invoiceItemList.isFetching) &&
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        }
        {
          invoiceItemList &&
          !invoiceItemList.isFetching &&
          invoiceItemList.data &&
          <MaterialTable
            options={{
              ...tableOptions(tableQuery),
              padding: 'dense',
              pageSizeOptions: [10, 50, 100, 500],
              pageSize: 100,
              maxBodyHeight: getTableBodyMaxHeight().maxHeight,
            }}
            columns={[
              {
                title: 'Row',
                field: EXPECTED_HEADERS_SHORT_NAME.itemIndex,
                editable: 'never',
              },
              {
                title: 'Serial Number',
                field: EXPECTED_HEADERS_SHORT_NAME.SERIAL_NUMBER,
                editable: 'never',
                hidden: !specificHeaders.serialNumber
              },
              {
                title: 'ReOrder Code',
                field: EXPECTED_HEADERS_SHORT_NAME.RE_ORDER_CODE,
                editable: 'never',
                hidden: !specificHeaders.reOrderCode
              },
              { title: 'Invoice Description', field: EXPECTED_HEADERS_SHORT_NAME.SUPPLIER_PRODUCT_NAME, editable: 'never' },
              {
                title: 'Customer Product',
                field: 'product.name',
                editable: 'never',
                render: rowData => {
                  if (rowData.product && rowData.product['name']) {
                    return <Typography variant="subtitle2" className={classes.defaultText}>{rowData.product['name']}</Typography>;
                  }
                  return <Typography variant="subtitle2" className={classes.errorText}>Product Mapping Not Found</Typography>;
                }
              },
              {
                title: 'Case Quantity',
                field: EXPECTED_HEADERS_SHORT_NAME.CASE_QUANTITY,
                render: rowData => {
                  if (rowData[EXPECTED_HEADERS_SHORT_NAME.CASE_QUANTITY] && rowData.master_supplier_container_qty) {
                    if (Number(rowData[EXPECTED_HEADERS_SHORT_NAME.CASE_QUANTITY]) !== Number(rowData.master_supplier_container_qty))
                      return <Typography variant="subtitle2" className={classes.errorText}>{rowData[EXPECTED_HEADERS_SHORT_NAME.CASE_QUANTITY]}</Typography>;
                  }
                  return <Typography variant="subtitle2">{rowData[EXPECTED_HEADERS_SHORT_NAME.CASE_QUANTITY]}</Typography>;
                },
                // editable: 'onUpdate',
                hidden: !specificHeaders.caseQuantity
              },
              { title: 'Supplier Case Quantity', field: 'master_supplier_container_qty', editable: 'never' },
              {
                title: 'Cases Delivered',
                field: EXPECTED_HEADERS_SHORT_NAME.CASES_DELIVERED,
                editable: 'never',
                hidden: !specificHeaders.casesDelivered
              },
              {
                title: 'Cost Per Case',
                field: EXPECTED_HEADERS_SHORT_NAME.COST_PER_CASE,
                editable: 'never'
              },
              {
                title: 'Units Delivered',
                field: EXPECTED_HEADERS_SHORT_NAME.UNITS_DELIVERED,
                editable: 'never',
                hidden: !specificHeaders.unitsDelivered
              },
              {
                title: 'Cost Per Unit',
                field: EXPECTED_HEADERS_SHORT_NAME.COST_PER_UNIT,
                editable: 'never',
              },
              {
                title: 'Line Total',
                field: EXPECTED_HEADERS_SHORT_NAME.LINE_TOTAL,
                editable: 'never',
                cellStyle: {
                  textAlign: 'right',
                },
              },
              {
                title: 'Calculated Total',
                field: EXPECTED_HEADERS_SHORT_NAME.calculatedLineTotal,
                editable: 'never',
                cellStyle: {
                  textAlign: 'right',
                },
                render: rowData => {
                  if (rowData.lineTotal === rowData.calculatedLineTotal) {
                    return (
                      <Typography variant="subtitle2"
                        className={classes.successText}>
                        {rowData.calculatedLineTotal}
                      </Typography>
                    );
                  }
                  return (
                    <Typography variant="subtitle2"
                      className={classes.errorText}>
                      {rowData.calculatedLineTotal}
                    </Typography>
                  );
                }
              },
              {
                title: 'Issue',
                field: 'error',
                editable: 'never',
                render: rowData => {
                  if (rowData.error) {
                    return <Typography variant="subtitle2" className={classes.errorText}>{rowData.error}</Typography>;
                  }
                  return <Typography variant="subtitle2" className={classes.defaultText}>----</Typography>;
                }
              },
              {
                title: 'Action',
                field: 'error',
                editable: 'never',
                render: rowData => {
                  if (rowData.error) {
                    return <div><Button variant="contained" style={{ textTransform: 'none' }}
                      onClick={() => fixInvoiceItem(rowData)}>Fix</Button></div>;
                  }
                  return <Typography variant="subtitle2" className={classes.defaultText}>----</Typography>;
                }
              },
            ]}
            data={invoiceItemList.data}
            title={
              invoiceItemList &&
              !invoiceItemList.isFetching &&
              invoiceItemList.data &&
              <div>
                <div className={classes.rowSummary}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                      <span style={{ fontSize: 18 }}
                        className={
                          stockOrderSummary.unMappedProducts > 0 ?
                            classes.errorText : classes.successText
                        }
                      >Items: {stockOrderSummary.itemCount}({stockOrderSummary.unMappedProducts})&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontSize: 18 }}
                        className={
                          stockOrderSummary.calculatedTotal !== stockOrderSummary.expectedTotal ?
                            classes.errorText : classes.successText
                        }
                      >Total: {stockOrderSummary.expectedTotal}({differenceString})</span>
                    </div>
                    <div style={{ justifySelf: 'flex-end', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                      <Button variant="contained"
                        className={classes.button}
                        onClick={() => getStockOrderProducts()}>
                        Reload Mapping</Button>
                    </div>
                  </div>

                </div>
                {
                  isCaseQtyMapped && caseQtyDataError.hasError &&
                  <div style={{ textAlign: 'right' }}>
                    <Typography variant='body1'
                      className={classes.errorText}>{caseQtyDataError.message}</Typography>
                  </div>
                }
              </div>
            }
          />
        }
        <FixInvoiceItemModal
          isOpen={invoiceItemModalData.open}
          business={invoiceItemModalData.business}
          invoiceItem={invoiceItemModalData.invoiceItem}
          selectedSupplier={props.selectedSupplier}
          onClose={invoiceItemModalData.onClose}
        />
      </Paper>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    stockOrderProductList: state.stockOrderModuleData.stockOrderProductList,
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
    updateMatchedItem: (invoiceItem: any) => dispatch(updateStockOrderProductInList(invoiceItem))
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(MatchInvoiceProduct);
