import { combineReducers } from 'redux';
import {
    SET_SELECTED_BUSINESS,
    SET_SELECTED_LOCATION,
    SET_SELECTED_LOCATIONS,
    SET_SELECTED_DASHBOARD_TILES,
    SET_KIOSK_PROFILE
} from '../actions/actionTypes';
import {
    SET_SELECTED_TEMPLATE,
    SET_NETWORK_PRINTER_IP,
    SET_NETWORK_PRINTER_PORT,
    ENABLE_AUTO_ADJUST_LABEL,
    SET_PRINT_WIDTH,
    SET_REPORT_EMAIL,
    SET_MULTI_PRINT,
    SET_RESET_PRINT_QTY
} from '../../modules/labeler/data/actions/actionTypes';
import { LabelerTemplate } from '../../modules/labeler/models';
import authReducer from '../../modules/auth/data/reducers/authReducer';
import { LocationBusiness } from '../../modules/shared/models';
import { KioskProfileSettings } from '../../modules/kiosk/models/models';
import kioskauthReducer from '../../modules/kiosk/data/reducers/kioskauthReducer';

export interface UserSettings {
    userData: any;
    labelerModule: LabelerModuleSettings;
    userLocation: any;
    selectedBusiness: LocationBusiness;
    selectedLocations: Location[];
    selectedDashboardTiles?: string[];
}
export interface AutoAdjustLabelSettings {
    currentPrintWidth: string,
    isAutoAdjustEnabled: boolean;
}

export interface MultiPrintSettings {
    isMultiPrintEnabled: boolean;
    isResetPrintQuantity: boolean;
}

export interface LabelerModuleSettings {
    // selectedTemplate: null | LabelerTemplate,
    autoAdjustLabel: AutoAdjustLabelSettings;
    networkPrinterSettings: NetworkPrinterSettings;
    multiPrintSettings: MultiPrintSettings;
    selectedTemplate: null | number;
    reportEmail: null | string;
}

export interface NetworkPrinterSettings {
    ip: string;
    port: string;
}

const initialState = null;

export function selectedTemplateReducer(state = initialState, action: any) {
    switch (action.type) {
        case SET_SELECTED_TEMPLATE:
            // console.log("selected template reducer")
            // console.log(state)
            // console.log(action)
            return action.data;
        default: return state;

    }
}

const selectedLocationReducer = (state = null as any, action: any) => {
    switch (action.type) {
        case SET_SELECTED_LOCATION:
            if (action.location) {
                return {
                    ...action.location
                };
            } else {
                return state;
            }
        default: return state;
    }
};
const selectedLocationsReducer = (state = [] as any, action: any) => {
    switch (action.type) {
        case SET_SELECTED_LOCATIONS:
            if (action.locations) {
                return [
                    ...action.locations
                ];
            } else {
                return state;
            }
        default: return state;
    }
};
const selectedBusinessReducer = (state = null as unknown as LocationBusiness, action: any) => {
    switch (action.type) {
        case SET_SELECTED_BUSINESS:
            if (action.business) {
                return {
                    ...action.business
                };
            } else {
                return state;
            }
        default: return state;
    }
};
const selectedDashboardTilesReducer = (state = [] as string[], action: any) => {
    switch (action.type) {
        case SET_SELECTED_DASHBOARD_TILES:
            if (action.selectedOptions) {
                return [...action.selectedOptions];
            } else {
                return state;
            }
        default: return state;
    }
};

const selectedKioskProfileReducer = (state = null as KioskProfileSettings, action: any) => {
    switch (action.type) {
        case SET_KIOSK_PROFILE:
            if (action.data) {
                return { ...action.data };
            } else {
                return state;
            }
        default: return state;
    }
};

const initialNetworkSettingsState = {
    ip: '192.168.5.104',
    port: '9100'
};
const labelerNetworkReducer = (state = initialNetworkSettingsState, action: any) => {
    switch (action.type) {
        case SET_NETWORK_PRINTER_IP:
            if (action.ip) {
                return {
                    ...state,
                    ip: action.ip
                };
            } else {
                return {
                    ...state
                };
            }
        case SET_NETWORK_PRINTER_PORT:
            if (action.port) {
                return {
                    ...state,
                    port: action.port
                };
            } else {
                return {
                    ...state
                };
            }
        default: return state;
    }
};

const initialAutoAdjustLabelChecked = {
    isAutoAdjustEnabled: false,
    currentPrintWidth: '624'
};

const autoAdjustLabelReducer = (state = initialAutoAdjustLabelChecked, action: any) => {
    switch (action.type) {
        case ENABLE_AUTO_ADJUST_LABEL:
            return {
                ...state,
                isAutoAdjustEnabled: action.enabled
            };
        case SET_PRINT_WIDTH:
            return {
                ...state,
                currentPrintWidth: action.printWidth
            };
        default: return state;
    }
};
const initialMultiPrintSettings = {
    isMultiPrintEnabled: false,
    isResetPrintQuantity: true
};

const multiPrintLabelReducer = (state = initialMultiPrintSettings, action: any) => {
    switch (action.type) {
        case SET_MULTI_PRINT:
            return {
                ...state,
                isMultiPrintEnabled: action.isMultiPrintEnabled
            };
        case SET_RESET_PRINT_QTY:
            return {
                ...state,
                isResetPrintQuantity: action.isResetPrintQuantity
            };
        default: return state;
    }
};

const reportEmailReducer = (state = '', action: any) => {
    switch (action.type) {
        case SET_REPORT_EMAIL:
            return action.reportEmail;
        default: return state;
    }
};

const labelerSettingsReducer = combineReducers({
    selectedTemplate: selectedTemplateReducer,
    networkPrinterSettings: labelerNetworkReducer,
    autoAdjustLabel: autoAdjustLabelReducer,
    multiPrintSettings: multiPrintLabelReducer,
    reportEmail: reportEmailReducer,
});

export const userDataReducer = combineReducers({
    userData: authReducer,
    kioskUserData: kioskauthReducer,
    labelerModule: labelerSettingsReducer,
    userLocation: selectedLocationReducer,
    selectedBusiness: selectedBusinessReducer,
    selectedDashboardTiles: selectedDashboardTilesReducer,
    selectedLocations: selectedLocationsReducer,
    selectedKioskProfile: selectedKioskProfileReducer
});
