import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import MaterialTable, { MaterialTableProps } from 'material-table';
import moment from 'moment';
import { User } from '../../models';

import { updateUser, getUsersByPage, permissionModel } from '../../api';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { getBusinessListFromAPI } from '../../../shared/data/actions';
import { PaginatedResponse } from '../../../shared/models';
import { ErrorStatusBar, CustomTableTitle } from '../../../shared/components';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG, API_RESPONSE, NO_PERMISSIONS_ERROR_MSG } from '../../../shared/lib/Localization';
import { isSuperAdmin, hasAddPermission, hasUpdatePermission, hasDeletePermission } from '../../../shared/lib/PermissionManager';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import { Device } from '../../../devices/models';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2, 2),
            border: '1px solid',
            borderColor: '#cdcdcd',
            borderRadius: 4
        }
    }),
);

const UserManagerScreen = (props: any) => {
    const classes = useStyles({});
    const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
    const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
    const [tableEditableProp, setTableEditableProp] = useState({});
    const tableRef = useRef<{ onQueryChange: any; }>();
    let locationQuery = useQuery(useLocation);
    const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (isSuperAdmin(props) && !isLoading) {
            tableRef.current && tableRef.current.onQueryChange();
        }
    }, [props.userData.selectedBusiness]);

    const renderUsers = () => {
        if (props.userList && props.userList.data) {
            return props.userList.data.map((device: Device) => {
                const key = device.id.toString();
                return (
                    <div key={key}>
                        <ListItem button>
                            <ListItemText primary={device.device_name} />
                        </ListItem>
                        <Divider />
                    </div>
                );
            });
        }
        return null;
    };

    const handleSwitchChange = (field: string, userData: any) => {
        userData[field] = !userData[field];
        props.updateUser(userData);
    };

    const getUpdateFn = () => {
        return (newData: any, oldData: any) => new Promise((resolve, reject) => {
            setTimeout(() => {
                {
                    updateUser(newData)
                        .catch(handleError) // handle network issues
                        .then(checkStatus)
                        .then(parseJSON)
                        .then(jsonData => {
                            props.showToast({
                                message: 'User updated successfully!',
                                options: ToastSuccess
                            });
                            resolve();
                        })
                        .catch((error: any) => {
                            let msg = 'Failed to update user';
                            if (error && error.status === 403) {
                                msg = NO_PERMISSIONS_ERROR_MSG;
                            } else if (error && error.status === 500) {
                                msg = ERROR_500;
                            }
                            props.showToast({ message: msg, options: ToastError });
                        });
                }
                resolve();
            }, 1000);
        });
    };

    useEffect(() => {
        const { userData } = props.userData.userData;
        const editable = {} as any;
        if (userData) {
            if (hasUpdatePermission(userData.is_superuser,
                userData.user_permissions, permissionModel)) {
                editable['onRowUpdate'] = getUpdateFn();
            }
            setTableEditableProp(editable);
        }
    }, [props.userData]);

    return (
        <div>
            <ErrorStatusBar isVisible={isErrorStatusVisible} />
            <div className="table-wrapper">
                <MaterialTable
                    options={{
                        ...tableOptions(tableQuery),
                        thirdSortClick: false,
                        draggable: false,
                        sorting: true 
                    }}
                    tableRef={tableRef}
                    localization={{
                        body: {
                            emptyDataSourceMessage: emptyDataSourceMessage
                        }
                    }}
                    columns={[
                        { title: 'Username', field: 'username', editable: 'never', },
                        { title: 'First Name', field: 'first_name', editable: 'never', },
                        { title: 'Last Name', field: 'last_name', editable: 'never', },
                        { title: 'Email', field: 'email', editable: 'never', },
                        {
                            title: 'Last Login',
                            field: 'last_login',
                            editable: 'never',
                            emptyValue: 'Never',
                            render: rowData => rowData.last_login && moment(rowData.last_login).format('YYYY-MM-DD HH:mm')
                        },
                        {
                            title: 'Pin',
                            field: 'pin',
                            editComponent: props => (
                                <Input
                                    type="text"
                                    value={props.value}
                                    inputProps={{
                                        'aria-label': 'pin',
                                        'maxLength': 4
                                    }}
                                    onChange={e => props.onChange(e.target.value)}
                                />)
                            // <input
                            // type="text"
                            // value={props.value}
                            // maxLength={4}
                            // onChange={e => props.onChange(e.target.value)}
                            // />)
                        },
                        {
                            title: 'Enabled',
                            field: 'is_active',
                            type: 'boolean',
                            sorting: false,
                            render: rowData => <Switch
                                checked={rowData.is_active}
                                // onClick={() => handleSwitchChange('active',rowData)}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                        },
                        {
                            title: 'Web Login',
                            field: 'allow_web_login',
                            type: 'boolean',
                            sorting: false,
                            render: rowData => <Switch
                                checked={rowData.allow_web_login}
                                // onClick={() => handleSwitchChange('active',rowData)}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                        },
                        {
                            title: 'Device Login',
                            field: 'allow_device_login',
                            type: 'boolean',
                            sorting: false,
                            render: rowData => <Switch
                                checked={rowData.allow_device_login}
                                // onClick={() => handleSwitchChange('active',rowData)}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                        }
                    ]}
                    data={query =>
                        new Promise((resolve, reject) => {
                            if (!isLoading) {
                                const sb = props.userData.selectedBusiness;
                                if (sb && sb.business_id) {
                                    replaceHistory(query, props.history);
                                    let pageData = {
                                        data: [] as User[],
                                        totalCount: 0,
                                        page: query.page
                                    };
                                    setLoading(true);
                                    let orderBy = '';
                                    if (query.orderBy) {
                                        orderBy = query.orderBy.field as string;
                                        orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                                        console.log("DIRECTION",orderBy)
                                    
                                    }
                                    getUsersByPage(
                                        sb.business_id, 
                                        query.page + 1, 
                                        query.pageSize, 
                                        query.search,
                                        orderBy
                                    )
                                        .catch(handleError) // handle network issues
                                        .then(checkStatus)
                                        .then(parseJSON)
                                        .then((data: PaginatedResponse<User>) => {
                                            setErrorStatusVisible(false);
                                            setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                                            pageData = {
                                                data: data.results,
                                                totalCount: data.count,
                                                page: query.page
                                            };
                                            setTableQuery({
                                                ...tableQuery,
                                                orderBy,
                                                totalCount: data.count,
                                                page: query.page,
                                                pageSize: query.pageSize
                                            });
                                            setLoading(false);
                                            resolve(pageData);
                                        })
                                        .catch((error: any) => {
                                            if (error) {
                                                if (error) {
                                                    if (error.status && error.status === 500) {
                                                        setEmptyDataSourceMessage(ERROR_500);
                                                        setErrorStatusVisible(false);
                                                    } else if ((error.status === 403 || error.status === 401)
                                                    ) {
                                                        setEmptyDataSourceMessage(ERROR_MSG);
                                                        setErrorStatusVisible(true);
                                                    } else {
                                                        setEmptyDataSourceMessage(ERROR_MSG);
                                                        setErrorStatusVisible(false);
                                                    }
                                                }
                                            }
                                            setTableQuery({
                                                ...tableQuery,
                                                totalCount: 0,
                                                page: query.page,
                                                pageSize: query.pageSize
                                            });
                                            setLoading(false);
                                            resolve(pageData);
                                        });
                                }
                            }
                        })
                    }
                    title={
                        <CustomTableTitle
                            history={props.history}
                            title={'Users'}
                            icon={SVG_ICONS.users} />
                    }
                    actions={[
                        {
                            icon: 'refresh',
                            tooltip: 'Refresh Data',
                            isFreeAction: true,
                            onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                        }
                    ]}
                    editable={tableEditableProp}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        businessList: state.sharedModuleData.businessList,
        userData: state.userLocalData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getBusinessList: () => dispatch(getBusinessListFromAPI()),
        showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagerScreen);
