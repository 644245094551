import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import MaterialTable from 'material-table';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useLocation } from 'react-router-dom';
import { ProductNoBarcode, ReportType } from '../../models';
import { PaginatedResponse } from '../../../shared/models';

import { API_URLS } from '../../api';
import composeRequest from '../../../shared/api/core';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { getBusinessListFromAPI } from '../../../shared/data/actions';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { CustomTableTitle, ErrorStatusBar,ExportMenu,ExportStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import download from 'downloadjs';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(3),
            marginBottom: theme.spacing(2),
        },
        formControl: {
            // margin: theme.spacing(1),
            minWidth: 120,
            width: '100%',
            maxWidth: '16rem'
        },
        progress: {
            margin: theme.spacing(2),
        },
    }),
);

const selectedReportType: ReportType = {
    id: 2,
    name: 'Products not Mapped',
    title: 'Products Not Mapped',
    columns: [
        { title: 'Number', field: 'number' },
        { title: 'Product', field: 'name' },
    ]
};

const getProductsByPage = (businessId: string | number, page: number, pageSize: number, 
    searchTerm: string | null , shouldExport?: boolean ) => {
    let URL = API_URLS.unMappedBusinessProductsUrl +
        `?business__id=${businessId}&page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = API_URLS.unMappedBusinessProductsUrl +
            `?business__id=${businessId}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    if (shouldExport) {
        URL = `${API_URLS.unMappedBusinessProductsUrl}export/?business_ids=${businessId}`;
    } else {
        URL = `${
            API_URLS.unMappedBusinessProductsUrl
        }?business_ids=${businessId}&page=${page}&page_size=${pageSize}`;
    }
    return composeRequest(URL, 'GET', null);
};

const UnmappedProductscreen = (props: any) => {
    const classes = useStyles({});
    const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
    const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
    const tableRef = useRef<{ onQueryChange: any; }>();
    let locationQuery = useQuery(useLocation);
    const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
    const [isLoading, setLoading] = useState(false);
    const [isExporting, setExporting] = useState(false);
    const [exportBtnAnchorEl, setExportBtnAnchorEl] = React.useState<null | HTMLElement>(null);

    const exportCSV = () => {
        if (props.userData && props.userData.selectedBusiness) {
            setExporting(true);
            const sb = props.userData.selectedBusiness;
            const shouldExport = true;
            getProductsByPage(
                sb.business_id,
                null,
                null,
                tableQuery.search,
                shouldExport
            )
                .catch(handleError) // handle network issues
                .then(checkStatus)
                .then((res) => res.blob())
                .then((data) => {
                    download(data, 'Unmapped_Products.csv', 'text/csv');
                    setExporting(false);
                })
                .catch((error: any) => {
                    setExporting(false);
                    if (error) {
                        if (error.status && error.status === 500) {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(false);
                        } else if (error.status === 403 || error.status === 401) {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(true);
                        } else {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(false);
                        }
                    }
                });
        }
    };



    const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setExportBtnAnchorEl(event.currentTarget);
    };

    const handleExportMenuClose = (shouldExport: boolean) => {
        if (shouldExport) {
            exportCSV();
        }
        setExportBtnAnchorEl(null);
    };


    useEffect(() => {
        tableRef && tableRef.current && tableRef.current.onQueryChange();
    }, [props.userData.selectedBusiness]);

    return (
        <Container maxWidth="md">
            <ErrorStatusBar isVisible={isErrorStatusVisible} />
            <ExportStatusBar isVisible={isExporting} />
            <div className="table-wrapper">
                <MaterialTable
                    options={{ ...tableOptions(tableQuery) }}
                    tableRef={tableRef}
                    localization={{
                        body: {
                            emptyDataSourceMessage: emptyDataSourceMessage
                        }
                    }}
                    columns={selectedReportType.columns}
                    data={query =>
                        new Promise((resolve, reject) => {
                            replaceHistory(query, props.history);
                            let pageData = {
                                data: [] as any[],
                                totalCount: 0,
                                page: query.page
                            };
                            if (props.userData && props.userData.selectedBusiness) {
                                const sb = props.userData.selectedBusiness;
                                getProductsByPage(
                                    sb.business_id,
                                    query.page + 1,
                                    query.pageSize,
                                    query.search,
                                    false
                                )
                                    .catch(handleError) // handle network issues
                                    .then(checkStatus)
                                    .then(parseJSON)
                                    .then((data: PaginatedResponse<any>) => {
                                        setErrorStatusVisible(false);
                                        setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                                        pageData = {
                                            data: data.results,
                                            totalCount: data.count,
                                            page: query.page
                                        };
                                        setTableQuery({
                                            ...tableQuery,
                                            totalCount: data.count,
                                            page: query.page,
                                            pageSize: query.pageSize
                                        });
                                        resolve(pageData);
                                    })
                                    .catch((error: any) => {
                                        if (error) {
                                            if (error.status && error.status === 500) {
                                                setEmptyDataSourceMessage(ERROR_500);
                                                setErrorStatusVisible(false);
                                            } else if ((error.status === 403 || error.status === 401)
                                            ) {
                                                setEmptyDataSourceMessage(ERROR_MSG);
                                                setErrorStatusVisible(true);
                                            } else {
                                                setEmptyDataSourceMessage(ERROR_MSG);
                                                setErrorStatusVisible(false);
                                            }
                                        }
                                        setTableQuery({
                                            ...tableQuery,
                                            totalCount: 0,
                                            page: query.page,
                                            pageSize: query.pageSize
                                        });
                                        resolve(pageData);
                                    });
                            }
                        })
                    }
                    title={
                        <CustomTableTitle
                            history={props.history}
                            title={selectedReportType.title}
                            icon={SVG_ICONS.product} />
                    }
                    actions={[
                        {
                            icon: 'save_alt',
                            tooltip: 'Export',
                            isFreeAction: true,
                            onClick: handleExportClick,
                            disabled: isExporting,
                        },
                        {
                            icon: 'refresh',
                            tooltip: 'Refresh Data',
                            isFreeAction: true,
                            onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                        },
                    ]}
                />
                <ExportMenu anchorEl={exportBtnAnchorEl} handleClose={handleExportMenuClose} />
            </div>
        </Container>
    );
};

const mapStateToProps = (state: any) => {
    return {
        userData: state.userLocalData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getBusinessList: () => dispatch(getBusinessListFromAPI())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UnmappedProductscreen);