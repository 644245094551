export const GET_MASTER_BRANDS = 'GET_MASTER_BRANDS';
export const GET_MASTER_BRANDS_SUCCESS = 'GET_MASTER_BRANDS_SUCCESS';
export const GET_MASTER_BRANDS_FAILURE = 'GET_MASTER_BRANDS_FAILURE';
export const UPDATE_MASTER_BRAND = 'UPDATE_MASTER_BRAND';
export const UPDATE_MASTER_BRAND_SUCCESS = 'UPDATE_MASTER_BRAND_SUCCESS';
export const UPDATE_MASTER_BRAND_FAILURE = 'UPDATE_MASTER_BRAND_FAILURE';
export const DELETE_MASTER_BRAND = 'DELETE_MASTER_BRAND';
export const DELETE_MASTER_BRAND_SUCCESS = 'DELETE_MASTER_BRAND_SUCCESS';
export const DELETE_MASTER_BRAND_FAILURE = 'DELETE_MASTER_BRAND_FAILURE';
export const ADD_MASTER_BRAND = 'ADD_MASTER_BRAND';
export const ADD_MASTER_BRAND_SUCCESS = 'ADD_MASTER_BRAND_SUCCESS';
export const ADD_MASTER_BRAND_FAILURE = 'ADD_MASTER_BRAND_FAILURE';

export const GET_MASTER_PRODUCTS = 'GET_MASTER_PRODUCTS';
export const GET_MASTER_PRODUCTS_SUCCESS = 'GET_MASTER_PRODUCTS_SUCCESS';
export const GET_MASTER_PRODUCTS_FAILURE = 'GET_MASTER_PRODUCTS_FAILURE';
export const UPDATE_MASTER_PRODUCT = 'UPDATE_MASTER_PRODUCT';
export const UPDATE_MASTER_PRODUCT_SUCCESS = 'UPDATE_MASTER_PRODUCT_SUCCESS';
export const UPDATE_MASTER_PRODUCT_FAILURE = 'UPDATE_MASTER_PRODUCT_FAILURE';
export const DELETE_MASTER_PRODUCT = 'DELETE_MASTER_PRODUCT';
export const DELETE_MASTER_PRODUCT_SUCCESS = 'DELETE_MASTER_PRODUCT_SUCCESS';
export const DELETE_MASTER_PRODUCT_FAILURE = 'DELETE_MASTER_PRODUCT_FAILURE';
export const ADD_MASTER_PRODUCT = 'ADD_MASTER_PRODUCT';
export const ADD_MASTER_PRODUCT_SUCCESS = 'ADD_MASTER_PRODUCT_SUCCESS';
export const ADD_MASTER_PRODUCT_FAILURE = 'ADD_MASTER_PRODUCT_FAILURE';

export const SET_SELECTED_MASTER_PRODUCT = 'SET_SELECTED_MASTER_PRODUCT';
export const CLEAR_SELECTED_MASTER_PRODUCT = 'CLEAR_SELECTED_MASTER_PRODUCT';

export const SEARCH_MASTER_PRODUCTS = 'SEARCH_MASTER_PRODUCTS';
export const SEARCH_MASTER_PRODUCTS_SUCCESS = 'SEARCH_MASTER_PRODUCTS_SUCCESS';
export const SEARCH_MASTER_PRODUCTS_FAILURE = 'SEARCH_MASTER_PRODUCTS_FAILURE';

export const GET_MASTER_SUPPLIERS = 'GET_MASTER_SUPPLIERS';
export const GET_MASTER_SUPPLIERS_SUCCESS = 'GET_MASTER_SUPPLIERS_SUCCESS';
export const GET_MASTER_SUPPLIERS_FAILURE = 'GET_MASTER_SUPPLIERS_FAILURE';
export const UPDATE_MASTER_SUPPLIER = 'UPDATE_MASTER_SUPPLIER';
export const UPDATE_MASTER_SUPPLIER_SUCCESS = 'UPDATE_MASTER_SUPPLIER_SUCCESS';
export const UPDATE_MASTER_SUPPLIER_FAILURE = 'UPDATE_MASTER_SUPPLIER_FAILURE';
export const DELETE_MASTER_SUPPLIER = 'DELETE_MASTER_SUPPLIER';
export const DELETE_MASTER_SUPPLIER_SUCCESS = 'DELETE_MASTER_SUPPLIER_SUCCESS';
export const DELETE_MASTER_SUPPLIER_FAILURE = 'DELETE_MASTER_SUPPLIER_FAILURE';
export const ADD_MASTER_SUPPLIER = 'ADD_MASTER_SUPPLIER';
export const ADD_MASTER_SUPPLIER_SUCCESS = 'ADD_MASTER_SUPPLIER_SUCCESS';
export const ADD_MASTER_SUPPLIER_FAILURE = 'ADD_MASTER_SUPPLIER_FAILURE';

export const GET_MASTER_PRODUCT_WITHOUT_IDENTIFIERS = 'GET_MASTER_PRODUCT_WITHOUT_IDENTIFIERS';
export const GET_MASTER_PRODUCT_WITHOUT_IDENTIFIERS_FAILURE = 'GET_MASTER_PRODUCT_WITHOUT_IDENTIFIERS_FAILURE';
export const GET_MASTER_PRODUCT_WITHOUT_IDENTIFIERS_SUCCESS = 'GET_MASTER_PRODUCT_WITHOUT_IDENTIFIERS_SUCCESS';

export const SEARCH_MASTER_PRODUCT_WITHOUT_IDENTIFIERS = 'SEARCH_MASTER_PRODUCT_WITHOUT_IDENTIFIERS';
export const SEARCH_MASTER_PRODUCT_WITHOUT_IDENTIFIERS_FAILURE = 'SEARCH_MASTER_PRODUCT_WITHOUT_IDENTIFIERS_FAILURE';
export const SEARCH_MASTER_PRODUCT_WITHOUT_IDENTIFIERS_SUCCESS = 'SEARCH_MASTER_PRODUCT_WITHOUT_IDENTIFIERS_SUCCESS';
