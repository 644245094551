import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Paper, Typography,
  MenuItem, CircularProgress, TextField, Checkbox,
  FormControlLabel, FormHelperText, ListItemIcon, ListItemText,
  FormControl, Select, InputLabel, SvgIcon
} from '@material-ui/core';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import { Theme, createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Container from '@material-ui/core/Container';
import Papa from 'papaparse';
import 'react-datasheet/lib/react-datasheet.css';
import './style.css';
import 'bootstrap/dist/css/bootstrap.css';
import { PaginatedResponse } from '../../../../shared/models';
import { BusinessSupplierLinkRecord } from '../../../../master-data/models';
import { getLinkedKoronaBusinessSuppliers } from '../../../../master-data/api';
import { checkStatus, parseJSON, handleError } from '../../../../shared/api/core';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG, NO_PERMISSIONS_ERROR_MSG } from '../../../../shared/lib/Localization';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../../shared/components/Notifier';
import { SVG_ICONS } from '../../../../shared/icons/SvgIcons';
import { useStyles } from './styles';
import { GridElement, stockOrderHeaders } from '../../../models';
import moment from 'moment';
import { MasterDataRouterPaths } from '../../../../page/Routes/RouterPaths';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const FileImport = (props: any) => {
  const classes = useStyles({});
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const {
    onFileSelectChangeHandler,
    linkedSuppliers,
    uploadPDF, parseCSV,
    handleLinkedSupplierChange,
    selectedFile, selectedFileError,
    selectedSupplier, supplierError,
    uiLoading, setUILoading,
    stepOneSuccess,
    selectedOrgUnit,
  } = props;

  const handleUploadClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUploadMenuClose = (type: string) => {
    setAnchorEl(null);
    if (type === 'pdf') {
      const el = document.getElementById('pdf-file-input');
      el && el.click();
    } else if (type === 'tsv') {
      const el = document.getElementById('tsv-file-input');
      el && el.click();
    }
  };

  useEffect(() => {
    if (props.selectedFile) {
      const fileExtension = props.selectedFile.name.split('.').pop();
      const validExtensions = ['csv', 'tsv', 'txt'];
      if (fileExtension.toLowerCase() === 'pdf') {
        uploadPDF();
      } else if (validExtensions.includes(fileExtension)) {
        parseCSV();
      }
    }
  }, [props.selectedFile, props.selectedSupplier]);

  return (
    <div>
      <Paper className={classes.paper} elevation={0}>
        {
          uiLoading &&
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        }
        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: 8 }}>
          {
            !selectedFile && selectedFileError.hasError &&
            <div className={classes.textLabel}>
              <Typography variant="body1" className={classes.errorText}>{selectedFileError.message}</Typography>
            </div>
          }
          <Container maxWidth="sm">
            <div className={classes.textLabel}>
              <Typography variant="h5" className={classes.sectionTitle}>Let's Get Started!</Typography>
              <div className={classes.formRow}>
                <Typography variant="body1" className={classes.instructions}
                  style={{ marginTop: 8 }}>1. Location:</Typography>

                {
                  !selectedOrgUnit &&
                  <Typography variant="body1" className={classes.errorText}
                    style={{ marginTop: 8 }}>Please select a single location.</Typography>
                }
                {
                  selectedOrgUnit &&
                  <Typography variant="body1" className={classes.instructions}
                    style={{ marginTop: 8 }}>{selectedOrgUnit ? selectedOrgUnit.name : ''}</Typography>
                }
              </div>
              <div>{'Note: Location cannot be changed on the following steps.'}</div>
              <div className={classes.formRow}>
                <Typography variant="body1" className={classes.instructions}
                  style={{ marginTop: 8 }}>2. Select A Supplier</Typography>
                <div style={{ display: 'flex', width: '50%' }}>
                  <FormControl
                    style={{ width: '100%' }}
                    error={supplierError.hasError}>
                    {/* <InputLabel id="select-supplier">Supplier</InputLabel> */}
                    <Select
                      // labelId="select-supplier"
                      id="select-supplier"
                      name="selectedSupplier"
                      disabled={props.stepOneSuccess || uiLoading}
                      value={selectedSupplier ? selectedSupplier.id : ''}
                      onChange={handleLinkedSupplierChange}
                      required={true}
                    >
                      {
                        linkedSuppliers.map((s: BusinessSupplierLinkRecord) => (
                          <MenuItem key={s.id} value={s.id}>{s.korona_supplier_business.name}</MenuItem>

                        ))
                      }
                    </Select>
                    {
                      supplierError && supplierError.hasError &&
                      <FormHelperText>{supplierError.message}</FormHelperText>
                    }
                  </FormControl>
                </div>
              </div>
              <div className={classes.formRow}>
                <Typography variant="body1" className={classes.instructions}>Supplier Doesn't Exist? Create a mapping.</Typography>
                {
                  <Button
                    variant="contained"
                    onClick={() => props.history.push(MasterDataRouterPaths.masterBusinessSuppliersLink)}
                    startIcon={<SvgIcon><path d={SVG_ICONS.linkPlus} /></SvgIcon>}>
                    Map Supplier
                </Button>
                  // <Tooltip title="Map Supplier"
                  //   onClick={() => props.history.push(MasterDataRouterPaths.masterBusinessSuppliersLink)}>
                  //   <IconButton>
                  //     <SvgIcon>
                  //       <path d={SVG_ICONS.linkPlus} />
                  //     </SvgIcon>
                  //   </IconButton>
                  // </Tooltip>
                  // <Tooltip title="Map Supplier"
                  //   onClick={() => props.history.push(MasterDataRouterPaths.masterBusinessSuppliersLink)}>
                  //   <IconButton>
                  //     <SvgIcon>
                  //       <path d={SVG_ICONS.linkPlus} />
                  //     </SvgIcon>
                  //   </IconButton>
                  // </Tooltip>
                }
              </div>
              <div className={classes.formRow}>
                <Typography variant="body1" className={classes.instructions}>2. Upload Invoice PDF or TSV file.</Typography>
                <div style={{ alignSelf: 'center', marginRight: 4, marginTop: 8 }}>
                  <Button aria-controls="simple-menu" aria-haspopup="true"
                    variant="contained"
                    color="primary"
                    disabled={props.stepOneSuccess || uiLoading}
                    onClick={handleUploadClick}
                    startIcon={<SvgIcon><path d={SVG_ICONS.upload} /></SvgIcon>}>
                    Upload
                  </Button>
                  <StyledMenu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleUploadMenuClose}
                  >
                    <MenuItem
                      onClick={() => handleUploadMenuClose('pdf')}>
                      <ListItemIcon>
                        <SvgIcon><path d={SVG_ICONS.pdf} /></SvgIcon>
                      </ListItemIcon>
                      <ListItemText primary="PDF" />
                    </MenuItem>
                    <MenuItem onClick={() => handleUploadMenuClose('tsv')}>
                      <ListItemIcon>
                        <SvgIcon><path d={SVG_ICONS.fileUpload} /></SvgIcon>
                      </ListItemIcon>
                      <ListItemText primary="TSV" />
                    </MenuItem>
                  </StyledMenu>
                  <input type="file" name="file"
                    onChange={onFileSelectChangeHandler}
                    id="pdf-file-input"
                    style={{ display: 'none' }}
                    accept=".pdf" />
                  <input type="file" name="file"
                    onChange={onFileSelectChangeHandler}
                    id="tsv-file-input"
                    style={{ display: 'none' }}
                    accept=".tsv,.csv,.txt" />
                </div>
              </div>
            </div>
          </Container>
        </div>
      </Paper>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileImport);
