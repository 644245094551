import { API_CONFIG } from "../../shared/api/config";
import composeRequest from "../../shared/api/core";
import { InvoicePDFUpload, StockOrderRequest, MatchInvoicePayload } from "../models";

const BASE_URL = API_CONFIG.API_BASE_PATH;

export const API_URLS = {
    masterSuppliersLinkedUrl: BASE_URL + 'korona/master/suppliers/business/link/',
    stockOrdersUrl: BASE_URL + 'korona/stockOrders/',
    stockOrderSummaryDataUrl: BASE_URL + 'korona/stockOrders/summary/',
    matchInvoiceProductsUrl: BASE_URL + 'korona/stockOrder/match-products',
    createStockOrderUrl: BASE_URL + 'korona/stockOrders/',
    uploadInvoicePDFUrl: BASE_URL + 'korona/invoice-pdf-parser/upload/',
};

export const getStockOrdersByPage = (
    business_id: number | string,
    page: number, 
    pageSize: number, 
    searchTerm: string,
    orderBy: string | number,
) => {
    let URL = `${API_URLS.stockOrdersUrl}?business_id=${business_id}&page=${page}&page_size=${pageSize}`;
    if (searchTerm) {
        URL = `${API_URLS.stockOrdersUrl}?business_id=${business_id}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getMatchedInvoiceItemList = (payload: MatchInvoicePayload) => {
    const URL = API_URLS.matchInvoiceProductsUrl;
    return composeRequest(URL, 'POST', JSON.stringify(payload));
};

export const addStockOrder = (data: StockOrderRequest) => {
    const URL = API_URLS.createStockOrderUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};

export const getStockOrdersSummary = (business_id: number | string) => {
    const URL = `${API_URLS.stockOrderSummaryDataUrl}?business_id=${business_id}`;
    return composeRequest(URL, 'GET', null);
};

export const searchMasterSuppliersLinked = (businessId: number | string, searchTerm: string) => {
    const URL = `${API_URLS.masterSuppliersLinkedUrl}?search=${searchTerm}&business__id=${businessId}`;
    return composeRequest(URL, 'GET', null);
};

export const uploadInvoicePDf = (payload) => {
    const URL = `${API_URLS.uploadInvoicePDFUrl}`;
    return composeRequest(URL, 'PUT', payload, "multipart/form-data");
};