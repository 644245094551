import {
    GET_LABEL_PREVIEW, GET_LABEL_PREVIEW_SUCCESS, GET_LABEL_PREVIEW_FAILURE,
    GET_TEMPLATES, GET_TEMPLATES_SUCCESS, GET_TEMPLATES_FAILURE,
    SET_SELECTED_TEMPLATE, SET_NETWORK_PRINTER_IP, SET_NETWORK_PRINTER_PORT,
    CLEAR_LABEL_PREVIEW,
    ENABLE_AUTO_ADJUST_LABEL,
    SET_PRINT_WIDTH,
    SET_REPORT_EMAIL,
    SET_MULTI_PRINT,
    SET_RESET_PRINT_QTY
} from 'components/src/modules/labeler/data/actions/actionTypes';
import composeRequest, { checkStatus, handleError, parseText, parseJSON } from '../../../shared/api/core';
import { LabelerTemplate } from 'components/src/modules/labeler/models';
import { API_URLS } from '../../api/ApiUrls';
import {
    GET_LABELER_SUMMARY, GET_LABELER_SUMMARY_FAILURE, GET_LABELER_SUMMARY_SUCCESS
} from './actionTypes';
import { Location } from '../../../shared/models';
import { PaginatedResponse } from '../../../shared/models';


export function getTemplatesFromAPI(selectedLocation: Location) {
    return (dispatch: any) => {
        dispatch(getTemplates());
        const URL = API_URLS.labelerTemplatesUrl + '?business_ids=' + selectedLocation.business_id;
        composeRequest(URL, 'GET', null)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then((data: PaginatedResponse<LabelerTemplate>) => {
                dispatch(getTemplatesSuccess(data));
            })
            .catch((err: any) => {
                dispatch(getTemplatesFailure(err));
            });
    };
}

export function getZPLLabelPreview(zpl: string) {
    return (dispatch: any) => {
        dispatch(getLabelPreview());
        const URL = API_URLS.labelerZPLPreviewUrl;
        const payload = { data: zpl };
        composeRequest(URL, 'POST', JSON.stringify(payload))
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseText)
            .then((data: any) => {
                dispatch(getLabelPreviewSuccess(data));
            })
            .catch((err: any) => {
                // console.log(err);
                dispatch(getLabelPreviewFailure(err));
            });

    };
}

export interface LabelerSummaryResponse {
    activeCount: number;
    authorizedCount: number,
    totalCount: number;
}

export function getLabelerDataSummary(businessId: number | string) {
    return (dispatch: any) => {
        dispatch(getLabelerSummary());
        const URL = API_URLS.labelerSummaryDataUrl + '?business_id=' + businessId;
        composeRequest(URL, 'GET', null)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then((data: LabelerSummaryResponse) => {
                dispatch(getLabelerSummarySuccess(data));
            })
            .catch((err: any) => {
                dispatch(getLabelerSummaryFailure(err));
            });
    };
}

export const saveSelectedTemplate = (data: number | null) => {
    return {
        type: SET_SELECTED_TEMPLATE,
        data
    };
};

export const saveNetworkIP = (ip: string) => {
    return {
        type: SET_NETWORK_PRINTER_IP,
        ip
    };
};

export const saveNetworkPort = (port: string) => {
    return {
        type: SET_NETWORK_PRINTER_PORT,
        port
    };
};
export const enableAdjustLabel = (enabled: boolean) => {
    return {
        type: ENABLE_AUTO_ADJUST_LABEL,
        enabled
    };
};
export const savePrintWidth = (printWidth: string) => {
    return {
        type: SET_PRINT_WIDTH,
        printWidth
    };
};
export const saveReportEmail = (reportEmail: string) => {
    return {
        type: SET_REPORT_EMAIL,
        reportEmail
    };
};
export const enableMultiPrint = (isMultiPrintEnabled: boolean) => {
    return {
        type: SET_MULTI_PRINT,
        isMultiPrintEnabled
    };
};
export const enableResetPrintQty = (isResetPrintQuantity: boolean) => {
    return {
        type: SET_RESET_PRINT_QTY,
        isResetPrintQuantity
    };
};

function getTemplates() {
    return {
        type: GET_TEMPLATES
    };
}

function getTemplatesSuccess(data: PaginatedResponse<LabelerTemplate>) {
    return {
        type: GET_TEMPLATES_SUCCESS,
        data
    };
}

function getTemplatesFailure(err: any) {
    return {
        type: GET_TEMPLATES_FAILURE,
        err
    };
}

function getLabelPreview() {
    return {
        type: GET_LABEL_PREVIEW
    };
}

function getLabelPreviewSuccess(data: any) {
    return {
        type: GET_LABEL_PREVIEW_SUCCESS,
        data
    };
}

function getLabelPreviewFailure(err: any) {
    return {
        type: GET_LABEL_PREVIEW_FAILURE,
        err
    };
}

export const clearLabelPreview = () => {
    return {
        type: CLEAR_LABEL_PREVIEW,
    };
};


function getLabelerSummary() {
    return {
        type: GET_LABELER_SUMMARY
    };
}

function getLabelerSummarySuccess(data: LabelerSummaryResponse) {
    return {
        type: GET_LABELER_SUMMARY_SUCCESS,
        data
    };
}

function getLabelerSummaryFailure(err: any) {
    return {
        type: GET_LABELER_SUMMARY_FAILURE,
        err
    };
}