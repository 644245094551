import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable, { MaterialTableProps } from 'material-table';
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';

import { MasterProductDuplicate, UnknownIdentifier } from '../../models';
import { PaginatedResponse } from '../../../shared/models';

import { getUnknownIdentifiersReport, processUnknownIdentifier } from '../../api';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { hasUpdatePermission, isSuperAdmin } from '../../../shared/lib/PermissionManager';
import {
    Toast,
    enqueueSnackbar,
    ToastSuccess,
    ToastError,
} from '../../../shared/components/Notifier';
import {
    CustomTableTitle,
    ErrorStatusBar,
    ExportMenu,
    ExportStatusBar,
} from '../../../shared/components';
import {
    NO_PERMISSIONS_ERROR_MSG,
    // EMPTY_ROW_MSG,
    ERROR_500,
    ERROR_MSG,
    UNKNOWN_IDENTIFIER_EMPTY_ROW_MSG
} from '../../../shared/lib/Localization';
import {
    initQueryParams,
    replaceHistory,
    useQuery,
    tableOptions,
} from '../../../shared/lib/TableQueryParams';
import isEqual from 'lodash/isEqual';
import download from 'downloadjs';
import moment from 'moment';
import GlobalStyles from '../../../../styles/GlobalStyles.web';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(3),
            marginBottom: theme.spacing(2),
        },
        formControl: {
            // margin: theme.spacing(1),
            minWidth: 120,
            width: '100%',
            maxWidth: '16rem',
        },
        progress: {
            margin: theme.spacing(2),
        },
        greenCircle: {
            color: GlobalStyles.green,
        },
        redCircle: {
            color: GlobalStyles.warningText,
        },
        helpTooltipRow: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 8,
        },
        helpTooltipText: {
            padding: '0 8px 0 0',
        },
    })
);
const permissionModel = 'unknownidentifierreport';

const timeDisplayFormat = 'YYYY-MM-DD HH:mm';

interface StatusFilter {
    id: number;
    label: string;
    filterString: string | null | undefined;
}
const statusFilters: StatusFilter[] = [
    { id: 1, label: 'Processed', filterString: '&processed=true' },
    { id: 2, label: 'Unprocessed', filterString: '&processed=false' },
    { id: 3, label: 'All', filterString: null },
];

const UnknownIdentifierHistoryScreen = (props: any) => {
    const classes = useStyles({});
    const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(UNKNOWN_IDENTIFIER_EMPTY_ROW_MSG);
    const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
    const tableRef = useRef<{ onQueryChange: any; onChangePage: any }>();
    let locationQuery = useQuery(useLocation);
    const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
    const [tableEditableProp, setTableEditableProp] = useState({});
    const [filterBtnAnchorEl, setFilterBtnAnchorEl] = React.useState<null | HTMLElement>(null);
    const [processedFilter, setProcessedFilter] = useState(statusFilters[1]);
    const [isLoading, setLoading] = useState(false);
    const [exportBtnAnchorEl, setExportBtnAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isExporting, setExporting] = useState(false);
    const theme = useTheme();

    const [columns, setColumns] = useState([
        {
            title: 'Unknown Code',
            field: 'unknown_code',
            editable: 'never',
        },
        {
            title: 'Processed',
            field: 'processed',
            type: 'boolean',
            headerStyle: { textAlign: 'center' },
            cellStyle: { textAlign: 'center' },
            // defaultFilter: 'unchecked',
            editable: 'onUpdate',
            // filtering: true,
            // render: (rowData: any) => <Switch checked={rowData.processed} />,
            render: (rowData: any) => (
                <SvgIcon className={rowData.processed ? classes.greenCircle : classes.redCircle}>
                    <path d={SVG_ICONS.blankCircle} />
                </SvgIcon>
            ),
            editComponent: (props: any) => {
                return (
                    <Switch
                        checked={props.value ? true : false}
                        onChange={(e) => props.onChange(e.target.checked)}
                    />
                );
            },
        },
        {
            title: 'Already Added To Item',
            field: 'already_added_to_item',
            editable: 'never',
            emptyValue: '---',
        },
        {
            title: 'Match BName PNum PName',
            field: 'matched_product_desc',
            editable: 'never',
            emptyValue: '---',
            hidden: !isSuperAdmin(props),
        },
        {
            title: 'No. Entries',
            field: 'num_entries',
            editable: 'never',
        },
        {
            title: 'Check Digit Valid',
            field: 'check_digit_valid',
            editable: 'never',
        },

        {
            title: 'Last Scanned',
            field: 'max_created_time',
            editable: 'never',
            render: (rowData: any) =>
                rowData.max_created_time
                    ? moment(rowData.max_created_time).format(timeDisplayFormat)
                    : null,
        },
    ]);

    const handleFilterBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setFilterBtnAnchorEl(event.currentTarget);
    };

    const handleFilterMenuClose = (filter: StatusFilter | null) => {
        if (filter) {
            if (!isEqual(processedFilter, filter)) {
                setProcessedFilter(filter);
            }
        }
        setFilterBtnAnchorEl(null);
    };

    useEffect(() => {
        if (!isLoading) {
            tableRef.current && tableRef.current.onQueryChange();
        }
    }, [props.userData.selectedBusiness]);

    useEffect(() => {
        if (!isLoading) {
            tableRef.current && tableRef.current.onChangePage(null, 0);
        }
    }, [processedFilter]);

    const getUpdateFn = () => {
        return (newData: any, oldData: any) =>
            new Promise((resolve, reject) => {
                setTimeout(() => {
                    {
                        processUnknownIdentifier(newData)
                            .catch(handleError) // handle network issues
                            .then(checkStatus)
                            .then(parseJSON)
                            .then((jsonData) => {
                                props.showToast({
                                    message: 'Unknown Identifier updated successfully!',
                                    options: ToastSuccess,
                                });
                                tableRef.current && tableRef.current.onChangePage(null, 0);
                                resolve();
                            })
                            .catch((error: any) => {
                                let msg = 'Failed to update identifier';
                                if (error && error.status === 403) {
                                    msg = NO_PERMISSIONS_ERROR_MSG;
                                } else if (error && error.status === 500) {
                                    msg = ERROR_500;
                                }
                                props.showToast({ message: msg, options: ToastError });
                            });
                    }
                    resolve();
                }, 1000);
            });
    };

    useEffect(() => {
        const { userData } = props.userData.userData;
        const editable: any = {};
        if (userData) {
            if (
                hasUpdatePermission(
                    userData.is_superuser,
                    userData.user_permissions,
                    permissionModel
                )
            ) {
                editable['onRowUpdate'] = getUpdateFn();
            }
            setTableEditableProp(editable);
        }
    }, [props.userData]);

    const exportCSV = () => {
        if (props.userData && props.userData.selectedBusiness) {
            setExporting(true);
            const sb = props.userData.selectedBusiness;
            const shouldExport = true;
            getUnknownIdentifiersReport(
                sb.business_id,
                null,
                null,
                tableQuery.search,
                sb.orderBy,
                processedFilter.filterString,
                shouldExport
            )
                .catch(handleError) // handle network issues
                .then(checkStatus)
                .then((res) => res.blob())
                .then((data) => {
                    download(data, 'Unknown_Identifiers.csv', 'text/csv');
                    setExporting(false);
                })
                .catch((error: any) => {
                    setExporting(false);
                    if (error) {
                        if (error.status && error.status === 500) {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(false);
                        } else if (error.status === 403 || error.status === 401) {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(true);
                        } else {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(false);
                        }
                    }
                });
        }
    };

    const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setExportBtnAnchorEl(event.currentTarget);
    };

    const handleExportMenuClose = (shouldExport: boolean) => {
        if (shouldExport) {
            exportCSV();
        }
        setExportBtnAnchorEl(null);
    };

    return (
        <Container maxWidth={false}>
            <ErrorStatusBar isVisible={isErrorStatusVisible} />
            <ExportStatusBar isVisible={isExporting} />
            <div className="table-wrapper">
                <MaterialTable
                    options={{
                        ...tableOptions(tableQuery),
                        thirdSortClick: false,
                        draggable: false,
                        sorting: true
                    }}
                    tableRef={tableRef}
                    localization={{
                        body: {
                            emptyDataSourceMessage:(
                                <div style={{
                                        display: 'inline-flex', 
                                        justifyContent:'flex-start', 
                                        flexDirection: 'column', 
                                        paddingBottom: 8, 
                                        textAlign:'initial'
                                    }}> 
                                    <Typography style={{paddingTop: 8}}>{emptyDataSourceMessage}</Typography>
                                { !isErrorStatusVisible && emptyDataSourceMessage !== ERROR_MSG &&
                                    <>
                                   <Typography style={{paddingTop: 8}}>1. Verify the Unknown Product Lookup external system call exists. </Typography>
                                    <Typography style={{paddingTop: 8, paddingBottom: 8}}>2. Verify the external system call is linked to the POS profile. </Typography>
                                    </>} 
                                </div>)
                        },
                    }}
                    //@ts-ignore
                    columns={columns}
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            if (!isLoading) {
                                // console.log('query', query);
                                const sb = props.userData.selectedBusiness;
                                if (sb && sb.business_id) {
                                    replaceHistory(query, props.history);
                                    let pageData = {
                                        data: [] as UnknownIdentifier[],
                                        totalCount: 0,
                                        page: query.page,
                                    };
                                    setLoading(true);
                                    let orderBy = '';
                                    if (query.orderBy) {
                                        orderBy = query.orderBy.field as string;
                                        orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                                    }
                                    getUnknownIdentifiersReport(
                                        sb.business_id,
                                        query.page + 1,
                                        query.pageSize,
                                        query.search,
                                        orderBy,
                                        processedFilter.filterString,
                                        false
                                    )
                                        .catch(handleError) // handle network issues
                                        .then(checkStatus)
                                        .then(parseJSON)
                                        .then((data: PaginatedResponse<UnknownIdentifier>) => {
                                            setErrorStatusVisible(false);
                                            setEmptyDataSourceMessage(UNKNOWN_IDENTIFIER_EMPTY_ROW_MSG);
                                            pageData = {
                                                data: data.results,
                                                totalCount: data.count,
                                                page: query.page,
                                            };
                                            setTableQuery({
                                                ...tableQuery,
                                                orderBy,
                                                totalCount: data.count,
                                                page: query.page,
                                                pageSize: query.pageSize,
                                                search: query.search,
                                            });
                                            setLoading(false);
                                            resolve(pageData);
                                        })
                                        .catch((error: any) => {
                                            if (error) {
                                                if (error.status && error.status === 500) {
                                                    setEmptyDataSourceMessage(ERROR_500);
                                                    setErrorStatusVisible(false);
                                                } else if (
                                                    error.status === 403 ||
                                                    error.status === 401
                                                ) {
                                                    setEmptyDataSourceMessage(ERROR_MSG);
                                                    setErrorStatusVisible(true);
                                                } else {
                                                    setEmptyDataSourceMessage(ERROR_MSG);
                                                    setErrorStatusVisible(false);
                                                }
                                            }
                                            setTableQuery({
                                                ...tableQuery,
                                                totalCount: 0,
                                                page: query.page,
                                                pageSize: query.pageSize,
                                                search: query.search,
                                            });
                                            setLoading(false);
                                            resolve(pageData);
                                        });
                                }
                            }
                        })
                    }
                    title={
                        <CustomTableTitle
                            history={props.history}
                            title={'Unknown Identifiers'}
                            icon={SVG_ICONS.labeler}
                            showHelp={true}
                            helpText={
                                <div>
                                    <Typography variant="body1" style={{ padding: '4px 8px' }}>
                                        {`This report lists all codes that have been scanned at the selected location, that did not have a product attached to the code at the time of scanning. 
You may attach these codes to an existing or new product as appropriate, and subsequently mark the record as processed.
Incorrect entries or typos may be processed right away.
Default view only displays Unprocessed records, use the filters to change the view.`}
                                    </Typography>
                                    <div className={classes.helpTooltipRow}>
                                        <Typography className={classes.helpTooltipText}>
                                            Processed
                                        </Typography>
                                        <SvgIcon className={classes.greenCircle}>
                                            <path d={SVG_ICONS.blankCircle} />
                                        </SvgIcon>
                                    </div>
                                    <div className={classes.helpTooltipRow}>
                                        <Typography className={classes.helpTooltipText}>
                                            Unprocessed
                                        </Typography>
                                        <SvgIcon className={classes.redCircle}>
                                            <path d={SVG_ICONS.blankCircle} />
                                        </SvgIcon>
                                    </div>
                                </div>
                            }
                        />
                    }
                    actions={[
                        {
                            icon: 'save_alt',
                            tooltip: 'Export',
                            isFreeAction: true,
                            onClick: handleExportClick,
                            disabled: isExporting,
                        },
                        {
                            icon: 'refresh',
                            tooltip: 'Refresh Data',
                            isFreeAction: true,
                            onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                        },
                        {
                            icon: 'filter_list',
                            tooltip: 'Filter By Status',
                            isFreeAction: true,
                            onClick: handleFilterBtnClick,
                            iconProps: { color: 'primary' },
                        },
                    ]}
                    editable={tableEditableProp}
                />
                <ExportMenu anchorEl={exportBtnAnchorEl} handleClose={handleExportMenuClose} />
                <Menu
                    id="filter-menu"
                    anchorEl={filterBtnAnchorEl}
                    keepMounted
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={Boolean(filterBtnAnchorEl)}
                    onClose={() => handleFilterMenuClose(null)}
                >
                    <MenuItem disabled={true}>Show codes with status:</MenuItem>
                    {statusFilters.map((f) => {
                        const selected = f.id === processedFilter.id;
                        return (
                            <MenuItem
                                key={`filter-${f.id}`}
                                style={{ color: selected ? theme.palette.primary.main : 'inherit' }}
                                onClick={() => handleFilterMenuClose(f)}
                                selected={selected}
                            >
                                {f.label}
                            </MenuItem>
                        );
                    })}
                </Menu>
            </div>
        </Container>
    );
};

const mapStateToProps = (state: any) => {
    return {
        userData: state.userLocalData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UnknownIdentifierHistoryScreen);
