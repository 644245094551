export const GET_STOCK_ORDER_PRODUCTS = 'GET_STOCK_ORDER_PRODUCTS';
export const GET_STOCK_ORDER_PRODUCTS_SUCCESS = 'GET_STOCK_ORDER_PRODUCTS_SUCCESS';
export const GET_STOCK_ORDER_PRODUCTS_FAILURE = 'GET_STOCK_ORDER_PRODUCTS_FAILURE';
export const CLEAR_STOCK_ORDER_PRODUCTS = 'CLEAR_STOCK_ORDER_PRODUCTS';

export const UPDATE_STOCK_ORDER_PRODUCT_IN_LIST = 'UPDATE_STOCK_ORDER_PRODUCT_IN_LIST';

export const CREATE_STOCK_ORDER = 'CREATE_STOCK_ORDER';
export const CREATE_STOCK_ORDER_SUCCESS = 'CREATE_STOCK_ORDER_SUCCESS';
export const CREATE_STOCK_ORDER_FAILURE = 'CREATE_STOCK_ORDER_FAILURE';

export const GET_STOCK_ORDER_SUMMARY = 'GET_STOCK_ORDER_SUMMARY';
export const GET_STOCK_ORDER_SUMMARY_FAILURE = 'GET_STOCK_ORDER_SUMMARY_FAILURE';
export const GET_STOCK_ORDER_SUMMARY_SUCCESS = 'GET_STOCK_ORDER_SUMMARY_SUCCESS';

